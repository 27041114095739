import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { useDispatch } from 'react-redux';
import { createAction } from '@reduxjs/toolkit';

export const RePrintRatingPlateDialog = ({
  visible,
  yesButtonCallback,
  message = 'Label already printed for some rows. Print another one?',
}) => {
  const dispatch = useDispatch();

  const onRePrint = useCallback(() => {
    yesButtonCallback();
    dispatch(showRePrintRowsDialog(false));
  }, [dispatch, yesButtonCallback]);

  const onCloseDialog = useCallback(() => {
    dispatch(showRePrintRowsDialog(false));
  }, [dispatch]);

  const actions = [
    { text: 'Yes', onClick: onRePrint },
    { text: 'No', onClick: onCloseDialog },
  ];

  if (!visible) {
    return null;
  }

  return (
    <ModalTransition>
      <Modal
        key="active-modal"
        actions={actions}
        onClose={onCloseDialog}
        heading="Print rating plate"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscapePress={false}
      >
        {message}
      </Modal>
    </ModalTransition>
  );
};

RePrintRatingPlateDialog.propTypes = {
  message: PropTypes.string,
  yesButtonCallback: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export const showRePrintRowsDialog = createAction('reprintDialog/showRePrintRowsDialog');
