import Get from 'lodash/get';
import { createReducer } from '@reduxjs/toolkit';

import { showActionFailure } from '../../shared/utils/Notifications';
import {
  downloadMaterialFileFailure,
  downloadMaterialFileStart,
  downloadMaterialFileSuccess,
  getMaterialSearchResultsFailure,
  getMaterialSearchResultsStart,
  getMaterialSearchResultsSuccess,
} from './materialSearchActions';

const initialState = {
  downloading: false,
  searching: false,
  searchResult: {},
};

export const isSearching = state => Get(state, 'materialSearch.searching');
export const isDownloading = state => Get(state, 'materialSearch.downloading');
export const isLoading = state => isSearching(state) || isDownloading(state);

export const getMaterialSearchResult = state => Get(state, 'materialSearch.searchResult');

const materialSearchReducer = createReducer(initialState, {
  [getMaterialSearchResultsStart](state) {
    state.searching = true;
    state.searchResult = [];
  },
  [getMaterialSearchResultsSuccess](state, action) {
    state.searching = false;
    state.searchResult = Get(action, 'payload.result');
  },
  [getMaterialSearchResultsFailure](state, action) {
    state.searching = false;
    showActionFailure(action, 'Failed to search material');
  },
  [downloadMaterialFileStart](state) {
    state.downloading = true;
  },
  [downloadMaterialFileSuccess](state) {
    state.downloading = false;
  },
  [downloadMaterialFileFailure](state, action) {
    state.downloading = false;
    showActionFailure(action, 'Failed to download material file');
  },
});

export default materialSearchReducer;
