import React from 'react';
import styled from 'styled-components';
import { Row } from 'simple-flexbox';
import PropTypes from 'prop-types';
import { DownloadMaterialDocuments } from '../../shared/DownloadMaterialImages';
import Button from '@atlaskit/button';
import DownloadIcon from '@atlaskit/icon/glyph/download';

const HeaderWrapper = styled.div`
  padding: 1rem;
  min-height: 40px;
  border: 1px solid #1c6ea4;
  margin-bottom: 1rem;
  width: 100%;
`;
export const StorageBinHeader = ({ storageBin }) => {
  return (
    <HeaderWrapper justifyContent="space-between">
      <Row justifyContent="space-between">
        <span>{storageBin.description}</span>
        <span>{storageBin.typeDesignation}</span>
        <DownloadMaterialDocuments
          materialNumber={storageBin.materialNumber}
          renderButton={onClick => (
            <Button appearance="link" iconBefore={<DownloadIcon />} onClick={onClick}>
              Download pictures
            </Button>
          )}
        />
      </Row>
      <Row>
        <span>Unrestricted quantity: {storageBin.quantity}</span>
      </Row>
    </HeaderWrapper>
  );
};

StorageBinHeader.propTypes = {
  storageBin: PropTypes.shape({
    description: PropTypes.string.isRequired,
    typeDesignation: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    materialNumber: PropTypes.string.isRequired,
  }).isRequired,
};
