import { LogLevel } from '@azure/msal-browser';
import get from 'lodash/get';

async function fetchConfiguration() {
  return await fetch('/app-config.json').then(response => response.json());
}

export async function getMsalConfig() {
  const configuration = await fetchConfiguration();

  return {
    auth: {
      clientId: get(configuration, 'auth.clientId'),
      authority: get(configuration, 'auth.authority'),
      redirectUri: get(configuration, 'auth.redirectUrl'),
    },
    cache: {
      cacheLocation: 'sessionStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback(loglevel, message) {
          switch (loglevel) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            default:
              console.log(message);
          }
        },
        piiLoggingEnabled: false,
        logLevel: LogLevel.Warning,
      },
    },
  };
}
