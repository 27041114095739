import { createReducer } from '@reduxjs/toolkit';
import get from 'lodash/get';
import {
  getBillOfMaterialsListFailure,
  getBillOfMaterialsListStart,
  getBillOfMaterialsListSuccess,
  setFilteredItems,
} from './billOfMaterialsActions';
import { ReloadButton, showActionFailure } from '../../../shared/utils/Notifications';
import { resetAppState } from '../../../../reducers';

const initialState = {
  loading: false,
  items: [],
  filteredItems: [],
};

export const isLoading = state => get(state, 'bom.loading');
export const getBomRows = state => get(state, 'bom.items');
export const getBomRowsFiltered = state => get(state, 'bom.filteredItems', []);

const billOfMaterialsReducer = createReducer(initialState, {
  [getBillOfMaterialsListStart](state) {
    state.items = [];
    state.filteredItems = [];
    state.loading = true;
  },
  [getBillOfMaterialsListSuccess](state, action) {
    state.items = get(action, 'payload.result');
    state.filteredItems = get(action, 'payload.result');
    state.loading = false;
  },
  [getBillOfMaterialsListFailure](state, action) {
    state.items = [];
    state.filteredItems = [];
    state.loading = false;
    showActionFailure(action, 'Failed to load bill of materials', ReloadButton);
  },
  [setFilteredItems](state, action) {
    state.filteredItems = get(action, 'payload');
  },
  [resetAppState](state) {
    state.items = [];
    state.filteredItems = [];
    state.loading = false;
  },
});

export default billOfMaterialsReducer;
