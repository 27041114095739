import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getFilteredRows, getHeaders, getSelectedVariant, isLoading } from './WorkQueueSelectors';
import { ExportDataToExcel } from '../../../shared/ExportToExcel';

export const WQActionDropdown = () => {
  const loading = useSelector(state => isLoading(state));
  const columnsFromSAP = useSelector(state => getHeaders(state));
  const rows = useSelector(state => getFilteredRows(state));
  const variant = useSelector(state => getSelectedVariant(state));

  const exportDataProvider = useCallback(
    () => ({
      headers: columnsFromSAP
        .map(header => ({ key: header.key, label: header.name }))
        .filter(header => header.key !== 'print'),
      data: rows,
    }),
    [columnsFromSAP, rows],
  );

  return (
    <DropdownMenu
      trigger="Actions"
      triggerType="button"
      triggerButtonProps={{ appearance: 'primary', isDisabled: loading }}
    >
      <DropdownItemGroup>
        <DropdownItem>
          <ExportDataToExcel exportDataProvider={exportDataProvider} filename={`${variant}`} name="Work queue" />
        </DropdownItem>
      </DropdownItemGroup>
    </DropdownMenu>
  );
};
