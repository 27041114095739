import { createReducer } from '@reduxjs/toolkit';
import Get from 'lodash/get';
import {
  convertSerialNumberFailure,
  convertSerialNumberStart,
  convertSerialNumberSuccess,
  genericSapPrintoutFailure,
  genericSapPrintoutStart,
  genericSapPrintoutSuccess,
  getTracingDataFailure,
  getTracingDataStart,
  getTracingDataSuccess,
  printRatingPlateFailure,
  printRatingPlateStart,
  printRatingPlateSuccess,
  saveTraceableComponentsFailure,
  saveTraceableComponentsStart,
  saveTraceableComponentsSuccess,
  updateDataForComponent,
  updateSerialNumberForComponent,
} from './TracingActions';
import {
  getInfoMessageFromAction,
  ReloadButton,
  showActionFailure,
  showSuccessNotification,
} from '../../../shared/utils/Notifications';
import { mergeTraceableComponents, updateTraceableComponent } from './TracingUtils';
import { setButtonLoading } from '../../../shared/utils/loadingButtons';
import { showRePrintRowsDialog } from '../RePrintDialog/RePrintRatingPlateDialog';

const initialState = {
  loading: false,
  header: {},
  components: [],
  buttons: [],
  updatedComponents: [],
  buttonLoading: [],
  showRePrintRatingPlateDialog: false,
};

const updatePendingModifications = (state, material, updatedData) => {
  const components = Get(state, 'components', []);
  const stateUpdatedComponents = Get(state, 'updatedComponents', []);
  const updatedComponents = updateTraceableComponent(components, material, updatedData);
  state.updatedComponents = mergeTraceableComponents(stateUpdatedComponents, updatedComponents);
};

export const TracingReducer = createReducer(initialState, {
  [getTracingDataStart](state) {
    state.loading = true;
    state.header = {};
    state.components = [];
    state.updatedComponents = [];
    state.showRePrintRatingPlateDialog = false;
  },
  [getTracingDataSuccess](state, action) {
    state.loading = false;
    state.header = Get(action, 'payload.result.header', {});
    state.components = Get(action, 'payload.result.traceableComponents', []);
    state.buttons = Get(action, 'payload.result.buttons', []);
  },
  [getTracingDataFailure](state, action) {
    state.loading = false;
    showActionFailure(action, 'Failed to load tracing data', ReloadButton);
  },
  'trace/updateSerialNumberFailed'(state, action) {
    showActionFailure(action, 'Failed to update serial number');
  },
  [updateSerialNumberForComponent](state, action) {
    const { material, serialNumber, username, unitCount } = Get(action, 'payload', {});

    updatePendingModifications(state, material, {
      serialNumber,
      unitCount,
      traceUser: username,
    });
  },
  [updateDataForComponent](state, action) {
    const material = Get(action, 'payload.material', '');
    const updatedData = Get(action, 'payload.data', {});

    updatePendingModifications(state, material, updatedData);
  },
  [convertSerialNumberStart](state) {
    state.loading = true;
  },
  [convertSerialNumberSuccess](state) {
    state.loading = false;
  },
  [convertSerialNumberFailure](state) {
    state.loading = false;
  },
  [saveTraceableComponentsStart](state) {
    setButtonLoading(state, 'SAVE_TRACE', true);
  },
  [saveTraceableComponentsSuccess](state, action) {
    setButtonLoading(state, 'SAVE_TRACE', false);
    showSuccessNotification(getInfoMessageFromAction(action, 'Traceable components updated'));
    state.components = mergeTraceableComponents(state.components, state.updatedComponents);
    state.updatedComponents = [];
  },
  [saveTraceableComponentsFailure](state, action) {
    setButtonLoading(state, 'SAVE_TRACE', false);
    showActionFailure(action, 'Failed to update traceable components');
  },
  [genericSapPrintoutStart](state, action) {
    const buttonCode = Get(action, 'meta.buttonCode', '');
    setButtonLoading(state, buttonCode, true);
  },
  [genericSapPrintoutSuccess](state, action) {
    const buttonCode = Get(action, 'meta.buttonCode', '');
    setButtonLoading(state, buttonCode, false);
    showSuccessNotification(getInfoMessageFromAction(action, 'Action succeeded'));
  },
  [genericSapPrintoutFailure](state, action) {
    const buttonCode = Get(action, 'meta.buttonCode', '');
    setButtonLoading(state, buttonCode, false);
    showActionFailure(action, 'Action failed');
  },
  [showRePrintRowsDialog](state, action) {
    state.showRePrintRatingPlateDialog = Get(action, 'payload', false);
  },
  [printRatingPlateStart](state, action) {
    const buttonCode = Get(action, 'meta.buttonCode', '');
    setButtonLoading(state, buttonCode, true);
  },
  [printRatingPlateSuccess](state, action) {
    const buttonCode = Get(action, 'meta.buttonCode', '');
    setButtonLoading(state, buttonCode, false);
  },
  [printRatingPlateFailure](state, action) {
    const buttonCode = Get(action, 'meta.buttonCode', '');
    setButtonLoading(state, buttonCode, false);
  },
});
