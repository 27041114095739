import React, { useState } from 'react';

import { RadioGroup } from '@atlaskit/radio';
import SearchMaterialForm from './SearchMaterialForm';
import { Column, Row } from 'simple-flexbox';

import styled from 'styled-components';
import { BomTypeMaterial, BomTypeSalesOrder } from '../utils/contstans';
import PropTypes from 'prop-types';

const SearchOptions = [
  {
    name: 'searchType',
    value: BomTypeMaterial,
    label: 'Material',
  },
  {
    name: 'searchType',
    value: BomTypeSalesOrder,
    label: 'Sales Order',
  },
];

const PrintSearchModeRadios = styled(Column)`
  margin-right: 2rem;
  margin-top: 8px;
`;

const SearchFormStyled = styled(Column)`
  min-height: 100px;
`;

const SearchMaterial = ({ onSubmitSearch, searching }) => {
  const [searchType, setSearchType] = useState(BomTypeMaterial);
  return (
    <>
      <Row wrap flexGrow={1}>
        <PrintSearchModeRadios>
          <RadioGroup defaultValue={searchType} options={SearchOptions} onChange={e => setSearchType(e.target.value)} />
        </PrintSearchModeRadios>
        <SearchFormStyled flexGrow={2}>
          <SearchMaterialForm onSubmitSearch={onSubmitSearch} searchType={searchType} searching={searching} />
        </SearchFormStyled>
      </Row>
    </>
  );
};

SearchMaterial.propTypes = {
  onSubmitSearch: PropTypes.func.isRequired,
  searching: PropTypes.bool.isRequired,
};

export default SearchMaterial;
