import Find from 'lodash/find';
import Remove from 'lodash/remove';

export const mergeTraceableComponents = (components, overridingComponents) => {
  const copyOfOverriding = [...overridingComponents];
  if (overridingComponents.length === 0) {
    return components;
  }

  const merged = components.map(component => {
    const overridingComponent = Find(
      copyOfOverriding,
      x => x.material === component.material && x.unitCount === component.unitCount,
    );

    if (overridingComponent) {
      Remove(copyOfOverriding, overridingComponent);
      return {
        ...component,
        ...overridingComponent,
      };
    }
    return component;
  });

  if (copyOfOverriding.length > 0) {
    return [...merged, ...copyOfOverriding];
  }

  return merged;
};

export const updateTraceableComponent = (components, materialCode, updatedData) => {
  return components
    .filter(component => component.material === materialCode && component.unitCount === updatedData.unitCount)
    .map(component => ({
      ...component,
      ...updatedData,
    }));
};
