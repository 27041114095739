import { createReducer } from '@reduxjs/toolkit';
import get from 'lodash/get';

import { getVariantListFailure, getVariantListStart, getVariantListSuccess } from './variantSelectionActions';
import { ReloadButton, showActionFailure } from '../../shared/utils/Notifications';

export const getVariants = state => get(state, 'selectVariant.variants');
export const isLoading = state => get(state, 'selectVariant.loading');

const initialState = {
  variants: [],
  selectedVariant: undefined,
  loading: false,
};

const variantSelectionReducer = createReducer(initialState, {
  [getVariantListStart](state) {
    state.variants = [];
    state.loading = true;
  },
  [getVariantListSuccess](state, action) {
    state.variants = get(action, 'payload.result').map(element => {
      return { label: element, value: element };
    });

    state.loading = false;
  },
  [getVariantListFailure](state, action) {
    state.variants = [];
    state.loading = false;
    showActionFailure(action, 'Failed to load variants', ReloadButton);
  },
});

export default variantSelectionReducer;
