import { createAction } from '@reduxjs/toolkit';
import { buildApiUrl, DownloadDocumentsEndpoint } from '../../shared/utils/ApiUtils';
import { triggerSapFileDownload } from '../Manufacturing/WorkQueue/WorkQueueActions';
import PadStart from 'lodash/padStart';

export const printWorkOrderStart = createAction('printWO/printWorkOrderStart');
export const printWorkOrderSuccess = createAction('printWO/printWorkOrderSuccess');
export const printWorkOrderFailure = createAction('printWO/printWorkOrderFailure');

const rsaaActionTypes = [printWorkOrderStart, printWorkOrderSuccess, printWorkOrderFailure];

export const printWorkOrder = productionOrder => dispatch => {
  const paddedProductionOrder = PadStart(productionOrder, 12, '0');

  const url = buildApiUrl(DownloadDocumentsEndpoint);

  const printoutDataProvider = () => {
    return [
      {
        prodLine: '1105', // Hard coded in old MSP
        prodOrder: paddedProductionOrder.trim(),
        serial: '',
      },
    ];
  };

  return dispatch(triggerSapFileDownload('PRINTWO', url, printoutDataProvider, rsaaActionTypes));
};
