import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import TableTree, { Cell, Header, Headers, Row, Rows } from '@atlaskit/table-tree';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@atlaskit/button';
import { downloadFile } from './DocumentSearchActions';
import PageLoading from '../../shared/PageLoading';

const FileDownloadLink = ({ docFile, documentNumber, documentPart, documentVersion, documentType }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onFiledownloadCompleted = () => setLoading(false);

  const onClickDownload = useCallback(() => {
    setLoading(true);
    dispatch(
      downloadFile(documentNumber, docFile, documentPart, documentVersion, documentType, onFiledownloadCompleted),
    );
  }, [dispatch, documentNumber, docFile, documentPart, documentVersion, documentType]);

  return (
    <LoadingButton appearance="link" onClick={onClickDownload} isLoading={loading}>
      {docFile}
    </LoadingButton>
  );
};
const FilesTable = ({ files }) => {
  return (
    <TableTree>
      <Headers>
        <Header width={500}>File</Header>
        <Header width={100}>Part</Header>
        <Header width={100}>Type</Header>
        <Header width={100}>Version</Header>
      </Headers>
      <Rows
        items={files}
        render={({ docFile, documentNumber, documentPart, documentType, documentVersion }) => (
          <Row itemId={docFile} hasChildren={false}>
            <Cell singleLine>
              <FileDownloadLink
                docFile={docFile}
                documentNumber={documentNumber}
                documentVersion={documentVersion}
                documentPart={documentPart}
                documentType={documentType}
              />
            </Cell>
            <Cell>{documentPart}</Cell>
            <Cell>{documentType}</Cell>
            <Cell>{documentVersion}</Cell>
          </Row>
        )}
      />
    </TableTree>
  );
};

FilesTable.propTypes = {
  files: PropTypes.array.isRequired,
};

export const DownloadFilesModalDialog = ({ files, isLoading, isOpen, onClose }) => {
  const actions = [{ text: 'Close', onClick: onClose }];
  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal actions={actions} onClose={onClose} heading="Download files" width="large" autoFocus={false}>
            <PageLoading visible={isLoading} />
            {!isLoading && <FilesTable files={files} />}
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

DownloadFilesModalDialog.propTypes = {
  files: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

FileDownloadLink.propTypes = {
  docFile: PropTypes.string.isRequired,
  documentNumber: PropTypes.string.isRequired,
  documentPart: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
  documentVersion: PropTypes.string.isRequired,
};
