import get from 'lodash/get';

export const getUserPermissions = state => get(state, 'auth.roles', []);
export const getVendorId = state => get(state, 'auth.vendorId');
export const getPlantId = state => get(state, 'auth.plantId');
export const getIdentifiers = state => get(state, 'auth.identifiers', []);

export const hasUserPermission = (state, permission) => getUserPermissions(state).includes(permission);
export const hasRolesBeenFetched = state => get(state, 'auth.rolesFetched', false);

export const isLoadingUserInformation = state => get(state, 'auth.loading', false);
export const isAuthenticated = state => get(state, 'auth.authenticated', false);
