import React, { useCallback } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { useDispatch, useSelector } from 'react-redux';
import { closeCreateSerialNumberDialog, createSerialNumber } from './WorkQueueActions';
import { getCreateSerialNumberDialogLoading, getShowCreateSerialNumberDialog } from './WorkQueueSelectors';

export const CreateSerialNumberDialog = () => {
  const dispatch = useDispatch();

  const showDialog = useSelector(state => getShowCreateSerialNumberDialog(state));
  const loading = useSelector(state => getCreateSerialNumberDialogLoading(state));

  const onCreateSerialNumber = useCallback(() => {
    dispatch(createSerialNumber());
  }, [dispatch]);

  const onCloseDialog = useCallback(() => {
    dispatch(closeCreateSerialNumberDialog());
  }, [dispatch]);

  if (!showDialog) {
    return null;
  }
  const actions = [
    { text: 'Create', onClick: onCreateSerialNumber, isLoading: loading },
    { text: 'Close', onClick: onCloseDialog, isDisabled: loading },
  ];

  const message = loading ? 'Creating serial number. Please wait...' : 'No serial number exists. Create one?';
  return (
    <ModalTransition>
      <Modal
        key="active-modal"
        actions={actions}
        onClose={onCloseDialog}
        heading="Create serial number"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscapePress={false}
      >
        {message}
      </Modal>
    </ModalTransition>
  );
};
