import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import { Data, Filters } from 'react-data-grid-addons';
import { isMultiselectVariant } from './WorkQueueUtils';
import {
  CreateSalesDocumentLink,
  NumberFormatter,
  OrderNumberLink,
  PrintPackageLabel,
  ProductCodeFormatter,
} from './DataGridFormatters';
import Head from 'lodash/head';
import Difference from 'lodash/difference';
import { addToEachColumn, GridColumnFormatter, mergeByKey } from '../../../shared/utils/gridUtils';
import EmptyGridState from '../../../shared/Grid/EmptyGridState';
import { SmallerFont } from '../../../shared/Grid/SmallerFont';
import { hasUserPermission } from '../../../shared/Auth/authSelectors';
import { UseSingleSelectFilterForOrderNumber } from '../../../shared/Auth/permissions';
import { CustomSimpleTextEditor } from './DataGridEditors';
import { useDispatch, useSelector } from 'react-redux';
import { setFilteredRows } from './WorkQueueActions';
import { getFilteredRows } from './WorkQueueSelectors';

const selectors = Data.Selectors;
const { SingleSelectFilter } = Filters;

const globalGridColumnProperties = { resizable: true, filterable: true };

const replaceDotsInDate = date => date.replace(/\./g, '-');

const getCustomColumnProperties = variant => {
  const shouldUseSingleSelectFilter = useSelector(state =>
    hasUserPermission(state, UseSingleSelectFilterForOrderNumber),
  );
  return [
    {
      key: 'prodOrder',
      formatter: OrderNumberLink,
      filterRenderer: shouldUseSingleSelectFilter ? SingleSelectFilter : undefined,
    },
    {
      key: 'salesdocument',
      formatter: CreateSalesDocumentLink(variant),
    },
    {
      key: 'productCode',
      formatter: ProductCodeFormatter,
    },
    {
      key: 'totalQty',
      formatter: NumberFormatter,
    },
    {
      key: 'salesQty',
      formatter: NumberFormatter,
    },
    {
      key: 'sumCounter',
      formatter: NumberFormatter,
    },
    {
      key: 'status',
      editable: true,
    },
    {
      key: 'statusLong',
      editable: true,
    },
    {
      key: 'mspdate',
      editable: true,
      editor: <CustomSimpleTextEditor placeholder="yyyy-mm-dd" formatter={replaceDotsInDate} />,
    },
    {
      key: 'print',
      formatter: PrintPackageLabel,
    },
  ];
};

const staticColumnWidths = {
  print: 130,
  poLongText: 300,
};
const enableGridFilterFeature = grid => {
  grid.onToggleFilter();
};

const filterRows = (rows, filters) => selectors.getRows({ rows, filters });

export const WorkQueueGrid = React.memo(
  ({ loading, gridColumns, gridRows, variant, onRowsSelected, onRowsChanged }) => {
    const dispatch = useDispatch();

    const [selectedRows, setSelectedRows] = useState([]);
    const [filters, setFilters] = useState({});
    const filteredRows = useSelector(state => getFilteredRows(state));

    const columnWidthFormatter = new GridColumnFormatter(staticColumnWidths);

    const dataGridRef = useRef(undefined);

    useEffect(() => {
      const rowIds = selectedRows.map(selected => filteredRows[selected]).map(row => row.id);
      onRowsSelected(rowIds);
    }, [selectedRows, filteredRows, onRowsSelected]);

    useEffect(() => {
      setSelectedRows([]);
    }, [gridRows.length]);

    useEffect(() => {
      const filteredRows = filterRows(gridRows, filters);
      dispatch(setFilteredRows(filteredRows));
    }, [filters, gridRows, dispatch]);

    const handleFilterChange = useCallback(
      filter => {
        const newFilters = { ...filters };
        if (filter.filterTerm) {
          newFilters[filter.column.key] = filter;
        } else {
          delete newFilters[filter.column.key];
        }
        setFilters(newFilters);
        setSelectedRows([]);
      },
      [filters],
    );

    const getValidFilterValues = useCallback((rows, columnId) => {
      return rows
        .map(r => r[columnId])
        .filter((item, i, a) => {
          return i === a.indexOf(item);
        });
    }, []);

    const onRowUpdated = useCallback(
      ({ fromRow, toRow, updated }) => {
        const updatedRows = filteredRows.slice(fromRow, toRow + 1);
        const updatedRowIds = updatedRows.map(row => row.id);
        onRowsChanged(updatedRowIds, updated);
      },
      [filteredRows, onRowsChanged],
    );

    const onRowsSelectedSingle = rows => {
      const selectedIndexes = Head(rows.map(row => row.rowIdx));

      setSelectedRows([selectedIndexes]);
    };

    const onRowsSelectedMultiple = rows => {
      const selectedIndexes = rows.map(row => row.rowIdx);

      const selected = [...selectedRows, ...selectedIndexes];
      setSelectedRows(selected);
    };

    const onRowsDeselected = rows => {
      const deselectedIndexes = rows.map(row => row.rowIdx);

      setSelectedRows(Difference(selectedRows, deselectedIndexes));
    };

    const autoOpenCellEditor = useCallback(
      event => {
        const column = dataGridRef.current.getColumn(event.idx);
        if (column.editable) {
          dataGridRef.current.openCellEditor(event);
        }
      },
      [dataGridRef],
    );

    useEffect(() => {
      enableGridFilterFeature(dataGridRef.current);
    }, [dataGridRef]);

    let dataGridColumns = addToEachColumn(gridColumns, globalGridColumnProperties);
    dataGridColumns = mergeByKey(dataGridColumns, getCustomColumnProperties(variant));

    return (
      <SmallerFont>
        <ReactDataGrid
          ref={dataGridRef}
          columns={columnWidthFormatter.formatColumnsWidth(gridRows, dataGridColumns, dataGridRef, 8)}
          rowGetter={i => filteredRows[i]}
          rowsCount={filteredRows.length}
          emptyRowsView={() => EmptyGridState(loading)}
          enableCellSelect={true}
          onGridRowsUpdated={onRowUpdated}
          enableCellAutoFocus={false}
          enableDragAndDrop={false}
          minHeight={window.innerHeight - 350}
          onCellSelected={autoOpenCellEditor}
          rowSelection={{
            showCheckbox: true,
            enableShiftSelect: false,
            onRowsSelected: isMultiselectVariant(variant) ? onRowsSelectedMultiple : onRowsSelectedSingle,
            onRowsDeselected: onRowsDeselected,
            selectBy: {
              indexes: selectedRows,
            },
          }}
          onAddFilter={handleFilterChange}
          onClearFilters={() => setFilters({})}
          getValidFilterValues={columnKey => getValidFilterValues(gridRows, columnKey)}
          enableRowSelect={null}
        />
      </SmallerFont>
    );
  },
);

WorkQueueGrid.propTypes = {
  gridColumns: PropTypes.array.isRequired,
  gridRows: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onRowsSelected: PropTypes.func,
  onRowsChanged: PropTypes.func,
  variant: PropTypes.string.isRequired,
};
