import React from 'react';
import styled from 'styled-components';

const GridCenteredText = styled.h2`
  text-align: center;
  padding: 25px;
`;

const EmptyGridState = (loading, message = 'No data to show') => {
  if (loading) {
    return <GridCenteredText>Loading data, please wait...</GridCenteredText>;
  }

  return <GridCenteredText>{message}</GridCenteredText>;
};

export default EmptyGridState;
