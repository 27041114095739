import { createReducer } from '@reduxjs/toolkit';
import Get from 'lodash/get';
import {
  getPrintingSearchResultsFailure,
  getPrintingSearchResultsStart,
  getPrintingSearchResultsSuccess,
} from './printingActions';
import { showActionFailure } from '../../shared/utils/Notifications';
import {
  sapFileDownloadFailure,
  sapFileDownloadStart,
  sapFileDownloadSuccess,
} from '../BillOfMaterials/BomDetails/billOfMaterialsDetailsActions';

const initialState = {
  searching: false,
  searchResult: {},
  bomType: undefined,
  downloading: false,
};

export const isDownloading = state => Get(state, 'printing.downloading');
export const isSearching = state => Get(state, 'printing.searching');
export const isLoading = state => isDownloading(state) || isSearching(state);
export const getSearchResult = state => Get(state, 'printing.searchResult');
export const getBomType = state => Get(state, 'printing.bomType');

const printingReducer = createReducer(initialState, {
  [getPrintingSearchResultsStart](state) {
    state.searching = true;
    state.searchResult = [];
  },
  [getPrintingSearchResultsSuccess](state, action) {
    state.searching = false;
    state.searchResult = Get(action, 'payload.result');
    state.bomType = action.meta.bomType;
  },
  [getPrintingSearchResultsFailure](state, action) {
    state.searching = false;
    showActionFailure(action, 'Failed to load material');
  },
  [sapFileDownloadStart](state) {
    state.downloading = true;
  },
  [sapFileDownloadFailure](state) {
    state.downloading = false;
  },
  [sapFileDownloadSuccess](state) {
    state.downloading = false;
  },
});

export default printingReducer;
