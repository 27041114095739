import PropTypes from 'prop-types';
import ReactDataGrid from 'react-data-grid';
import React, { useState, useRef } from 'react';
import Button from '@atlaskit/button';
import { useDispatch } from 'react-redux';
import Xor from 'lodash/xor';
import Find from 'lodash/find';
import { mergeTraceableComponents } from './TracingUtils';
import { updateDataForComponent } from './TracingActions';
import { UpdateSerialNumber } from './UpdateSerialNumber';
import EmptyGridState from '../../../shared/Grid/EmptyGridState';
import { SmallerFont } from '../../../shared/Grid/SmallerFont';

const columns = [
  {
    key: 'edit',
    width: 140,
    frozen: true,
  },
  {
    key: 'material',
    name: 'Material',
  },
  {
    key: 'description',
    name: 'Description',
  },
  {
    key: 'typeDesignation',
    name: 'Type designation',
  },
  {
    key: 'serialNumber',
    name: 'Serial no.',
    editable: true,
  },
  {
    key: 'traceUser',
    name: 'User',
    width: 100,
    editable: true,
  },
  {
    key: 'swVersion',
    name: 'SW version',
    editable: true,
  },
  {
    key: 'unitCount',
    name: 'Unit count',
    width: 100,
  },
  {
    key: 'position',
    name: 'Position',
    width: 100,
  },
];

export const TracingComponentsGrid = ({ components, loading }) => {
  const dispatch = useDispatch();

  const [rowsBeingEdited, setRowsBeingEdited] = useState([]);
  const editedRows = useRef([]);
  const [username, setUsername] = useState(undefined);

  const rowId = (material, unitCount) => `${material}-${unitCount}`;

  const enableEditing = (material, unitCount) => {
    setRowsBeingEdited(previous => previous.concat([rowId(material, unitCount)]));
  };

  const cancelEdit = (material, unitCount) => {
    setRowsBeingEdited(previous => Xor(previous, [rowId(material, unitCount)]));
    editedRows.current = editedRows.current.filter(row => row.material !== material && row.unitCount !== unitCount);
  };

  const applyEditedData = (material, unitCount) => {
    setTimeout(() => {
      let updatedData = Find(editedRows.current, x => x.material === material && x.unitCount === unitCount);

      const appendTraceUserToData =
        updatedData && !updatedData.traceUser && username ? { ...updatedData, traceUser: username } : updatedData;

      dispatch(updateDataForComponent(material, appendTraceUserToData));

      cancelEdit(material, unitCount);
    }, 0);
  };

  const onRowsUpdated = ({ fromRow, toRow, updated }) => {
    let affectedRows = components.slice(fromRow, toRow + 1);
    affectedRows = mergeTraceableComponents(affectedRows, editedRows.current);

    const updatedRows = affectedRows.map(row => ({
      ...row,
      ...updated,
    }));
    editedRows.current = [...updatedRows];
  };

  const componentsWithEditState = components.map(component => ({
    ...component,
    edit: rowsBeingEdited.includes(rowId(component.material, component.unitCount)),
  }));

  const componentsWithUpdatedData = mergeTraceableComponents(componentsWithEditState, editedRows.current);

  const checkSellIsEditable = ({ row }) => {
    return row.edit;
  };

  const getEditCellButtons = (column, row) => {
    if (column.key === 'edit') {
      const isEditing = rowsBeingEdited.includes(rowId(row.material, row.unitCount));

      const nonEditButtons = [
        {
          icon: <Button appearance="link">Edit</Button>,
          callback: () => enableEditing(row.material, row.unitCount),
        },
      ];
      const editButtons = [
        {
          icon: <Button appearance="link">Cancel</Button>,
          callback: () => cancelEdit(row.material, row.unitCount),
        },
        {
          icon: <Button appearance="link">Apply</Button>,
          callback: () => applyEditedData(row.material, row.unitCount),
        },
      ];

      return isEditing ? editButtons : nonEditButtons;
    }

    return null;
  };

  return (
    <>
      <UpdateSerialNumber onUsernameUpdated={username => setUsername(username)} />
      <SmallerFont>
        <ReactDataGrid
          columns={columns}
          rowGetter={i => componentsWithUpdatedData[i]}
          onCheckCellIsEditable={checkSellIsEditable}
          onGridRowsUpdated={onRowsUpdated}
          rowsCount={components.length}
          minColumnWidth={150}
          enableCellSelect={true}
          enableCellAutoFocus={false}
          enableDragAndDrop={false}
          getCellActions={getEditCellButtons}
          minHeight={Math.min(window.innerHeight - 200, components.length * 35 + 37 + 15)}
          emptyRowsView={() => EmptyGridState(loading)}
          enableRowSelect={null}
        />
      </SmallerFont>
    </>
  );
};

TracingComponentsGrid.propTypes = {
  components: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};
