import PageLoading from '../../shared/PageLoading';
import AbbTitle from '../../shared/AbbSectionTitle';
import React, { useCallback } from 'react';
import Form, { Field } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { printWorkOrder } from './PrintWorkOrderActions';
import { isLoading } from './PrintWorkOrderSelectors';
import SearchIcon from '@atlaskit/icon/glyph/search';
import { LoadingButton } from '@atlaskit/button';
import { DownloadUserGuide } from '../../shared/DownloadUserGuide';

const FormFields = styled.div`
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 22rem;
  margin-bottom: 3rem;
`;

export const PrintWorkOrderPage = () => {
  const dispatch = useDispatch();

  const loading = useSelector(state => isLoading(state));

  const onSubmit = useCallback(
    ({ productionOrder }) => {
      dispatch(printWorkOrder(productionOrder));
    },
    [dispatch],
  );

  return (
    <>
      <>
        <PageLoading visible={loading} />
      </>
      <AbbTitle title="Print work order">
        <DownloadUserGuide filename="/user-manuals/user-guide_print-wo.docx" />
      </AbbTitle>
      <Form onSubmit={onSubmit}>
        {({ formProps, dirty, submitting }) => (
          <form {...formProps}>
            <FormFields>
              <Field name="productionOrder" label="Production order" defaultValue="" isRequired>
                {({ fieldProps }) => (
                  <TextField maxLength={18} type="text" width="large" style={{ width: '15rem' }} {...fieldProps} />
                )}
              </Field>
              <LoadingButton
                type="submit"
                iconBefore={<SearchIcon />}
                isDisabled={!dirty || submitting || loading}
                isLoading={loading}
                appearance="primary"
              >
                Open
              </LoadingButton>
            </FormFields>
          </form>
        )}
      </Form>
    </>
  );
};
