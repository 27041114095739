import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PageLoading from '../../../shared/PageLoading';
import AbbTitle from '../../../shared/AbbSectionTitle';
import { getBomTree, getCheckedRows, isLoading } from './billOfMaterialsDetailsReducer';
import {
  downloadBomFiles,
  downloadSelectedBomFiles,
  fetchBillOfMaterialsDetails,
  updateCheckedRows,
} from './billOfMaterialsDetailsActions';
import BillOfMaterialsDetailsHeader from './componenets/BillOfMaterialsDetailsHeader';
import BillOfMaterialsDetailsTreeGrid from './BillOfMaterialsDetailsTreeGrid';
import styled from 'styled-components';

import filterDeep from 'deepdash-es/filterDeep';
import * as qs from 'query-string';
import flatten from 'tree-flatten';
import SearchBar from '../../../shared/SearchBar';
import BillOfMaterialsOrderDetailsGrid from './componenets/BillOfMaterialsOrderDetailsGrid';
import BillOfMaterialsDetailsActionButtons, {
  DownloadAllButtonCode,
  DownloadSelectedButtonCode,
  PrintBomButtonCode,
} from './componenets/BillOfMaterialsDetailsActionButtons';
import { BomTypeMaterial, BomTypeProductionOrder, BomTypeSalesOrder } from '../../../shared/utils/contstans';
import { DownloadUserGuide } from '../../../shared/DownloadUserGuide';

const SmallFontDiv = styled.div`
  font-size: small;
`;

const SearchBarWrapper = styled.div`
  width: 350px;
  margin-bottom: 1rem;
`;

export const columnsMaterialDhlOrder = [
  {
    key: 'code',
    name: 'Code',
  },
  {
    key: 'rev',
    name: 'Rev',
  },
  {
    key: 'qty',
    name: 'Qty',
  },
  {
    key: 'description',
    name: 'Technical data / material description',
  },
  {
    key: 'typeDesignation',
    name: 'Type designation',
  },
];

export const columnsMaterial = [
  {
    key: 'pos',
    name: 'Pos',
  },
  {
    key: 'level',
    name: 'Level',
  },
  {
    key: 'code',
    name: 'Code',
  },
  {
    key: 'rev',
    name: 'Rev',
  },
  {
    key: 'qty',
    name: 'Qty',
  },
  {
    key: 'dumps',
    name: 'Phantom item',
  },
  {
    key: 'docs',
    name: 'Docs',
  },
  {
    key: 'prints',
    name: 'Prints',
  },
  {
    key: 'description',
    name: 'Technical data / material description',
  },
  {
    key: 'typeDesignation',
    name: 'Type designation',
  },
  {
    key: 'designation',
    name: 'Designation',
  },
  {
    key: 'comments',
    name: 'Comments',
  },
  {
    key: 'group',
    name: 'Substitute Group',
  },
  {
    key: 'lineChangeNo',
    name: 'BOM line change no',
  },
  {
    key: 'lineValidFrom',
    name: 'BOM line valid from',
  },
  {
    key: 'matRevChangeNo',
    name: 'Mat rev change number',
  },
  {
    key: 'matRevValidFrom',
    name: 'Mat rev valid from',
  },
  {
    key: 'docChangeNo',
    name: 'Doc change no',
  },
  {
    key: 'docValidFrom',
    name: 'Doc valid from',
  },
];
const columnsProductionOrder = [
  {
    key: 'pos',
    name: 'Pos',
  },
  {
    key: 'level',
    name: 'Level',
  },
  {
    key: 'code',
    name: 'Code',
  },
  {
    key: 'docs',
    name: 'Docs',
  },
  {
    key: 'prints',
    name: 'Prints',
  },
  {
    key: 'rev',
    name: 'Rev',
  },
  {
    key: 'description',
    name: 'Description',
  },
  {
    key: 'typeDesignation',
    name: 'Type designation',
  },
  {
    key: 'technicalDescription',
    name: 'Technical description',
  },
  {
    key: 'qty',
    name: 'Reqmts qty',
  },
  {
    key: 'saldo',
    name: 'Saldo',
  },
  {
    key: 'un',
    name: 'UoM',
  },
  {
    key: 'bulk',
    name: 'Bulk',
  },
  {
    key: 'backflush',
    name: 'Backflush',
  },
  {
    key: 'phantom',
    name: 'Phantom',
  },
  {
    key: 'sortString',
    name: 'Sort string',
  },
  {
    key: 'storageBin1',
    name: 'Bin1',
  },
  {
    key: 'storageBin2',
    name: 'Bin2',
  },
  {
    key: 'storageBin3',
    name: 'Bin3',
  },
  {
    key: 'storageBin4',
    name: 'Bin4',
  },
  {
    key: 'storageBin5',
    name: 'Bin5',
  },
  {
    key: 'storageBin6',
    name: 'Bin6',
  },
  {
    key: 'plant',
    name: 'Plant',
  },
  {
    key: 'sloc',
    name: 'SLoc',
  },
  {
    key: 'itca',
    name: 'ItCa',
  },
];

function BillOfMaterialsDetailsPage() {
  const dispatch = useDispatch();
  const { materialCode } = useParams();
  const location = useLocation();
  const loading = useSelector(state => isLoading(state));
  const data = useSelector(state => getBomTree(state));
  const checkedRows = useSelector(state => getCheckedRows(state));

  const [expansionMap, setExpansionMap] = useState({});
  const [bomType, setBomType] = useState({});
  const [filteredBomTree, setFilteredBomTree] = useState([]);
  const [flatRows, setFlatRows] = useState([]);
  const [columns, setCurrentColumns] = useState([]);
  const [areAllExpanded, setAreAllExpanded] = useState(false);
  const [hidePhantomItems, setHidePhantomItems] = useState(false);

  useEffect(() => {
    dispatch(fetchBillOfMaterialsDetails({ materialCode, ...qs.parse(location.search) }));
  }, [dispatch, materialCode, location]);

  useEffect(() => {
    setFilteredBomTree(data.rows);
    setFlatRows(data.rows.flatMap(x => flatten(x, 'children')));
  }, [data]);

  useEffect(() => {
    setBomType(qs.parse(location.search).bomType);
    switch (qs.parse(location.search).bomType) {
      case BomTypeMaterial:
      default:
        setCurrentColumns(columnsMaterial);
        break;
      case BomTypeProductionOrder:
      case BomTypeSalesOrder:
        setCurrentColumns(columnsProductionOrder);
        break;
    }
  }, [location]);

  const createExpansionMap = useCallback(
    data => {
      let flatIdsList = [];
      data && data.forEach(x => flatIdsList.push(flatten(x, 'children')));
      flatIdsList = flatIdsList.flat();

      if (flatIdsList.length === flatRows.length) {
        return {};
      } else {
        return {
          ...expansionMap,
          ...flatIdsList
            .map(x => x.id)
            .reduce((acc, id) => {
              return { ...acc, [id]: true };
            }, {}),
        };
      }
    },
    [expansionMap, flatRows],
  );

  const setFilteredItems = useCallback(
    rows => {
      const ids = rows.map(x => x.id);
      let filtered = filterDeep(data.rows, x => ids.includes(x.id), {
        tree: true,
        childrenPath: 'children',
      });
      setFilteredBomTree(filtered || []);

      setExpansionMap(createExpansionMap(filtered));
    },
    [createExpansionMap, data.rows],
  );

  const handleDownloadAction = useCallback(
    e => {
      switch (e.type) {
        case DownloadSelectedButtonCode:
          dispatch(downloadSelectedBomFiles(e.type, data.header.materialCode, checkedRows));
          break;
        case DownloadAllButtonCode:
        case PrintBomButtonCode:
          dispatch(downloadBomFiles(e.type, data.header.materialCode, null, bomType, e.level));
          break;
        default:
          console.error('Wrong download action type');
      }
    },
    [checkedRows, data, dispatch, bomType],
  );

  const filterBomTreeLevel = useCallback(
    level => {
      const filteredBom = filterDeep(data.rows, x => x.level <= level, { tree: true, childrenPath: 'children' });
      setFilteredBomTree(filteredBom || []);
    },
    [data],
  );

  const fuseConfig = {
    threshold: 0.0,
    tokenize: true,
    keys: columns.map(column => column.key),
    minMatchCharLength: 2,
  };

  const toggleAllRows = () => {
    const newExpansionMap = {};
    flatRows.forEach(row => {
      newExpansionMap[generateId(row)] = !areAllExpanded;
    });
    setExpansionMap(newExpansionMap);
    setAreAllExpanded(!areAllExpanded);
  };

  const generateId = element => {
    return `${element.parentId}-${element.code}-${element.pos}`;
  };

  function removePhantomItems(row) {
    if (row.dumps === 'X') {
      if (row.children && row.children.length > 0) {
        return row.children.flatMap(removePhantomItems);
      } else {
        return [];
      }
    } else {
      let processedChildren = [];
      if (row.children && row.children.length > 0) {
        processedChildren = row.children.flatMap(removePhantomItems);
      }
      return [
        {
          ...row,
          id: generateId(row),
          children: processedChildren,
        },
      ];
    }
  }

  const dataWithId = hidePhantomItems
    ? filteredBomTree.flatMap(removePhantomItems)
    : filteredBomTree.map(row => {
        return { ...row, id: generateId(row) };
      });

  return (
    <>
      <PageLoading visible={loading} />
      <AbbTitle title={`Bill of materials: ${materialCode}`}>
        <DownloadUserGuide filename="/user-manuals/user-guide_bill-of-materials.docx" />
      </AbbTitle>

      {(bomType === BomTypeProductionOrder || bomType === BomTypeSalesOrder) && (
        <BillOfMaterialsOrderDetailsGrid orderData={data && new Array(data.header)} />
      )}
      <BillOfMaterialsDetailsActionButtons
        onDownloadAction={handleDownloadAction}
        checkedRows={checkedRows}
        onLevelSelected={filterBomTreeLevel}
        bomData={dataWithId}
        areAllExpanded={areAllExpanded}
        toggleAllRows={toggleAllRows}
        hidePhantomItems={hidePhantomItems}
        setHidePhantomItems={setHidePhantomItems}
      />
      <BillOfMaterialsDetailsHeader bomType={bomType} bomHeader={data && data.header} />
      <SearchBarWrapper>
        <SearchBar isDisabled={loading} data={flatRows} onFiltered={setFilteredItems} fuseConfig={fuseConfig} />
      </SearchBarWrapper>
      <SmallFontDiv>
        <BillOfMaterialsDetailsTreeGrid
          bomType={bomType}
          bomNumber={materialCode}
          data={dataWithId}
          cols={columns}
          flatRows={flatRows}
          expansionMap={expansionMap}
          showCheckbox={true}
          onCheckboxClick={e => dispatch(updateCheckedRows({ [e.rowId]: e.checked }))}
          onRowExpanded={row => setExpansionMap({ ...expansionMap, [row.id]: true })}
          onRowCollapsed={row => setExpansionMap({ ...expansionMap, [row.id]: false })}
          checkedRows={checkedRows}
          gridHeight={window.innerHeight - (bomType === BomTypeMaterial ? 400 : 480)}
        />
      </SmallFontDiv>
    </>
  );
}

export default BillOfMaterialsDetailsPage;
