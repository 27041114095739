import { createAction } from '@reduxjs/toolkit';
import { buildApiUrl } from '../../shared/utils/ApiUtils';
import { RSAA } from 'redux-api-middleware';

export const getPrintingSearchResultsStart = createAction('printing/getPrintingSearchResultsStart');
export const getPrintingSearchResultsSuccess = createAction('printing/getPrintingSearchResultsSuccess');
export const getPrintingSearchResultsFailure = createAction('printing/getPrintingSearchResultsFailure');

export const searchBillOfMaterials = query => dispatch => {
  const url = buildApiUrl(`bom/search`);

  const meta = {
    bomType: query.bomType,
  };

  return dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(query),
      types: [
        { type: getPrintingSearchResultsStart.type, meta },
        { type: getPrintingSearchResultsSuccess.type, meta },
        { type: getPrintingSearchResultsFailure.type, meta },
      ],
    },
  });
};
