import PropTypes from 'prop-types';
import React from 'react';
import ReactDataGrid from 'react-data-grid';
import EmptyGridState from '../../../shared/Grid/EmptyGridState';
import { getGridHeight } from '../../../shared/utils/gridUtils';
import { SmallerFont } from '../../../shared/Grid/SmallerFont';

const columns = [
  {
    key: 'serialNumber',
    name: 'Serial number',
  },
  {
    key: 'material',
    name: 'Material',
  },
  {
    key: 'productionOrder',
    name: 'Production order',
  },
  {
    key: 'loadingDate',
    name: 'Loading date',
  },
  {
    key: ' sdDoc',
    name: 'Sales order',
  },
  {
    key: 'itmNumber',
    name: 'SO item',
  },
  {
    key: 'productCode',
    name: 'Type code',
    width: 250,
  },
  {
    key: 'productionLine',
    name: 'Production line',
  },
  {
    key: 'refDocument',
    name: 'Reference doc.',
  },
  {
    key: 'status',
    name: 'Status',
    width: 80,
  },
];

export const TracingHeaderGrid = ({ rows, loading }) => {
  return (
    <SmallerFont>
      <ReactDataGrid
        columns={columns}
        rowGetter={i => rows[i]}
        rowsCount={rows.length}
        minColumnWidth={150}
        enableCellSelect={true}
        enableCellAutoFocus={false}
        enableDragAndDrop={false}
        minHeight={getGridHeight(rows.length)}
        emptyRowsView={() => EmptyGridState(loading)}
        enableRowSelect={null}
      />
    </SmallerFont>
  );
};

TracingHeaderGrid.propTypes = {
  rows: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};
