import { createReducer } from '@reduxjs/toolkit';
import { printWorkOrderFailure, printWorkOrderStart, printWorkOrderSuccess } from './PrintWorkOrderActions';
import { showActionFailure } from '../../shared/utils/Notifications';

const initialState = {
  loading: false,
};

export const printWorkOrderReducer = createReducer(initialState, {
  [printWorkOrderStart](state) {
    state.loading = true;
  },
  [printWorkOrderSuccess](state) {
    state.loading = false;
  },
  [printWorkOrderFailure](state, action) {
    state.loading = false;
    showActionFailure(action, 'Failed to print work order');
  },
});
