import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const AbbCursor = styled.div`
  color: #ff000f;
`;

const SectionTitle = styled.div`
  font-size: 36px;
  margin-bottom: 32px;
  line-height: 45px;
  font-weight: 500;
`;

function AbbTitle(props) {
  const { title } = props;
  return (
    <SectionTitle>
      <AbbCursor>—</AbbCursor>
      {title}
      {props.children}
    </SectionTitle>
  );
}

export default AbbTitle;

AbbTitle.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
};

AbbTitle.defaultProps = {
  title: '',
};
