import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { apiMiddleware } from 'redux-api-middleware';
import rootReducer from './reducers';

import apiAuthHeaderInjector from './apiAuthHeaderInjector';
import adminVendorInjector from './adminVendorInjector';

export default function configureStore(initialState = {}, history) {
  let composeEnhancers = compose;

  if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
    /* eslint-disable no-underscore-dangle */
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
    }
  }
  const middlewares = [routerMiddleware(history), apiAuthHeaderInjector, adminVendorInjector, apiMiddleware, thunk];

  const enhancers = [applyMiddleware(...middlewares)];
  const store = createStore(rootReducer, initialState, composeEnhancers(...enhancers));

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducers', () => {
        store.replaceReducer(rootReducer);
      });
    }
  }

  return store;
}
