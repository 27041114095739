import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInformation } from './authActions';
import { isAuthenticated } from './authSelectors';

import { InteractionType, EventType } from '@azure/msal-browser';
import { MsalProvider, AuthenticatedTemplate, MsalAuthenticationTemplate } from '@azure/msal-react';
import { getMsalInstance } from './msal';

export const Authenticate = ({ store, children }) => {
  const dispatch = useDispatch();
  const authenticated = useSelector(state => isAuthenticated(state));
  const msalInstance = getMsalInstance();

  useEffect(() => {
    if (authenticated) {
      dispatch(getUserInformation());
    }
  }, [dispatch, authenticated]);

  msalInstance.addEventCallback(event => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
      store.dispatch({ type: 'AAD_LOGIN_SUCCESS', payload: event.payload });
    }
    if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
      // The event ACQUIRE_TOKEN_SUCCESS happens when a logged-in user refreshes the page.
      // Dispatching AAD_LOGIN_SUCCESS simply sets the 'authenticated' flag to true to remove the 'loading' indicator.
      store.dispatch({ type: 'AAD_LOGIN_SUCCESS', payload: event.payload });
    }
  });

  return (
    <MsalProvider instance={msalInstance}>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}></MsalAuthenticationTemplate>
    </MsalProvider>
  );
};

Authenticate.propTypes = {
  children: PropTypes.node.isRequired,
  store: PropTypes.object.isRequired,
};
