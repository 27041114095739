import dayjs from 'dayjs';
import { RSAA } from 'redux-api-middleware';
import { generateFilename, handleFileDownload } from '../../../shared/utils/FileDownloadUtils';

const determineProductionLineForMoGe = (row, productionLine) => {
  const serialNumberAndProductionLineMissing = !row.serialNumber && !productionLine;

  if (serialNumberAndProductionLineMissing) {
    return row.operationNumber;
  }

  return productionLine;
};

export const createPrintoutData = (workQueueRows, productionLine) => {
  return workQueueRows.map(row => {
    return {
      id: row.id,
      prodLine: determineProductionLineForMoGe(row, productionLine),
      prodOrder: row.prodOrder,
      serial: row.serialNumber,
      ebeln: row.ebeln,
      ebelp: row.ebelp,
    };
  });
};

const createDownloadCommand = buttonCode => {
  if (buttonCode.startsWith('WWW_')) {
    return buttonCode.slice(4);
  }
  return buttonCode;
};

export const downloadFileForEachProductLine = (
  prodLine,
  rows,
  dispatch,
  buttonCode,
  url,
  vendorId,
  rsaaActionTypes,
) => {
  const command = createDownloadCommand(buttonCode);

  const data = {
    command,
    prodLine,
    rows: rows.map(row => ({
      prodOrder: row.prodOrder,
      serial: row.serial,
      purchaseOrderNumber: row.ebeln,
      purchaseOrderItem: row.ebelp,
    })),
  };

  const meta = {
    buttonCode,
  };

  const VendorsSeparatePdfFilePrintWo = ['121619', '109950', '108210'];

  let fileNameParam = prodLine;

  if (VendorsSeparatePdfFilePrintWo.includes(vendorId)) {
    const currentDateFromatted = dayjs().format('YYYY-MM-DD_HH-mm');
    fileNameParam += `_${currentDateFromatted}`;
  }

  const filenameGenerator = contentType => generateFilename(contentType, vendorId, fileNameParam);

  dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
      fetch: handleFileDownload(filenameGenerator),
      types: [
        {
          type: rsaaActionTypes[0].type,
          meta,
        },
        {
          type: rsaaActionTypes[1].type,
          meta,
        },
        {
          type: rsaaActionTypes[2].type,
          meta,
        },
      ],
    },
  });
};
