import mime from 'mime-types';
import dateFormat from 'date-format';

export const handleFileDownload = (filenameGenerator, onFiledownloadCompleted = () => {}) => async (...args) => {
  const response = await fetch(...args)
    .then(response => {
      onFiledownloadCompleted();
      return response;
    })
    .catch(() => {
      onFiledownloadCompleted();
    });

  if (response.ok && response.status === 200) {
    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const contentType = response.headers.get('content-type');

    const filename = filenameGenerator(contentType);

    window.setTimeout(() => {
      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    }, 500);
  }

  return response;
};

export const generateFilename = (contentType, ...params) => {
  const extension = mime.extension(contentType);
  const filename = params.length > 0 ? params.join('_') : 'attachment';
  return `${filename}.${extension}`;
};

export const shortDateAsString = () => dateFormat.asString('dd.MM.yyyy', new Date());
