import { PublicClientApplication } from '@azure/msal-browser';
import { getMsalConfig } from './authConfig';

let msalInstance;

export async function initializeMsal() {
  const config = await getMsalConfig();
  msalInstance = new PublicClientApplication(config);

  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  return msalInstance;
}

export function getMsalInstance() {
  if (!msalInstance) {
    throw new Error("MSAL instance not initialized. Ensure you call 'initializeMsal' first.");
  }
  return msalInstance;
}
