import { createReducer } from '@reduxjs/toolkit';
import {
  askPasswordToDownloadReport,
  downloadReportFailure,
  downloadReportStart,
  downloadReportSuccess,
  fetchReportsFailure,
  fetchReportsStart,
  fetchReportsSuccess,
  hidePasswordDialog,
} from './ReportsActions';
import { showActionFailure } from '../../shared/utils/Notifications';
import Get from 'lodash/get';
import SortBy from 'lodash/sortBy';
import * as uuid from 'uuid';

const initialState = {
  loading: false,
  reports: [],
  loadingReports: [],
  passwordProtectedReport: undefined,
};

function setReportLoadingState(state, action, isLoading) {
  const reportId = Get(action, 'meta.reportId', '');

  if (isLoading) {
    state.loadingReports.push(reportId);
  } else {
    state.loadingReports = [...state.loadingReports].filter(id => id !== reportId);
  }
  state.loading = state.loadingReports.length > 0;
}

export const ReportsReducer = createReducer(initialState, {
  [fetchReportsStart](state) {
    state.loading = true;
  },
  [fetchReportsSuccess](state, action) {
    state.loading = false;
    state.reports = SortBy(
      Get(action, 'payload.result', []).map(({ webPasswordNeeded, ...report }) => ({
        ...report,
        id: uuid.v4(),
        requirePassword: webPasswordNeeded,
      })),
      ['webQuery', 'webQueryName'],
    );
  },
  [fetchReportsFailure](state, action) {
    state.loading = false;
    showActionFailure(action, 'Failed to fetch reports');
  },
  [downloadReportStart](state, action) {
    setReportLoadingState(state, action, true);
  },
  [downloadReportSuccess](state, action) {
    setReportLoadingState(state, action, false);
  },
  [downloadReportFailure](state, action) {
    setReportLoadingState(state, action, false);
    showActionFailure(action, 'Failed to download report');
  },
  [askPasswordToDownloadReport](state, action) {
    state.passwordProtectedReport = Get(action, 'payload');
  },
  [hidePasswordDialog](state) {
    state.passwordProtectedReport = undefined;
  },
});
