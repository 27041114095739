import React from 'react';
import PropTypes from 'prop-types';

/**
 * https://github.com/adazzle/react-data-grid/blob/next/packages/react-data-grid/src/common/editors/SimpleTextEditor.tsx
 */
export class CustomSimpleTextEditor extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.input = React.createRef();
    this.state = { value: props.value };
    this.handleChange = this.handleChange.bind(this);
  }

  getValue() {
    let value = this.state.value;
    if (this.props.formatter) {
      value = this.props.formatter(value);
    }
    return {
      [this.props.column.key]: value,
    };
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  getInputNode() {
    return this.input.current;
  }

  render() {
    return (
      <input
        ref={this.input}
        type="text"
        onBlur={this.props.onBlur}
        className="form-control"
        placeholder={this.props.placeholder}
        value={this.state.value}
        onChange={this.handleChange}
      />
    );
  }
}

CustomSimpleTextEditor.propTypes = {
  placeholder: PropTypes.string,
  formatter: PropTypes.func,
  value: PropTypes.any,
  column: PropTypes.any,
  onBlur: PropTypes.func,
};
