import React from 'react';
import Avatar, { AvatarItem } from '@atlaskit/avatar';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { ReactComponent as AbbLogo } from 'assets/abb-logo.svg';
import SignOutIcon from '@atlaskit/icon/glyph/sign-out';
import LoadingIndicator from '../../../../shared/LoadingIndicator';
import { clearAbbLocalStorage } from '../../../../shared/utils/storage';
import { getMsalInstance } from 'app/shared/Auth/msal';
import { BroadcastChannel } from 'broadcast-channel';
const channel = new BroadcastChannel('abb-msp-broadcast-channel');
const LogoStyle = styled(AbbLogo)`
  margin-left: 24px;
  height: 33px;
`;

const UserAvatar = styled.div`
  margin-left: auto;
  margin-right: 24px;
  display: flex;
  align-items: center;
  min-width: 300px;
`;

const TopBarWrapper = styled.div`
  display: flex;
  background: #ffffff;
  height: 72px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  align-items: center;
`;

const SignOutIconWrapper = styled.div`
  cursor: pointer;
`;

function Logo() {
  return (
    <LogoStyle>
      <AbbLogo />
    </LogoStyle>
  );
}

function UserProfileIcon() {
  const msalInstance = getMsalInstance();
  const account = msalInstance.getActiveAccount();

  const logoutRequest = {
    account: account,
  };

  const handleLogout = () => {
    clearAbbLocalStorage();
    msalInstance.logoutRedirect(logoutRequest);
  };

  const LogoutAndBroadcastMessage = () => {
    channel.postMessage('msp-logout');
    handleLogout();
  };

  channel.onmessage = msg => (msg === 'msp-logout' ? handleLogout() : null);

  if (!account) {
    return <LoadingIndicator />;
  }
  const idToken = account.idTokenClaims;

  return (
    <UserAvatar>
      <AvatarItem
        avatar={<Avatar src={account.picture} size="medium" />}
        key={idToken.email}
        primaryText={`${idToken.given_name} ${idToken.family_name}`}
        secondaryText={idToken.email}
      />
      <SignOutIconWrapper onClick={LogoutAndBroadcastMessage}>
        <SignOutIcon />
      </SignOutIconWrapper>
    </UserAvatar>
  );
}

function GlobalTopBar() {
  return (
    <TopBarWrapper>
      <NavLink to="/home">
        <Logo />
      </NavLink>
      <UserProfileIcon />
    </TopBarWrapper>
  );
}

GlobalTopBar.propTypes = {};

export default GlobalTopBar;
