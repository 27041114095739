import { createAction } from '@reduxjs/toolkit';
import { buildApiUrl } from '../../../shared/utils/ApiUtils';
import { RSAA } from 'redux-api-middleware';
import { getBomRows } from './billOfMaterialsReducer';

export const getBillOfMaterialsListStart = createAction('bom/getBillOfMaterialsListStart');
export const getBillOfMaterialsListSuccess = createAction('bom/getBillOfMaterialsListSuccess');
export const getBillOfMaterialsListFailure = createAction('bom/getBillOfMaterialsListFailure');

export const setFilteredItems = createAction('bom/setFilteredItems');

export const fetchBillOfMaterialsListFromBackend = () => (dispatch, getState) => {
  const state = getState();

  const rows = getBomRows(state);

  if (rows.length > 0) {
    return dispatch(setFilteredItems(rows));
  }

  const url = buildApiUrl('bom');

  return dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      types: [getBillOfMaterialsListStart.type, getBillOfMaterialsListSuccess.type, getBillOfMaterialsListFailure.type],
    },
  });
};
