import { buildApiUrl } from '../../shared/utils/ApiUtils';
import { RSAA } from 'redux-api-middleware';
import { createAction } from '@reduxjs/toolkit';
import Get from 'lodash/get';
import { generateFilename, handleFileDownload } from '../../shared/utils/FileDownloadUtils';

export const fetchDocumentKindsStart = createAction('documentSearch/fetchDocumentKindsStart');
export const fetchDocumentKindsSuccess = createAction('documentSearch/fetchDocumentKindsSuccess');
export const fetchDocumentKindsFailure = createAction('documentSearch/fetchDocumentKindsFailure');

export const searchStart = createAction('documentSearch/searchStart');
export const searchSuccess = createAction('documentSearch/searchSuccess');
export const searchFailure = createAction('documentSearch/searchFailure');

export const downloadFileStart = createAction('documentSearch/downloadFileStart');
export const downloadFileSuccess = createAction('documentSearch/downloadFileSuccess');
export const downloadFileFailure = createAction('documentSearch/downloadFileFailure');

export const fetchDocumentKinds = () => dispatch => {
  const url = buildApiUrl('documentSearch/documentKind');

  return dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      types: [fetchDocumentKindsStart.type, fetchDocumentKindsSuccess.type, fetchDocumentKindsFailure.type],
    },
  });
};

export const search = (searchParameters, isAdvancedSearch) => dispatch => {
  if (!isAdvancedSearch) {
    delete searchParameters['description'];
    delete searchParameters['typeCode'];
    delete searchParameters['filename'];
  }

  searchParameters.documentKind = Get(searchParameters, 'documentKind.value', '');
  searchParameters.fileExtension = Get(searchParameters, 'fileExtension.value', '');

  const url = buildApiUrl('documentSearch', searchParameters);

  return dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      types: [searchStart.type, searchSuccess.type, searchFailure.type],
    },
  });
};

export const downloadFile = (
  documentNumber,
  documentFile,
  documentPart,
  documentVersion,
  documentType,
  onFiledownloadCompleted = () => {},
) => dispatch => {
  const url = buildApiUrl('download/downloadFile', {
    documentNumber,
    documentFile,
    documentPart,
    documentVersion,
    documentType,
  });

  const filename = documentFile
    .split('/')
    .filter(part => part)
    .pop();

  const filenameGenerator = contentType => generateFilename(contentType, filename);

  return dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      fetch: handleFileDownload(filenameGenerator, onFiledownloadCompleted),
      types: [downloadFileStart.type, downloadFileSuccess.type, downloadFileFailure.type],
    },
  });
};
