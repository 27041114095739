import PageLoading from '../../shared/PageLoading';
import AbbTitle from '../../shared/AbbSectionTitle';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getReports, isLoading } from './ReportsSelectors';
import { fetchReports } from './ReportsActions';
import { ReportsTable } from './ReportsTable';
import { DownloadUserGuide } from '../../shared/DownloadUserGuide';

export const ReportsPage = () => {
  const loading = useSelector(state => isLoading(state));
  const reports = useSelector(state => getReports(state));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchReports());
  }, [dispatch]);

  return (
    <>
      <>
        <PageLoading visible={loading} />
      </>
      <AbbTitle title="Reports">
        <DownloadUserGuide filename="/user-manuals/user-guide_reports.docx" />
      </AbbTitle>
      <ReportsTable reports={reports} />
    </>
  );
};
