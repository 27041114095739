import PropTypes from 'prop-types';
import Button from '@atlaskit/button';
import DownloadIcon from '@atlaskit/icon/glyph/download';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DownloadFilesModalDialog } from '../../views/DocumentSearch/DownloadFilesModalDialog';
import { getFilesList, isFileLoading } from '../../views/BillOfMaterials/BomDetails/billOfMaterialsDetailsReducer';
import { fetchBomFileList } from '../../views/BillOfMaterials/BomDetails/billOfMaterialsDetailsActions';

const defaultRenderButton = onClick => {
  return <Button appearance={'subtle'} onClick={onClick} iconBefore={<DownloadIcon label={'download'} />} />;
};

export const DownloadMaterialDocuments = ({ materialNumber, renderButton = defaultRenderButton }) => {
  const dispatch = useDispatch();
  const filesLoading = useSelector(state => isFileLoading(state));
  const downloadFilesList = useSelector(state => getFilesList(state));

  const [dialogOpen, setDialogOpen] = useState(false);

  const onFetchFiles = useCallback(() => {
    setDialogOpen(true);
    dispatch(fetchBomFileList(materialNumber));
  }, [dispatch, materialNumber]);

  const onCloseDownloadDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  return (
    <>
      <DownloadFilesModalDialog
        isOpen={dialogOpen}
        isLoading={filesLoading}
        files={downloadFilesList}
        onClose={onCloseDownloadDialog}
      />
      {renderButton(onFetchFiles)}
    </>
  );
};

DownloadMaterialDocuments.propTypes = {
  materialNumber: PropTypes.string.isRequired,
  renderButton: PropTypes.func,
};
