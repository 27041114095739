import { buildApiUrl } from '../utils/ApiUtils';
import { RSAA } from 'redux-api-middleware';
import { createAction } from '@reduxjs/toolkit';

export const fetchShipNtDataStart = createAction('shipNt/fetchShipNtDataStart');
export const fetchShipNtDataSuccess = createAction('shipNt/fetchShipNtDataSuccess');
export const fetchShipNtDataFailure = createAction('shipNt/fetchShipNtDataFailure');

export const fetchShipNtData = materialNumber => dispatch => {
  const url = buildApiUrl('materialSearch/shipNt', { materialNumber });

  return dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      types: [fetchShipNtDataStart.type, fetchShipNtDataSuccess.type, fetchShipNtDataFailure.type],
    },
  });
};
