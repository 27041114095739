import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import PageLoading from '../PageLoading';
import { useDispatch, useSelector } from 'react-redux';
import { fetchShipNtData } from './ShipNtDialogActions';
import { getShipNtData, isLoading } from './ShipNtDialogSelectors';
import IsEmpty from 'lodash/isEmpty';
import { ShipNtHeader } from './ShipNtHeader';
import { ShipNtRows } from './ShipNtRows';

const ShipNtData = ({ data }) => {
  if (IsEmpty(data)) {
    return null;
  }

  return (
    <>
      <ShipNtHeader header={data.header} stocks={data.stocks} />
      <ShipNtRows rows={data.rows} />
    </>
  );
};

export const ShipNtDialog = ({ materialNumber, isOpen, onClose }) => {
  const actions = [{ text: 'Close', onClick: onClose }];
  const dispatch = useDispatch();

  const loading = useSelector(state => isLoading(state));
  const shipNtData = useSelector(state => getShipNtData(state));

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchShipNtData(materialNumber));
    }
  }, [materialNumber, isOpen, dispatch]);

  return (
    <ModalTransition>
      {isOpen && (
        <Modal actions={actions} onClose={onClose} heading="Ship NT" width="large" autoFocus={false}>
          <PageLoading visible={loading} />
          <ShipNtData data={shipNtData} />
        </Modal>
      )}
    </ModalTransition>
  );
};

ShipNtDialog.propTypes = {
  materialNumber: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

ShipNtData.propTypes = {
  data: PropTypes.object.isRequired,
};
