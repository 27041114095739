import RenderDropdownLevels from './RenderDropdownLevels';
import { LoadingButton } from '@atlaskit/button';
import DropdownMenu, { DropdownItemGroupRadio, DropdownItemRadio } from '@atlaskit/dropdown-menu';
import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getLoadingButtons } from '../billOfMaterialsDetailsReducer';
import PropTypes from 'prop-types';
import { MaxNumberOfBomLevels } from '../../../../shared/utils/contstans';
import { BomActionDropdown } from './BomActionDropdown';

export const DownloadSelectedButtonCode = 'selectedDocs';
export const DownloadAllButtonCode = 'allDocs';
export const PrintBomButtonCode = 'printBom';

const ActionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

const FlexColumn = styled.div`
  margin-right: 1rem;
  margin-bottom: 0.5rem;
`;

const BillOfMaterialsDetailsActionButtons = ({
  onDownloadAction,
  checkedRows,
  onLevelSelected,
  bomData,
  toggleAllRows,
  areAllExpanded,
  hidePhantomItems,
  setHidePhantomItems,
}) => {
  const loadingButtons = useSelector(state => getLoadingButtons(state));

  return (
    <ActionsWrapper>
      <FlexColumn>
        <RenderDropdownLevels
          numberOfLevels={MaxNumberOfBomLevels}
          triggerType={'button'}
          dropDownTrigger={'Download all documents'}
          clickEventType={DownloadAllButtonCode}
          onOptionClick={onDownloadAction}
        />
      </FlexColumn>
      <FlexColumn>
        <LoadingButton
          isLoading={loadingButtons.includes(DownloadSelectedButtonCode.toLowerCase())}
          isDisabled={!checkedRows.length}
          onClick={() => onDownloadAction({ type: DownloadSelectedButtonCode })}
        >
          Download selected documents
        </LoadingButton>
      </FlexColumn>
      <FlexColumn>
        <RenderDropdownLevels
          numberOfLevels={MaxNumberOfBomLevels}
          triggerType={'button'}
          dropDownTrigger={'Print BOM'}
          clickEventType={PrintBomButtonCode}
          onOptionClick={onDownloadAction}
        />
      </FlexColumn>
      <FlexColumn>
        <DropdownMenu trigger="Show Levels" triggerType="button" isMenuFixed>
          <DropdownItemGroupRadio id="levels">
            {Array(7)
              .fill()
              .map((x, i) => {
                const level = i + 1;
                return (
                  <DropdownItemRadio
                    onClick={() => onLevelSelected(level)}
                    key={`level-${level}`}
                    id={`level-${level}`}
                  >{`Level ${level}`}</DropdownItemRadio>
                );
              })}
          </DropdownItemGroupRadio>
        </DropdownMenu>
      </FlexColumn>
      <FlexColumn>
        <BomActionDropdown bomData={bomData} />
      </FlexColumn>
      <FlexColumn>
        <LoadingButton isDisabled={!bomData.length} onClick={toggleAllRows}>
          {areAllExpanded ? 'Collapse All' : 'Expand All'}
        </LoadingButton>
      </FlexColumn>
      <FlexColumn>
        <LoadingButton isDisabled={!bomData.length} onClick={() => setHidePhantomItems(!hidePhantomItems)}>
          {hidePhantomItems ? 'Show phantom items' : 'Hide phantom items'}
        </LoadingButton>
      </FlexColumn>
    </ActionsWrapper>
  );
};

BillOfMaterialsDetailsActionButtons.propTypes = {
  onDownloadAction: PropTypes.func.isRequired,
  checkedRows: PropTypes.array.isRequired,
  onLevelSelected: PropTypes.func.isRequired,
  bomData: PropTypes.array.isRequired,
  areAllExpanded: PropTypes.bool.isRequired,
  toggleAllRows: PropTypes.func.isRequired,
  hidePhantomItems: PropTypes.bool.isRequired,
  setHidePhantomItems: PropTypes.func.isRequired,
};

export default BillOfMaterialsDetailsActionButtons;
