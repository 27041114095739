import Get from 'lodash/get';
import FindIndex from 'lodash/findIndex';
import IsEmpty from 'lodash/isEmpty';

export const isLoading = state => Get(state, 'tracing.loading', false);
export const getHeader = state => Get(state, 'tracing.header', {});
export const getHeaderAsRows = state => {
  const header = getHeader(state);
  return IsEmpty(header) ? [] : [header];
};

export const getTraceableComponents = state => {
  const components = Get(state, 'tracing.components', []);
  const updatedComponents = Get(state, 'tracing.updatedComponents', []);

  return components.map(component => {
    const index = FindIndex(
      updatedComponents,
      updatedComponent =>
        updatedComponent.material === component.material && updatedComponent.unitCount === component.unitCount,
    );
    if (index > -1) {
      return updatedComponents[index];
    }
    return component;
  });
};

export const getUpdatedTraceableComponents = state => Get(state, 'tracing.updatedComponents', []);
export const haveComponentsBeenEdited = state => getUpdatedTraceableComponents(state).length > 0;

const saveTracingDataButton = {
  id: 'SAVE_TRACE',
  description: 'Save tracing data',
  code: 'SAVE_TRACE',
};
export const getButtons = state => {
  const buttonsFromSap = Get(state, 'tracing.buttons');

  return [...buttonsFromSap, saveTracingDataButton];
};

export const getLoadingButtons = state => Get(state, 'tracing.buttonLoading', []);
export const getShowReprintDialog = state => Get(state, 'tracing.showRePrintRatingPlateDialog', false);
