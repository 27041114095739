import { createReducer } from '@reduxjs/toolkit';
import { fetchShipNtDataFailure, fetchShipNtDataStart, fetchShipNtDataSuccess } from './ShipNtDialogActions';
import Get from 'lodash/get';
import { showActionFailure } from '../utils/Notifications';

const initialState = {
  loading: false,
  shipNtData: {},
};
export const shipNtDialogReducer = createReducer(initialState, {
  [fetchShipNtDataStart](state) {
    state.loading = true;
    state.shipNtData = {};
  },
  [fetchShipNtDataSuccess](state, action) {
    state.loading = false;
    state.shipNtData = Get(action, 'payload.result');
  },
  [fetchShipNtDataFailure](state, action) {
    state.loading = false;
    showActionFailure(action, 'Failed to fetch ShipNT data / Data not found for plant');
  },
});
