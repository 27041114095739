import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@atlaskit/checkbox';

import styled from 'styled-components';
import DownloadIcon from '@atlaskit/icon/glyph/download';
import Button from '@atlaskit/button';
import { DownloadMaterialDocuments } from '../../../../shared/DownloadMaterialImages';

const HeaderWrapper = styled.div`
  display: flex;
  min-height: 40px;
  border: 1px solid #1c6ea4;
  margin-bottom: 1rem;
  align-items: center;
  flex-wrap: wrap;
`;

const HeaderSpan = styled.span`
  margin-right: 1.5rem;
`;

const DownloadButton = onClick => (
  <Button appearance={'subtle'} onClick={onClick} iconBefore={<DownloadIcon label={'download'} />} />
);

const BillOfMaterialsDetailsHeaderGrid = ({ bomHeader }) => {
  if (!bomHeader) return null;
  return (
    <HeaderWrapper>
      <Checkbox isDisabled={!bomHeader.hasDocs} name="checkbox-header-docs" />
      <HeaderSpan>{bomHeader.materialCode}</HeaderSpan>
      <HeaderSpan>{bomHeader.materialDescription}</HeaderSpan>
      <HeaderSpan>{bomHeader.typeDesign}</HeaderSpan>
      <HeaderSpan>{bomHeader.materialTechDesc}</HeaderSpan>
      <HeaderSpan>{bomHeader.rev}</HeaderSpan>
      <HeaderSpan>{bomHeader.revDate}</HeaderSpan>
      {bomHeader.hasDocs && (
        <HeaderSpan>
          <DownloadMaterialDocuments materialNumber={bomHeader.materialCode} renderButton={DownloadButton} />
        </HeaderSpan>
      )}
    </HeaderWrapper>
  );
};

export default BillOfMaterialsDetailsHeaderGrid;

BillOfMaterialsDetailsHeaderGrid.propTypes = {
  bomHeader: PropTypes.any,
};
