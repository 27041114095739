import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from '@atlaskit/progress-bar';
import styled from 'styled-components';

const StyledProgressBar = styled.div`
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

const PageLoading = ({ visible }) => {
  return (
    <StyledProgressBar visible={visible}>
      <ProgressBar isIndeterminate />
    </StyledProgressBar>
  );
};

PageLoading.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default PageLoading;
