import { RSAA } from 'redux-api-middleware';
import { buildApiUrl } from '../../shared/utils/ApiUtils';
import { createAction } from '@reduxjs/toolkit';
import { generateFilename, handleFileDownload } from '../../shared/utils/FileDownloadUtils';

export const fetchReportsStart = createAction('reports/fetchReportsStart');
export const fetchReportsSuccess = createAction('reports/fetchReportsSuccess');
export const fetchReportsFailure = createAction('reports/fetchReportsFailure');

export const downloadReportStart = createAction('reports/downloadReportStart');
export const downloadReportSuccess = createAction('reports/downloadReportSuccess');
export const downloadReportFailure = createAction('reports/downloadReportFailure');

export const askPasswordToDownloadReport = createAction('reports/askPasswordToDownloadReport');
export const hidePasswordDialog = createAction('reports/hidePasswordDialog');

export const fetchReports = () => dispatch => {
  const url = buildApiUrl('reports');

  return dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      types: [fetchReportsStart.type, fetchReportsSuccess.type, fetchReportsFailure.type],
    },
  });
};

export const downloadReport = (report, password = '') => dispatch => {
  const { id, webUserGroup, webQuery, webQueryName, webVariant, requirePassword } = report;

  if (requirePassword && !password) {
    dispatch(askPasswordToDownloadReport(report));
    return;
  }

  const data = {
    userGroup: webUserGroup,
    query: webQuery,
    queryName: webQueryName,
    variant: webVariant,
    password,
  };

  const url = buildApiUrl('download/downloadReport', data);

  const filenameGenerator = contentType => generateFilename(contentType, webQueryName, webVariant);

  const meta = {
    reportId: id,
  };

  return dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      fetch: handleFileDownload(filenameGenerator),
      types: [
        { type: downloadReportStart.type, meta },
        { type: downloadReportSuccess.type, meta },
        { type: downloadReportFailure.type, meta },
      ],
    },
  });
};
