import { createAction } from '@reduxjs/toolkit';
import { buildApiUrl } from '../utils/ApiUtils';
import { RSAA } from 'redux-api-middleware';
import { getUserPermissions, isLoadingUserInformation } from './authSelectors';

export const switchVendorContext = createAction('auth/switchVendorContext');
export const setAdminPlantId = createAction('auth/setAdminPlantId');
export const setAdminVendorId = createAction('auth/setAdminVendorId');

export const getUsersRolesStart = createAction('auth/getUsersRolesStart');
export const getUsersRolesSuccess = createAction('auth/getUsersRolesSuccess');
export const getUsersRolesFailure = createAction('auth/getUsersRolesFailure');

export const getUserInformation = () => (dispatch, getState) => {
  const url = buildApiUrl('user');
  const state = getState();

  const isAlreadyLoading = isLoadingUserInformation(state);
  const roles = getUserPermissions(state);

  if (!isAlreadyLoading && roles.length === 0) {
    return dispatch({
      [RSAA]: {
        endpoint: url,
        method: 'GET',
        types: [getUsersRolesStart.type, getUsersRolesSuccess.type, getUsersRolesFailure.type],
      },
    });
  }
};
