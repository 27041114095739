import { v4 as uuid } from 'uuid';

export const isMultiselectVariant = variant => variant.startsWith('X');

export const removeOldStatusUpdateButton = button => !(button.code === 'STATUSUPDATE');

export const generateUniqueIdForRows = item => ({
  ...item,
  id: uuid(),
});

export const updateExistingRowById = (items, ids, updateFn) => {
  let expectedIds = ids;
  if (!Array.isArray(ids)) {
    expectedIds = [ids];
  }
  items.filter(item => expectedIds.includes(item.id)).forEach(item => updateFn(item));
};
