import React, { useRef } from 'react';

import PropTypes from 'prop-types';
import ReactDataGrid from 'react-data-grid';
import { addToEachColumn, getGridHeight, GridColumnFormatter } from '../../../../shared/utils/gridUtils';
import styled from 'styled-components';

const OrderDataGridColumns = [
  {
    key: 'productionOrder',
    name: 'Order',
  },
  {
    key: 'materialCode',
    name: 'Material',
  },
  {
    key: 'materialDescription',
    name: 'Description',
  },
  {
    key: 'materialTechDesc',
    name: 'Technical description',
  },
  {
    key: 'orderQty',
    name: 'Total Qty',
  },
  {
    key: 'stock',
    name: 'Saldo',
  },
  {
    key: 'uom',
    name: 'UoM',
  },
  {
    key: 'salesOrder',
    name: 'Sales order',
  },
  {
    key: 'salesOrderItem',
    name: 'Item',
  },
];

const staticColumnWidths = {
  orderQty: 80,
  stock: 80,
  uom: 50,
};
const GridWrapper = styled.div`
  font-size: small;
  margin-bottom: 1rem;
`;

const BillOfMaterialsOrderDetailsGrid = ({ orderData }) => {
  const dataGridRef = useRef(undefined);
  const columnWidthFormatter = new GridColumnFormatter(staticColumnWidths);

  var gridColumns = addToEachColumn(OrderDataGridColumns, { resizable: true });

  return (
    <>
      <GridWrapper>
        <ReactDataGrid
          ref={dataGridRef}
          columns={columnWidthFormatter.formatColumnsWidth(orderData, gridColumns, dataGridRef)}
          rowGetter={i => orderData[i]}
          enableCellAutoFocus={false}
          rowsCount={orderData.length}
          minColumnWidth={20}
          minHeight={getGridHeight(orderData.length)}
          enableCellSelect={true}
          enableRowSelect={null}
        />
      </GridWrapper>
    </>
  );
};

BillOfMaterialsOrderDetailsGrid.propTypes = {
  orderData: PropTypes.any,
};

export default BillOfMaterialsOrderDetailsGrid;
