import { isRSAA, RSAA } from 'redux-api-middleware';
import { getMsalInstance } from 'app/shared/Auth/msal';
import get from 'lodash/get';

async function fetchConfiguration() {
  return await fetch('/app-config.json').then(response => response.json());
}

const apiAuthHeaderInjector = () => next => async action => {
  if (isRSAA(action)) {
    const rsaaAction = action[RSAA];
    const msalInstance = getMsalInstance();
    const account = msalInstance.getActiveAccount();
    const configuration = await fetchConfiguration();
    const clientId = get(configuration, 'auth.clientId');
    const accessTokenRequest = {
      scopes: [clientId + '/.default'],
      account: account,
    };

    let token = await msalInstance.acquireTokenSilent(accessTokenRequest);
    token = token.accessToken;

    rsaaAction.headers = {
      ...rsaaAction.headers,
      pragma: 'no-cache',
      'cache-control': 'no-store',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}` || '',
    };

    /* If the user has 5+ groups, the 'groups' claim transforms into 'hasgroups' and the groups have to be
    separately fetched from graphs.
    Because of high strangeness in the App Registration in the AD, let's always fetch the graph token.*/
    const request = {
      scopes: ['User.Read'],
    };
    await msalInstance
      .acquireTokenSilent(request)
      .then(response => {
        rsaaAction.headers = {
          ...rsaaAction.headers,
          'X-MSP-GraphToken': response.accessToken,
        };
      })
      .catch(e => {
        console.log('Error: ', e);
      });
  }
  return next(action);
};

export default apiAuthHeaderInjector;
