import { createReducer } from '@reduxjs/toolkit';
import set from 'lodash/set';

import * as homePageActions from './homePageActions';
import * as reducerUtils from '../../shared/utils/reducerUtils';

const initialState = {
  pending: false,
  error: false,
  results: [],
};

const homePageReducer = createReducer(initialState, {
  [homePageActions.testApiCallBegin](state) {
    set(state, 'results', []);
    set(state, 'pending', true);
  },
  [homePageActions.testApiCallSuccess](state, action) {
    set(state, 'pending', false);
    set(state, 'results', action.payload);
  },
  [homePageActions.testApiCallFailure]: reducerUtils.setPending(false),
});

export default homePageReducer;
