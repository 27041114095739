import { createReducer } from '@reduxjs/toolkit';
import Get from 'lodash/get';
import Set from 'lodash/set';
import { generateUniqueIdForRows, removeOldStatusUpdateButton, updateExistingRowById } from './WorkQueueUtils';
import {
  ReloadButton,
  showActionFailure,
  showFailureNotification,
  showSuccessNotification,
} from '../../../shared/utils/Notifications';
import {
  changeRowData,
  closeCreateSerialNumberDialog,
  confirmOrderFailure,
  confirmOrderStart,
  confirmOrderSuccess,
  createSerialNumberFailure,
  createSerialNumberStart,
  createSerialNumberSuccess,
  getWorkQueueFailure,
  getWorkQueueStart,
  getWorkQueueSuccess,
  printPackingLabelFailure,
  printPackingLabelStart,
  printPackingLabelSuccess,
  printWorkQueueDocumentsStart,
  printWorkQueueDocumentsSuccess,
  promptCreateSerialNumber,
  reset,
  sapFileDownloadFailure,
  sapFileDownloadStart,
  sapFileDownloadSuccess,
  sendStatusUpdateFailure,
  sendStatusUpdateStart,
  sendStatusUpdateSuccess,
  setFilteredRows,
  triggerSapPrintoutFailure,
  triggerSapPrintoutStart,
  triggerSapPrintoutSuccess,
  updateSelectedRows,
} from './WorkQueueActions';
import { setButtonLoading } from '../../../shared/utils/loadingButtons';
import { showRePrintRowsDialog } from '../RePrintDialog/RePrintRatingPlateDialog';
import { showDhlOrderDialog } from '../DhlOrderDialog/DhlOrderDialog';

const initialState = {
  selectedVariant: undefined,
  buttons: [],
  headers: [],
  items: [],
  productionLine: '',
  requestId: undefined,
  loading: false,
  selected: [],
  serialNumberDialog: {
    show: false,
    loading: false,
  },
  buttonLoading: [],
  filesDownloading: 0,
  showRePrintRatingPlateDialog: false,
  showDhlOrderDialog: false,
  filteredItems: [],
};

const removeRowsById = (state, ids) => {
  state.items = state.items.filter(item => !ids.includes(item.id));
};

export const WorkQueueReducer = createReducer(initialState, {
  [getWorkQueueStart](state, action) {
    state.loading = true;
    state.buttons = [];
    state.headers = [];
    state.items = [];
    state.filteredItems = [];
    state.selected = [];
    state.productionLine = '';
    state.requestId = Get(action, 'meta.reqId', '');
    state.selectedVariant = Get(action, 'meta.selectedVariant', '');
  },
  [getWorkQueueSuccess](state, action) {
    const requestId = Get(action, 'meta.reqId', '');

    if (state.requestId !== requestId) {
      return;
    }

    state.loading = false;
    state.headers = Get(action, 'payload.result.workQueueColumns', []);

    const sortCharacterNotEmpty = (a, b) => {
      if (!a) return 1;
      if (!b) return -1;
      if (a === b) return 0;
      return a > b ? 1 : -1;
    };
    const items = Get(action, 'payload.result.workQueueList', [])
      .map(generateUniqueIdForRows)
      .sort((a, b) => {
        return sortCharacterNotEmpty(a.priority, b.priority) || a.ebeln - b.ebeln || a.ebelp - b.ebelp;
      });
    state.items = items;
    state.filteredItems = items;
    state.buttons = Get(action, 'payload.result.workQueueButtons', []).filter(removeOldStatusUpdateButton);
    state.productionLine = Get(action, 'payload.result.workQueueHeader.productionLine', '');
  },
  [getWorkQueueFailure](state, action) {
    state.loading = false;
    showActionFailure(action, 'Failed to load work queue', ReloadButton);
  },
  [updateSelectedRows](state, action) {
    state.selected = action.payload;
  },
  [changeRowData](state, action) {
    const rowIds = Get(action, 'payload.rowIds', []);
    const newData = Get(action, 'payload.newData', {});

    updateExistingRowById(state.items, rowIds, item => {
      Object.keys(newData).forEach(key => {
        Set(item, key, newData[key]);
      });
    });
  },
  [setFilteredRows](state, action) {
    state.filteredItems = Get(action, 'payload', []);
  },
  [promptCreateSerialNumber](state) {
    state.serialNumberDialog.show = true;
  },
  [closeCreateSerialNumberDialog](state) {
    state.serialNumberDialog.show = false;
  },
  [createSerialNumberStart](state) {
    state.serialNumberDialog.loading = true;
  },
  [createSerialNumberFailure](state, action) {
    state.serialNumberDialog.loading = false;
    state.serialNumberDialog.show = false;
    showActionFailure(action, 'Failed to create serial number', ReloadButton);
  },
  [createSerialNumberSuccess](state, action) {
    state.serialNumberDialog.loading = false;
    const createdSerialNumber = Get(action, 'payload.result', '');
    const rowId = Get(action, 'meta.rowId');

    updateExistingRowById(state.items, rowId, item => {
      Set(item, 'serialNumber', createdSerialNumber);
    });

    state.serialNumberDialog.show = false;
  },
  [reset](state) {
    state.serialNumberDialog.show = false;
  },
  [sendStatusUpdateStart](state) {
    setButtonLoading(state, 'status', true);
  },
  [sendStatusUpdateSuccess](state, action) {
    setButtonLoading(state, 'status', false);
    const rows = Get(action, 'payload.result', []);

    rows.forEach(row => showSuccessNotification('Status updated for ' + row.productionOrder));
  },
  [sendStatusUpdateFailure](state, action) {
    setButtonLoading(state, 'status', false);
    showActionFailure(action, 'Failed to update statuses');
  },
  [triggerSapPrintoutStart](state, action) {
    const buttonCode = Get(action, 'meta.buttonCode', '');

    setButtonLoading(state, buttonCode, true);
  },
  [triggerSapPrintoutSuccess](state, action) {
    const buttonCode = Get(action, 'meta.buttonCode', '');
    const buttonDescription = Get(action, 'meta.buttonDescription', '');

    setButtonLoading(state, buttonCode, false);
    const succeededAmount = Get(action, 'payload.result.succeeded', 1);
    const failedAmount = Get(action, 'payload.result.failed', 0);

    if (succeededAmount > 0) {
      const succeededRows = Get(action, 'payload.result.succeededRows', []);
      succeededRows.forEach(row => {
        showSuccessNotification('Start work operation succeeded for ' + row.productionOrder);
      });
      removeRowsById(
        state,
        succeededRows.map(row => row.id),
      );
    }

    if (failedAmount > 0) {
      showFailureNotification(`"${buttonDescription}"-operation failed for ${failedAmount} items`);
    }
  },
  [triggerSapPrintoutFailure](state, action) {
    const buttonCode = Get(action, 'meta.buttonCode', '');
    const buttonDescription = Get(action, 'meta.buttonDescription', '');
    setButtonLoading(state, buttonCode, false);

    showActionFailure(action, `Failed to execute "${buttonDescription}"-operation`);
  },
  [confirmOrderStart](state) {
    setButtonLoading(state, 'CONFSUM', true);
  },
  [confirmOrderSuccess](state, action) {
    setButtonLoading(state, 'CONFSUM', false);
    const succeededAmount = Get(action, 'payload.result.succeeded', 1);
    const failedAmount = Get(action, 'payload.result.failed', 0);

    if (succeededAmount > 0) {
      const succeededRows = Get(action, 'payload.result.succeededRows', []);
      succeededRows.forEach(row => showSuccessNotification(Get(row, 'informationMessage', 'Order confirmed')));

      removeRowsById(
        state,
        succeededRows.map(row => row.id),
      );
    }

    if (failedAmount > 0) {
      showFailureNotification(`"Confirm order"-operation failed for ${failedAmount} items`);
    }
  },
  [confirmOrderFailure](state, action) {
    setButtonLoading(state, 'CONFSUM', false);
    showActionFailure(action, 'Failed to confirm order');
  },
  [sapFileDownloadStart](state, action) {
    const buttonCode = Get(action, 'meta.buttonCode');
    setButtonLoading(state, buttonCode, true);
  },
  [sapFileDownloadSuccess](state, action) {
    const buttonCode = Get(action, 'meta.buttonCode');
    setButtonLoading(state, buttonCode, false);
  },
  [sapFileDownloadFailure](state, action) {
    const buttonCode = Get(action, 'meta.buttonCode');
    setButtonLoading(state, buttonCode, false);
    showActionFailure(action, 'Printout action failed');
  },
  [showRePrintRowsDialog](state, action) {
    state.showRePrintRatingPlateDialog = Get(action, 'payload', false);
  },
  [showDhlOrderDialog](state, action) {
    state.showDhlBomOrderDialog = Get(action, 'payload', false);
  },
  [printPackingLabelStart](state) {
    state.loading = true;
  },
  [printPackingLabelSuccess](state) {
    state.loading = false;
  },
  [printPackingLabelFailure](state, action) {
    state.loading = false;
    showActionFailure(action, 'Package label download failed');
  },
  [printWorkQueueDocumentsStart](state, action) {
    const buttonCode = Get(action, 'meta.buttonCode');
    setButtonLoading(state, buttonCode, true);
  },
  [printWorkQueueDocumentsSuccess](state, action) {
    const buttonCode = Get(action, 'meta.buttonCode');
    setButtonLoading(state, buttonCode, false);
  },
});
