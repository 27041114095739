import { createAction } from '@reduxjs/toolkit';
import { buildApiUrl } from '../../../shared/utils/ApiUtils';
import { RSAA } from 'redux-api-middleware';
import { generateFilename, handleFileDownload } from '../../../shared/utils/FileDownloadUtils';
import { getBomTree } from './billOfMaterialsDetailsReducer';
import { DownloadAllButtonCode } from './componenets/BillOfMaterialsDetailsActionButtons';
import { MaxNumberOfBomLevels } from 'app/shared/utils/contstans';

export const getBillOfMaterialsDetailsStart = createAction('bomDetails/getBillOfMaterialsDetailsStart');
export const getBillOfMaterialsDetailsSuccess = createAction('bomDetails/getBillOfMaterialsDetailsSuccess');
export const getBillOfMaterialsDetailsFailure = createAction('bomDetails/getBillOfMaterialsDetailsFailure');

export const sapFileDownloadStart = createAction('bomDetails/sapFileDownloadStart');
export const sapFileDownloadSuccess = createAction('bomDetails/sapFileDownloadSuccess');
export const sapFileDownloadFailure = createAction('bomDetails/sapFileDownloadFailure');

export const getBillOfMaterialsFileListStart = createAction('bomDetails/getBillOfMaterialsFileListStart');
export const getBillOfMaterialsFileListSuccess = createAction('bomDetails/getBillOfMaterialsFileListSuccess');
export const getBillOfMaterialsFileListFailure = createAction('bomDetails/getBillOfMaterialsFileListFailure');

export const updateCheckedRows = createAction('bomDetails/updateCheckedRows');

export const fetchBillOfMaterialsDetails = query => dispatch => {
  const url = buildApiUrl(`bom/search`);

  return dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(query),
      types: [
        getBillOfMaterialsDetailsStart.type,
        getBillOfMaterialsDetailsSuccess.type,
        getBillOfMaterialsDetailsFailure.type,
      ],
    },
  });
};

export const fetchBomFileList = materialCode => dispatch => {
  const url = buildApiUrl(`bom/${materialCode}/documents`);
  return dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      types: [
        getBillOfMaterialsFileListStart.type,
        getBillOfMaterialsFileListSuccess.type,
        getBillOfMaterialsFileListFailure.type,
      ],
    },
  });
};

export const downloadSelectedBomFiles = (buttonCode, materialCode, ids) => dispatch => {
  return dispatch(triggerBomFileDownload(buttonCode, materialCode, null, ids));
};

export const downloadBomFiles = (buttonCode, materialCode, materialVariant, bomType, level) => (dispatch, getState) => {
  if (bomType === 'ProductionOrder') {
    const state = getState();
    const header = getBomTree(state).header;
    const { productionOrder, productionOrderItem } = header;
    dispatch(triggerProductionOrderFileDownload(buttonCode, materialCode, level, productionOrder, productionOrderItem));
  } else {
    dispatch(triggerBomFileDownload(buttonCode, materialCode, materialVariant, null, level));
  }
};

export const triggerProductionOrderFileDownload = (
  buttonCode,
  materialCode,
  level,
  productionOrder,
  productionOrderItem,
) => dispatch => {
  const data = {
    downloadType: buttonCode,
    level,
    productionOrder,
    productionOrderItem,
  };

  const url = buildApiUrl('download/salesOrderDocumentDownload');

  const meta = { buttonCode };

  const filenameGenerator = contentType => generateFilename(contentType, materialCode);

  dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
      fetch: handleFileDownload(filenameGenerator),
      types: [
        {
          type: sapFileDownloadStart.type,
          meta,
        },
        {
          type: sapFileDownloadSuccess.type,
          meta,
        },
        {
          type: sapFileDownloadFailure.type,
          meta,
        },
      ],
    },
  });
};

export const triggerBomFilesDownloadForSelectedRows = () => (dispatch, getState) => {
  const state = getState();
  const selectedRowId = state.workQueue.selected[0];
  const selectedMaterial = state.workQueue.items.find(x => x.id === selectedRowId);
  dispatch(triggerBomFileDownload(DownloadAllButtonCode, selectedMaterial.material, null, null, MaxNumberOfBomLevels));
};

export const triggerBomFileDownload = (buttonCode, materialCode, materialVariant, ids, level) => dispatch => {
  let data = {
    downloadType: buttonCode,
    materialCode,
    materialVariant,
  };
  if (ids && ids.length > 0) {
    data = { ...data, materials: ids };
  } else {
    data = { ...data, level };
  }

  const url = buildApiUrl('download/bomDocumentDownload');

  const meta = {
    buttonCode,
  };

  let materialIdentifier;
  if (materialVariant != null) {
    materialIdentifier = materialVariant;
  } else {
    materialIdentifier = materialCode;
  }

  const filenameGenerator = contentType => generateFilename(contentType, materialIdentifier);

  return dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
      fetch: handleFileDownload(filenameGenerator),
      types: [
        {
          type: sapFileDownloadStart.type,
          meta,
        },
        {
          type: sapFileDownloadSuccess.type,
          meta,
        },
        {
          type: sapFileDownloadFailure.type,
          meta,
        },
      ],
    },
  });
};
