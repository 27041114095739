import PropTypes from 'prop-types';
import Button from '@atlaskit/button';
import TableIcon from '@atlaskit/icon/glyph/table';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { downloadBomFiles as printBomFiles } from '../../views/BillOfMaterials/BomDetails/billOfMaterialsDetailsActions';

export const PrintMaterialDocuments = ({ materialNumber, materialNumberVariant, downloadType, bomType, level }) => {
  const dispatch = useDispatch();

  const onFetchFiles = useCallback(() => {
    dispatch(printBomFiles(downloadType, materialNumber, materialNumberVariant, bomType, level));
  }, [dispatch, materialNumber]);

  return <Button appearance={'subtle'} onClick={onFetchFiles} iconBefore={<TableIcon label={'print'} />} />;
};

PrintMaterialDocuments.propTypes = {
  materialNumber: PropTypes.string.isRequired,
  materialNumberVariant: PropTypes.string.isRequired,
  downloadType: PropTypes.string.isRequired,
  bomType: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
};
