import { createReducer } from '@reduxjs/toolkit';

import * as authActions from './authActions';
import { ADMIN_PLANT_ID_KEY, ADMIN_VENDOR_ID_KEY } from '../utils/authUtils';
import set from 'lodash/set';
import get from 'lodash/get';
import { showSuccessNotification } from '../utils/Notifications';

const aadloginsuccess = 'AAD_LOGIN_SUCCESS';

const initialState = {
  loading: false,
  authenticated: false,
  rolesFetched: false,
  roles: [],
  vendorId: '',
  plantId: '',
  identifiers: [],
};

export const AuthReducer = createReducer(initialState, {
  [aadloginsuccess](state) {
    state.authenticated = true;
  },
  [authActions.getUsersRolesStart](state) {
    state.loading = true;
  },
  [authActions.getUsersRolesFailure](state) {
    state.roles = [];
    state.loading = false;
    state.identifiers = [];
  },
  [authActions.getUsersRolesSuccess](state, action) {
    state.roles = get(action, 'payload.result.roles');
    state.vendorId = get(action, 'payload.result.vendorId');
    state.plantId = get(action, 'payload.result.plantId');
    state.identifiers = get(action, 'payload.result.identifiers', '').split(',');

    state.loading = false;
    state.rolesFetched = true;
  },
  [authActions.setAdminVendorId](state, action) {
    if (action.payload) {
      localStorage.setItem(ADMIN_VENDOR_ID_KEY, action.payload);
    }
    return set(state, 'vendorId', action.payload);
  },
  [authActions.setAdminPlantId](state, action) {
    if (action.payload) {
      localStorage.setItem(ADMIN_PLANT_ID_KEY, action.payload);
    }
    return set(state, 'plantId', action.payload);
  },
  [authActions.switchVendorContext](state, action) {
    const plantId = action.payload.plantId;
    const vendorId = action.payload.vendorId;

    localStorage.setItem(ADMIN_PLANT_ID_KEY, plantId);
    localStorage.setItem(ADMIN_VENDOR_ID_KEY, vendorId);
    set(state, 'plantId', plantId);
    set(state, 'vendorId', vendorId);

    showSuccessNotification(`Vendor has been switched to ${vendorId} and plant ${plantId}`);
  },
});
