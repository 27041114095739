/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import { PermissibleRender } from '@brainhubeu/react-permissible';
import { useSelector } from 'react-redux';
import * as authSelectors from '../Auth/authSelectors';
import AccessDenied from '../AccessDenied/AccessDenied';
import ReactNotification from 'react-notifications-component';

const RouteWithLayout = props => {
  const { layout: Layout, component: Component, requiredPermissions, ...rest } = props;
  const userPermissions = useSelector(state => authSelectors.getUserPermissions(state));
  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          <PermissibleRender
            userPermissions={userPermissions}
            requiredPermissions={requiredPermissions}
            renderOtherwise={<AccessDenied />}
          >
            <ReactNotification />
            <Component {...matchProps} />
          </PermissibleRender>
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.func.isRequired,
  layout: PropTypes.func.isRequired,
  path: PropTypes.string,
  requiredPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RouteWithLayout;
