import Form, { Field, FormFooter, FormHeader, FormSection } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';

import Button from '@atlaskit/button';

import AbbTitle from '../../../shared/AbbSectionTitle';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { DownloadUserGuide } from '../../../shared/DownloadUserGuide';

export const TraceSerialNumberInputPage = () => {
  const history = useHistory();

  const onSubmit = useCallback(
    data => {
      history.push(`/trace/${data.serialNumber}`);
    },
    [history],
  );

  return (
    <>
      <AbbTitle title="Trace by serial number">
        <DownloadUserGuide filename="/user-manuals/user-guide_tracing.docx" />
      </AbbTitle>
      <Form onSubmit={onSubmit}>
        {({ formProps, dirty, submitting }) => (
          <form {...formProps}>
            <FormHeader title="Get trace information using serial number" />
            <FormSection>
              <Field name="serialNumber" defaultValue="" isRequired>
                {({ fieldProps }) => (
                  <TextField maxLength={18} type="number" width="medium" placeholder="Serial number" {...fieldProps} />
                )}
              </Field>
            </FormSection>

            <FormFooter align="start">
              <Button type="submit" appearance="primary" isDisabled={!dirty || submitting}>
                Trace
              </Button>
            </FormFooter>
          </form>
        )}
      </Form>
    </>
  );
};
