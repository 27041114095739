import { Row } from 'simple-flexbox';
import TextField from '@atlaskit/textfield';
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import SelectClearIcon from '@atlaskit/icon/glyph/select-clear';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Form, { Field, FormFooter, FormHeader, FormSection } from '@atlaskit/form';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { saveStorageBins } from './StorageBinsActions';
import { isSaving } from './StorageBinsSelectors';
import { ShipNtDialog } from '../../shared/ShipNtDialog';

const StorageBinRow = styled(Row)`
  width: 100%;
  padding: 0.5rem 0;
`;
const AddressField = styled.div`
  flex-grow: 1;
  padding: 0 2rem;
`;
export const InputWithClear = ({ onClear, ...rest }) => {
  return (
    <Row>
      <TextField maxLength={10} type="text" style={{ width: '100%' }} {...rest} />
      <Button appearance="subtle" onClick={onClear} iconBefore={<SelectClearIcon />} />
    </Row>
  );
};
export const StorageBinRows = ({ storageBin }) => {
  const dispatch = useDispatch();
  const { rows } = storageBin;

  const [shipNtDialogVisible, setShipNtDialogVisible] = useState(false);

  const saving = useSelector(state => isSaving(state));

  const onSaveStorageBins = useCallback(
    values => {
      dispatch(saveStorageBins({ ...values }));
    },
    [dispatch],
  );

  const clearStorageBin = useCallback(onChange => {
    onChange('');
  }, []);

  const showShipNtDialog = useCallback(() => {
    setShipNtDialogVisible(true);
  }, []);

  const closeShipNtDialog = useCallback(() => {
    setShipNtDialogVisible(false);
  }, []);

  return (
    <>
      <ShipNtDialog
        materialNumber={storageBin.materialNumber}
        isOpen={shipNtDialogVisible}
        onClose={closeShipNtDialog}
      />

      <Form onSubmit={onSaveStorageBins}>
        {({ formProps, dirty, submitting }) => (
          <form {...formProps}>
            <FormHeader title="Storage bins" />
            <FormSection>
              {rows.map(row => (
                <StorageBinRow key={row.name} justifyContent="space-between" alignItems="center">
                  <div>{row.name}</div>
                  <AddressField>
                    <Field key={row.name} name={row.name} defaultValue={row.address}>
                      {({ fieldProps }) => (
                        <InputWithClear {...fieldProps} onClear={() => clearStorageBin(fieldProps.onChange)} />
                      )}
                    </Field>
                  </AddressField>
                </StorageBinRow>
              ))}
            </FormSection>
            <FormFooter>
              <ButtonGroup>
                <Button onClick={showShipNtDialog}>Ship NT</Button>
                <LoadingButton
                  type="submit"
                  appearance="primary"
                  isDisabled={!dirty || submitting || saving}
                  isLoading={saving}
                >
                  Save
                </LoadingButton>
              </ButtonGroup>
            </FormFooter>
          </form>
        )}
      </Form>
    </>
  );
};

StorageBinRows.propTypes = {
  storageBin: PropTypes.shape({
    materialNumber: PropTypes.string.isRequired,
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
      }),
    ),
  }),
};

InputWithClear.propTypes = {
  onClear: PropTypes.func.isRequired,
};
