import { createAction } from '@reduxjs/toolkit';
import { buildApiUrl } from '../../shared/utils/ApiUtils';
import { RSAA } from 'redux-api-middleware';

export const getVariantListStart = createAction('selectVariant/getVariantListStart');
export const getVariantListSuccess = createAction('selectVariant/getVariantListSuccess');
export const getVariantListFailure = createAction('selectVariant/getVariantListFailure');

export const fetchVariantsFromBackend = () => dispatch => {
  const url = buildApiUrl('workQueues/variantsList');

  return dispatch({
    [RSAA]: {
      endpoint: url,
      headers: { 'Content-Type': 'application/json' },
      method: 'GET',
      types: [getVariantListStart.type, getVariantListSuccess.type, getVariantListFailure.type],
    },
  });
};
