import React from 'react';
import { LayoutManager, modeGenerator, NavigationProvider, ThemeProvider } from '@atlaskit/navigation-next';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import GlobalTopBar from './components/GlobalTopBar';
import SideNavigation from './components/SideNavigation';

const customMode = modeGenerator({
  product: {
    text: '#4E4E4E',
    background: '#FFFFFF',
  },
});

const isStaging = () => window.location.hostname.includes('stage.');

const Content = styled.div`
  padding: 12px 30px;
  background-color: ${isStaging() ? '#e3eff7' : '#f0f0f0'};
  min-height: 100%;
  height: auto;
`;

const Main = props => {
  const { children } = props;

  return (
    <NavigationProvider>
      <ThemeProvider theme={theme => ({ ...theme, mode: customMode })}>
        <LayoutManager
          experimental_flyoutOnHover
          experimental_alternateFlyoutBehaviour
          experimental_horizontalGlobalNav
          globalNavigation={GlobalTopBar}
          productNavigation={SideNavigation}
          containerNavigation={null}
        >
          <Content>{children}</Content>
        </LayoutManager>
      </ThemeProvider>
    </NavigationProvider>
  );
};

Main.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Main;
