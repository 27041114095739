import { getConfiguration } from './configuration';

export const DownloadDocumentsEndpoint = 'download/documentDownload';

export const buildApiUrl = (path, params = {}) => {
  let root = getConfiguration('mspApi.root', 'http://localhost:5000/api');

  if (!root.endsWith('/')) {
    root = root + '/';
  }

  const queryParams = new URLSearchParams(params);

  if (queryParams.toString()) {
    return `${root}${path}?${queryParams}`;
  }

  return `${root}${path}`;
};
