import React, { useEffect } from 'react';
import AbbTitle from '../../shared/AbbSectionTitle';
import Select from '@atlaskit/select';
import { useDispatch, useSelector } from 'react-redux';
import { getVariants, isLoading } from './variantSelectionReducer';
import { fetchVariantsFromBackend } from './variantSelectionActions';
import PageLoading from '../../shared/PageLoading';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { DownloadUserGuide } from '../../shared/DownloadUserGuide';

const StyledGrid = styled.div`
  display: flex;
  align-items: center;
`;

const SelectWrapper = styled.div`
  margin-left: 16px;
  min-width: 300px;
`;

function VariantSelectionPage() {
  const dispatch = useDispatch();
  const variants = useSelector(state => getVariants(state));
  const loading = useSelector(state => isLoading(state));

  const history = useHistory();

  const selectVariant = ({ value }, { action }) => {
    if (action === 'select-option') {
      history.push(`/variant/${value}/workqueue`);
    }
  };

  useEffect(() => {
    dispatch(fetchVariantsFromBackend());
  }, [dispatch]);

  return (
    <>
      <PageLoading visible={loading} />
      <AbbTitle title="Work queue by variant">
        <DownloadUserGuide filename="/user-manuals/user-guide_manufacturing-work-queues.docx" />
      </AbbTitle>
      <StyledGrid>
        <p>Select variant: </p>
        <SelectWrapper>
          <Select
            className="async-select-with-callback"
            classNamePrefix="react-select"
            defaultOptions
            options={variants}
            placeholder="Choose a variant"
            onChange={(value, action) => selectVariant(value, action)}
          />
        </SelectWrapper>
      </StyledGrid>
    </>
  );
}

export default VariantSelectionPage;
