export const AccessWorkQueues = 'access:work_queue';
export const AccessTracing = 'access:tracing';
export const AccessDocumentSearch = 'access:document_search';
export const AllowImpersonateVendor = 'allow:impersonate_vendor';
export const AccessBillOfMaterials = 'access:bom';
export const AccessPrinting = 'access:printing';
export const AccessReports = 'access:reports';
export const AccessMaterialSearch = 'access:material_search';
export const AllowWorkQueueLabelPrint = 'allow:work_queue:label_print';
export const AccessStorageBins = 'access:storage_bins';
export const AccessPrintWorkOrder = 'access:print_work_order';

export const UseSingleSelectFilterForOrderNumber = 'use:single_select_filter_for_order_number';
