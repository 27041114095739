import PropTypes from 'prop-types';
import { store } from 'react-notifications-component';
import { useHistory } from 'react-router-dom';
import React, { useCallback } from 'react';
import Button from '@atlaskit/button';
import Icon from '@atlaskit/icon';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import CheckCircleOutlineIcon from '@atlaskit/icon/glyph/check-circle-outline';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import * as uuid from 'uuid';
import Get from 'lodash/get';
import IsString from 'lodash/isString';

const params = {
  danger: {
    title: 'Operation failed',
    dismiss: {
      duration: 0,
    },
    appearance: 'error',
    icon: <Icon glyph={WarningIcon} label="Warning icon" size="xlarge" />,
  },
  success: {
    title: 'Operation succeeded',
    dismiss: {
      duration: 3000,
    },
    appearance: 'announcement',
    icon: <Icon glyph={CheckCircleOutlineIcon} label="Success icon" size="xlarge" />,
  },
};

const NotificationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
`;

const ButtonContainer = styled.div`
  margin-left: 2rem;
  align-content: flex-end;
  font-size: smaller;
`;

const NotificationText = styled.div`
  font-size: 1rem;
  color: white;
  flex-grow: 2;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

export const ReloadButton = {
  text: 'Reload',
  onClick: ({ history }) => {
    history.go(0);
  },
};

const DismissButton = id => ({
  text: 'Dismiss',
  onClick: () => store.removeNotification(id),
});

const ButtonWrapper = ({ button }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    button.onClick({ history, dispatch });
  }, [history, dispatch, button]);

  return (
    <StyledButton key={button.text} onClick={onClick} appearance={'primary'}>
      {button.text}
    </StyledButton>
  );
};
ButtonWrapper.propTypes = {
  button: PropTypes.object.isRequired,
};

export const showSuccessNotification = (message, ...buttons) => {
  showNotification(message, buttons, 'success');
};

export const showFailureNotification = (message, ...buttons) => {
  showNotification(message, buttons, 'danger');
};

export const showActionFailure = (action, defaultMessage, ...buttons) => {
  return showFailureNotification(getErrorFromActionPayload(action, defaultMessage), ...buttons);
};

const showNotification = (message, buttons = [], type) => {
  let duration = params[type].dismiss.duration;
  if (type === 'success' && buttons.length > 0) {
    duration = 0;
  }

  const id = uuid.v4();

  if (duration === 0) {
    buttons.push(DismissButton(id));
  }
  const settings = {
    ...params[type],
    id,
    insert: 'top',
    width: 600,
    container: 'top-center',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      showIcon: true,
      duration,
    },
    content: (
      <div className={`notification-${type}`} style={{ width: '100%' }}>
        <NotificationContainer>
          <NotificationText>
            {params[type].icon}
            {message}
          </NotificationText>
          <ButtonContainer>
            {buttons.map(button => (
              <ButtonWrapper key={button.text} button={button} />
            ))}
          </ButtonContainer>
        </NotificationContainer>
      </div>
    ),
  };

  store.addNotification(settings);
};

export const getErrorFromActionPayload = (action, defaultMessage) => {
  let message = Get(action, 'payload');

  if (!IsString(message)) {
    message = Get(action, 'payload.response.responseException.details');
  }

  return IsString(message) ? message : defaultMessage;
};

export const getInfoMessageFromAction = (action, defaultMessage) => {
  const message = Get(action, 'payload.message', defaultMessage);

  return IsString(message) ? message : defaultMessage;
};
