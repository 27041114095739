import { createAction } from '@reduxjs/toolkit';
import { getProductionLine, getRowsByIds, getSelectedRows, getSelectedVariant } from './WorkQueueSelectors';
import Get from 'lodash/get';
import { buildApiUrl, DownloadDocumentsEndpoint } from '../../../shared/utils/ApiUtils';
import { RSAA } from 'redux-api-middleware';
import TrimStart from 'lodash/trimStart';
import { createPrintoutData, downloadFileForEachProductLine } from './SapPrintout';
import { getVendorId } from '../../../shared/Auth/authSelectors';
import GroupBy from 'lodash/groupBy';
import { showSuccessNotification } from '../../../shared/utils/Notifications';
import { showRePrintRowsDialog } from '../RePrintDialog/RePrintRatingPlateDialog';
import { generateFilename, handleFileDownload, shortDateAsString } from '../../../shared/utils/FileDownloadUtils';
import { getBomTree } from '../../BillOfMaterials/BomDetails/billOfMaterialsDetailsReducer';
import {
  downloadSelectedBomFiles,
  fetchBillOfMaterialsDetails,
} from '../../BillOfMaterials/BomDetails/billOfMaterialsDetailsActions';
import { DownloadSelectedButtonCode } from '../../BillOfMaterials/BomDetails/componenets/BillOfMaterialsDetailsActionButtons';
import { columnsMaterialDhlOrder } from '../../BillOfMaterials/BomDetails/billOfMaterialsDetailsPage';
import { extractDataAsFlatArray } from '../../BillOfMaterials/BomDetails/componenets/BomActionDropdown';
import { saveExcelDhlOrder } from '../../../shared/ExportToExcel';

export const updateSelectedRows = createAction('workQueue/updateSelectedRows');
export const changeRowData = createAction('workQueue/changeRowData');

export const getWorkQueueStart = createAction('workQueue/getWorkQueueStart');
export const getWorkQueueSuccess = createAction('workQueue/getWorkQueueSuccess');
export const getWorkQueueFailure = createAction('workQueue/getWorkQueueFailure');

export const createSerialNumberStart = createAction('workQueue/createSerialNumberStart');
export const createSerialNumberSuccess = createAction('workQueue/createSerialNumberSuccess');
export const createSerialNumberFailure = createAction('workQueue/createSerialNumberFailure');

export const sendStatusUpdateStart = createAction('workQueue/sendStatusUpdateStart');
export const sendStatusUpdateSuccess = createAction('workQueue/sendStatusUpdateSuccess');
export const sendStatusUpdateFailure = createAction('workQueue/sendStatusUpdateFailure');

export const triggerSapPrintoutStart = createAction('workQueue/triggerSapPrintoutStart');
export const triggerSapPrintoutSuccess = createAction('workQueue/triggerSapPrintoutSuccess');
export const triggerSapPrintoutFailure = createAction('workQueue/triggerSapPrintoutFailure');

export const confirmOrderStart = createAction('workQueue/confirmOrderStart');
export const confirmOrderSuccess = createAction('workQueue/confirmOrderSuccess');
export const confirmOrderFailure = createAction('workQueue/confirmOrderFailure');

export const sapFileDownloadStart = createAction('workQueue/sapFileDownloadStart');
export const sapFileDownloadSuccess = createAction('workQueue/sapFileDownloadSuccess');
export const sapFileDownloadFailure = createAction('workQueue/sapFileDownloadFailure');

export const reset = createAction('workQueue/reset');

export const promptCreateSerialNumber = createAction('workQueue/promptCreateSerialNumber');
export const closeCreateSerialNumberDialog = createAction('workQueue/closeCreateSerialNumberDialog');

export const printPackingLabelStart = createAction('workQueue/printPackingLabelStart');
export const printPackingLabelSuccess = createAction('workQueue/printPackingLabelSuccess');
export const printPackingLabelFailure = createAction('workQueue/printPackingLabelFailure');

export const setFilteredRows = createAction('workQueue/setFilteredRows');

export const printWorkQueueDocumentsStart = createAction('workQueue/printWorkQueueDocumentsStart');
export const printWorkQueueDocumentsSuccess = createAction('workQueue/printWorkQueueDocumentsSuccess');

export const fetchWorkQueue = selectedVariant => (dispatch, getState) => {
  const state = getState();
  const currentVariant = getSelectedVariant(state);

  if (currentVariant === selectedVariant) {
    return;
  }

  const url = buildApiUrl('workQueues', { variant: selectedVariant });

  const meta = {
    reqId: Math.random(),
    selectedVariant,
  };

  return dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      types: [
        { type: getWorkQueueStart.type, meta },
        { type: getWorkQueueSuccess.type, meta },
        { type: getWorkQueueFailure.type, meta },
      ],
    },
  });
};
const callCreateSerialNumberEndpoint = (productionLine, prodOrder, unitCount, rowId) => dispatch => {
  const data = {
    productionLine,
    prodOrder,
    unitCount,
  };
  const url = buildApiUrl('workQueues/createSerialNumber', data);

  return dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      types: [
        createSerialNumberStart.type,
        {
          type: createSerialNumberSuccess.type,
          meta: {
            rowId,
          },
        },
        {
          type: createSerialNumberFailure.type,
          meta: {
            rowId,
          },
        },
      ],
    },
  });
};
export const createSerialNumber = () => async (dispatch, getState) => {
  const state = getState();
  const productionLine = getProductionLine(state);
  const selectedRows = getSelectedRows(state);

  const prodOrder = Get(selectedRows, '[0].prodOrder', '');
  const unitCount = Get(selectedRows, '[0].unitCount', '');

  const rowId = Get(selectedRows, '[0].id');

  const action = await dispatch(callCreateSerialNumberEndpoint(productionLine, prodOrder, unitCount, rowId));

  if (action.error) {
    return action;
  }

  const createdSerialNumber = Get(action, 'payload.result', '');

  const goToTraceScreenButton = {
    text: 'Go to tracing screen',
    onClick: ({ dispatch, history }) => dispatch(traceBySerialNumber(history, createdSerialNumber)),
  };

  showSuccessNotification('Serial number created successfully', goToTraceScreenButton);
};
export const traceBySerialNumber = (history, serialNumber) => (dispatch, getState) => {
  const selectedRows = getSelectedRows(getState());

  const sn = serialNumber || Get(selectedRows, '[0].serialNumber', '');

  if (!sn) {
    return dispatch(promptCreateSerialNumber());
  }

  dispatch(reset());

  return history.push('/trace/' + sn);
};

export const printWorkOrderDocuments = () => (dispatch, getState) => {
  const state = getState();
  const currentVariant = getSelectedVariant(state);
  console.log('*****');
  console.log(currentVariant);
  console.log('*****');
  const kitHeaders = [
    {
      key: 'materialCode',
      label: 'Material Code',
    },
    {
      key: 'materialDescription',
      label: 'Material Description',
    },
    {
      key: 'typeDesign',
      label: 'Type designation',
    },
    {
      key: 'rev',
      label: 'Rev',
    },
  ];

  const downloadFiles = [
    'ASSEMBLY DRAWING',
    'PARTS LIST',
    'INSTRUCTION',
    'WORK INSTRUCTION',
    'QUICK GUIDE',
    'PHOTO KIT PACKING PHOTO/LIST',
  ];

  const selectedRow = getSelectedRows(getState())[0];

  dispatch({ type: printWorkQueueDocumentsStart().type, meta: { buttonCode: 'printDocuments' } });

  return dispatch(fetchBillOfMaterialsDetails({ materialCode: selectedRow.material })).then(() => {
    const exportDataProvider = () => {
      const headers = columnsMaterialDhlOrder
        .filter(column => column.name)
        .map(column => ({ key: column.key, label: column.name }));

      const headerKeys = headers.map(header => header.key);

      const dataToExport = extractDataAsFlatArray(bomData.rows, headerKeys);

      return {
        headers: headers,
        data: dataToExport,
      };
    };
    const bomData = getBomTree(getState());
    if (currentVariant && !currentVariant.toUpperCase().startsWith('X_')) {
      dispatch(printPackingLabel(selectedRow.material, selectedRow.sumCounter));
    }
    if (bomData && bomData.rows && bomData.rows.length > 0) {
      const dhlOrderSpecificFiles = bomData.rows
        .filter(x =>
          downloadFiles.some(
            z => x.description.trim().toUpperCase().startsWith(z) || x.description.includes('INSTRUCT'),
          ),
        )
        .map(x => x.id);
      dispatch(downloadSelectedBomFiles(DownloadSelectedButtonCode, selectedRow.material, dhlOrderSpecificFiles)).then(
        () => {
          dispatch({ type: printWorkQueueDocumentsSuccess().type, meta: { buttonCode: 'printDocuments' } });
        },
      );
    }

    saveExcelDhlOrder({
      ...exportDataProvider(),
      kitHeaders: kitHeaders,
      kitData: bomData.header,
      name: selectedRow.prodOrder,
      filename: `${selectedRow.material}-${selectedRow.prodOrder}`,
    });
  });
};

export const updateStatusForSelectedRows = () => (dispatch, getState) => {
  const selectedRows = getSelectedRows(getState());

  const statusUpdateData = selectedRows
    .map(row => ({
      prodOrder: row.prodOrder,
      status: row.statusLong,
      mspdate: row.mspdate,
    }))
    .filter(data => data.prodOrder);

  const url = buildApiUrl('workQueues/updateStatus');

  return dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(statusUpdateData),
      types: [sendStatusUpdateStart.type, sendStatusUpdateSuccess.type, sendStatusUpdateFailure.type],
    },
  });
};
const defaultPrintoutDataProvider = state => {
  const selectedRows = getSelectedRows(state);
  const productionLine = getProductionLine(state);
  return createPrintoutData(selectedRows, productionLine);
};
export const triggerSapPrintout = (
  buttonCode,
  buttonDescription,
  printoutDataProvider = defaultPrintoutDataProvider,
  rsaaActionTypes = [triggerSapPrintoutStart, triggerSapPrintoutSuccess, triggerSapPrintoutFailure],
) => (dispatch, getState) => {
  const state = getState();

  const printoutData = printoutDataProvider(state);

  const url = buildApiUrl('workQueues/printout');

  const data = {
    code: buttonCode,
    rows: printoutData,
  };

  const meta = {
    buttonCode,
    buttonDescription,
  };

  return dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
      types: [
        {
          type: rsaaActionTypes[0].type,
          meta,
        },
        {
          type: rsaaActionTypes[1].type,
          meta,
        },
        {
          type: rsaaActionTypes[2].type,
          meta,
        },
      ],
    },
  });
};
const printoutDataProvider = selectedRows => state => {
  const selectedRowIds = selectedRows.map(row => row.id);
  const productionLine = getProductionLine(state);
  const rows = getRowsByIds(state, selectedRowIds);
  return createPrintoutData(rows, productionLine);
};

export const printWorkOrder = buttonCode => (dispatch, getState) => {
  const state = getState();
  const selectedRows = getSelectedRows(state);

  const url = buildApiUrl(DownloadDocumentsEndpoint);

  return dispatch(triggerSapFileDownload(buttonCode, url, printoutDataProvider(selectedRows)));
};
export const triggerSapFileDownload = (
  buttonCode,
  url,
  printoutDataProvider,
  rsaaActionTypes = [sapFileDownloadStart, sapFileDownloadSuccess, sapFileDownloadFailure],
) => async (dispatch, getState) => {
  const state = getState();
  const vendorId = getVendorId(state);

  const printoutData = printoutDataProvider(state);

  const groupedRowsByProdLine = GroupBy(printoutData, row => row.prodLine);

  Object.keys(groupedRowsByProdLine).forEach(prodLine => {
    downloadFileForEachProductLine(
      prodLine,
      groupedRowsByProdLine[prodLine],
      dispatch,
      buttonCode,
      url,
      vendorId,
      rsaaActionTypes,
    );
  });
};
export const confirmWorkOrder = () => (dispatch, getState) => {
  const state = getState();
  const productionLine = getProductionLine(state);
  const selectedRows = getSelectedRows(state);

  const printoutData = createPrintoutData(selectedRows, productionLine);

  const url = buildApiUrl('workQueues/confirmOrder');

  const data = {
    code: 'CONFSUM',
    rows: printoutData,
  };

  return dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
      types: [confirmOrderStart.type, confirmOrderSuccess.type, confirmOrderFailure.type],
    },
  });
};
export const printRatingPlate = () => (dispatch, getState) => {
  const state = getState();
  const selectedRows = getSelectedRows(state);

  const rowHasSerialNumber = item => item.serialNumber;

  if (selectedRows.some(rowHasSerialNumber)) {
    return dispatch(showRePrintRowsDialog(true));
  } else {
    return dispatch(triggerRatingPlatePrinting(selectedRows));
  }
};
const createSerialNumbersIfNeeded = (selectedRows, productionLine) => async dispatch => {
  const updatedRows = [];

  for (const row of selectedRows) {
    if (!row.serialNumber) {
      const createSerialAction = await dispatch(
        callCreateSerialNumberEndpoint(productionLine, row.prodOrder, row.unitCount, row.id),
      );
      if (createSerialAction.error) {
        return createSerialAction;
      }

      updatedRows.push({
        ...row,
        serialNumber: Get(createSerialAction, 'payload.result.serialNumber', row.serialNumber),
      });
    } else {
      updatedRows.push(row);
    }
  }

  return createAction('rowsUpdated')(updatedRows);
};
export const downloadRatingPlate = (printoutDataProvider, rsaaActionTypes) => dispatch => {
  const url = buildApiUrl(DownloadDocumentsEndpoint);

  return dispatch(triggerSapFileDownload('WWW_RATINGP', url, printoutDataProvider, rsaaActionTypes));
};
export const triggerRatingPlatePrinting = selectedRows => async (dispatch, getState) => {
  const state = getState();
  const productionLine = getProductionLine(state);
  if (!selectedRows) {
    selectedRows = getSelectedRows(state);
  }
  const updatedRowsAction = await dispatch(createSerialNumbersIfNeeded(selectedRows, productionLine));

  if (updatedRowsAction.error) {
    return updatedRowsAction;
  }

  dispatch(downloadRatingPlate(printoutDataProvider(selectedRows)));
};
export const printPackingLabel = (material, printQty) => dispatch => {
  const trimmedPrintQty = TrimStart(printQty, '0') || '1';

  const url = buildApiUrl('download/downloadLabel', { material, printQty: trimmedPrintQty, qty: 1 });

  const filenameGenerator = contentType => generateFilename(contentType, 'LABEL', shortDateAsString());

  dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      fetch: handleFileDownload(filenameGenerator),
      types: [printPackingLabelStart.type, printPackingLabelSuccess.type, printPackingLabelFailure.type],
    },
  });
};
