import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import GreenIcon from 'assets/green.png';
import RedIcon from 'assets/red.png';
import styled from 'styled-components';
import ToNumber from 'lodash/toNumber';
import { isMultiselectVariant } from './WorkQueueUtils';
import Button from '@atlaskit/button';
import { useDispatch, useSelector } from 'react-redux';

import * as qs from 'query-string';
import { BomTypeMaterial, BomTypeProductionOrder, BomTypeSalesOrder } from '../../../shared/utils/contstans';
import { printPackingLabel } from './WorkQueueActions';
import TrimStart from 'lodash/trimStart';
import { hasUserPermission } from '../../../shared/Auth/authSelectors';
import { AccessBillOfMaterials } from '../../../shared/Auth/permissions';

export const OrderNumberLink = ({ value, row, onClick = () => {} }) => {
  const hasAccessToBom = useSelector(state => hasUserPermission(state, AccessBillOfMaterials));

  if (!hasAccessToBom) {
    return <div>{value}</div>;
  }

  const bomQuery = {
    bomType: BomTypeProductionOrder,
    prodOrderNumber: row.prodOrder,
    salesOrderNumber: row.salesdocument,
    salesOrderNumberItem: row.salesdocitem,
  };

  return (
    <Link onClick={onClick} to={`/bom/${row.material}?${qs.stringify(bomQuery)}`}>
      {value}
    </Link>
  );
};

OrderNumberLink.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export const CreateSalesDocumentLink = variant => {
  const SalesDocumentLink = ({ value, row }) => {
    if (isMultiselectVariant(variant)) {
      return <div>{value}</div>;
    }

    const bomQuery = {
      bomType: BomTypeSalesOrder,
      salesOrderNumber: row.salesdocument,
      salesOrderNumberItem: row.salesdocitem,
    };
    return <Link to={`/bom/${row.material}?${qs.stringify(bomQuery)}`}>{value}</Link>;
  };

  SalesDocumentLink.propTypes = {
    value: PropTypes.any.isRequired,
    row: PropTypes.shape({
      salesdocitem: PropTypes.any,
      salesdocument: PropTypes.any,
      material: PropTypes.string,
    }).isRequired,
  };

  return SalesDocumentLink;
};

const RightFloating = styled.div`
  float: right;
`;

export const StockQuantityFormatter = ({ value, row }) => {
  if (row.safetyStock) {
    const stock = parseInt(value);
    const safetyStock = parseInt(row.safetyStock);
    const availQty = stock - safetyStock;
    const tooltip = 'Avail. qty: ' + availQty;

    return (
      <div title={tooltip}>
        <span>{value}</span>
        <RightFloating>
          <img src={availQty >= 0 ? GreenIcon : RedIcon} alt="Stock icon" />
        </RightFloating>
      </div>
    );
  }
  return <div>{value}</div>;
};

StockQuantityFormatter.propTypes = {
  row: PropTypes.shape({
    safetyStock: PropTypes.number,
  }).isRequired,
  value: PropTypes.any.isRequired,
};

export const ProductCodeFormatter = ({ value }) => {
  return <div>{value}</div>;
};

ProductCodeFormatter.propTypes = {
  value: PropTypes.any.isRequired,
};

export const NumberFormatter = ({ value }) => {
  return <div>{ToNumber(value)}</div>;
};

NumberFormatter.propTypes = {
  value: PropTypes.any.isRequired,
};

export const MaterialCodeLinkFormatter = ({ value }) => {
  return <Link to={`/bom/${value}?bomType=${BomTypeMaterial}`}>{value}</Link>;
};

MaterialCodeLinkFormatter.propTypes = {
  value: PropTypes.any.isRequired,
};

export const PrintPackageLabel = ({ row }) => {
  const dispatch = useDispatch();

  const onPrintPackingLabel = useCallback(
    (material, sumCounter) => {
      dispatch(printPackingLabel(material, sumCounter));
    },
    [dispatch],
  );

  if (row.material && row.sumCounter) {
    const trimmedSumCounter = TrimStart(row.sumCounter, '0');
    return (
      <Button
        appearance="link"
        onClick={() => onPrintPackingLabel(row.material, row.sumCounter)}
        isDisabled={!trimmedSumCounter}
      >
        Packing label
      </Button>
    );
  }

  return <span>Packing label</span>;
};

PrintPackageLabel.propTypes = {
  row: PropTypes.shape({
    sumCounter: PropTypes.number,
    material: PropTypes.string,
  }).isRequired,
};
