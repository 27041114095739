import PageLoading from '../../shared/PageLoading';
import AbbTitle from '../../shared/AbbSectionTitle';
import React from 'react';
import { useSelector } from 'react-redux';
import { getStorageBin, isLoading } from './StorageBinsSelectors';
import { GetStorageBinsForm } from './GetStorageBinsForm';
import { EditStorageBin } from './EditStorageBin';
import { DownloadUserGuide } from '../../shared/DownloadUserGuide';

export const StorageBinsPage = () => {
  const loading = useSelector(state => isLoading(state));
  const storageBin = useSelector(state => getStorageBin(state));

  return (
    <>
      <PageLoading visible={loading} />

      <AbbTitle title="Storage bin update">
        <DownloadUserGuide filename="/user-manuals/user-guide_storage-bins.docx" />
      </AbbTitle>
      <GetStorageBinsForm />
      <EditStorageBin storageBin={storageBin} />
    </>
  );
};
