import { createAction } from '@reduxjs/toolkit';
import { RSAA } from 'redux-api-middleware';

export const testApiCallBegin = createAction('homePage/testApiCallBegin');
export const testApiCallSuccess = createAction('homePage/testApiCallSuccess');
export const testApiCallFailure = createAction('homePage/testApiCallFailure');

export const testApiCall = () => dispatch => {
  const config = JSON.parse(window.APP_CONFIG);
  const apiUrl = `${config.mspApi.root}values`;
  return dispatch({
    [RSAA]: {
      endpoint: apiUrl,
      method: 'GET',
      types: [testApiCallBegin.type, testApiCallSuccess.type, testApiCallFailure.type],
    },
  });
};
