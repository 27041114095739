import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import MainLayout from './app/layout';
import HomePage from './app/views/HomePage';
import { DocumentSearchPage } from './app/views/DocumentSearch';
import RouteWithLayout from './app/shared/RouteWithLayout';
import VariantSelectionPage from './app/views/Manufacturing';
import WorkQueuePage from './app/views/Manufacturing/WorkQueue';
import { TraceSerialNumberInputPage, TracingPage } from './app/views/Manufacturing/Trace';
import { BillOfMaterialsDetailsPage, BillOfMaterialsPage } from './app/views/BillOfMaterials';

import * as PERMISSIONS from './app/shared/Auth/permissions';
import { PrintingPage } from './app/views/Printing';
import { ReportsPage } from './app/views/Reports';
import { StorageBinsPage } from './app/views/StorageBins';
import { PrintWorkOrderPage } from './app/views/PrintWorkOrder';
import { MaterialSearchPage } from './app/views/MaterialSearch';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/home" />
      <RouteWithLayout requiredPermissions={[]} component={HomePage} exact layout={MainLayout} path="/home" />
      <RouteWithLayout
        component={VariantSelectionPage}
        requiredPermissions={[PERMISSIONS.AccessWorkQueues]}
        exact
        layout={MainLayout}
        path="/variant"
      />
      <RouteWithLayout
        requiredPermissions={[PERMISSIONS.AccessWorkQueues]}
        component={WorkQueuePage}
        exact
        layout={MainLayout}
        path="/variant/:variant/workqueue"
      />
      <RouteWithLayout
        requiredPermissions={[PERMISSIONS.AccessTracing]}
        component={TraceSerialNumberInputPage}
        exact
        layout={MainLayout}
        path="/trace"
      />
      <RouteWithLayout
        requiredPermissions={[PERMISSIONS.AccessTracing]}
        component={TracingPage}
        exact
        layout={MainLayout}
        path="/trace/:serialNumber"
      />
      <RouteWithLayout
        requiredPermissions={[PERMISSIONS.AccessDocumentSearch]}
        component={DocumentSearchPage}
        exact
        layout={MainLayout}
        path="/document-search"
      />
      <RouteWithLayout
        requiredPermissions={[PERMISSIONS.AccessBillOfMaterials]}
        component={BillOfMaterialsPage}
        exact
        layout={MainLayout}
        path="/bom"
      />
      <RouteWithLayout
        requiredPermissions={[PERMISSIONS.AccessPrinting]}
        component={PrintingPage}
        exact
        layout={MainLayout}
        path="/printing"
      />
      <RouteWithLayout
        requiredPermissions={[PERMISSIONS.AccessBillOfMaterials]}
        component={BillOfMaterialsDetailsPage}
        exact
        layout={MainLayout}
        path="/bom/:materialCode"
      />
      <RouteWithLayout
        requiredPermissions={[PERMISSIONS.AccessReports]}
        component={ReportsPage}
        exact
        layout={MainLayout}
        path="/reports"
      />
      <RouteWithLayout
        requiredPermissions={[PERMISSIONS.AccessStorageBins]}
        component={StorageBinsPage}
        exact
        layout={MainLayout}
        path="/storage-bins"
      />
      <RouteWithLayout
        requiredPermissions={[PERMISSIONS.AccessPrintWorkOrder]}
        component={PrintWorkOrderPage}
        exact
        layout={MainLayout}
        path="/print-wo"
      />
      <RouteWithLayout
        requiredPermissions={[PERMISSIONS.AccessMaterialSearch]}
        component={MaterialSearchPage}
        exact
        layout={MainLayout}
        path="/material-search"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
