import React from 'react';
import PropTypes from 'prop-types';

export const ShipNtRows = ({ rows }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>MrpElement</th>
          <th>Purchase order nr/pos</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr key={index}>
            <td>{row.date}</td>
            <td>{row.mrpElement}</td>
            <td>{row.elementData}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

ShipNtRows.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      mrpElement: PropTypes.string,
      elementData: PropTypes.string,
    }),
  ).isRequired,
};
