import React, { useCallback, useRef } from 'react';
import ReactDataGrid from 'react-data-grid';
import EmptyGridState from '../../../shared/Grid/EmptyGridState';
import { useDispatch, useSelector } from 'react-redux';
import { getBomType, getSearchResult, isLoading } from '../printingReducer';
import { getGridHeight } from '../../../shared/utils/gridUtils';
import PropTypes from 'prop-types';
import RenderDropdownLevels from '../../BillOfMaterials/BomDetails/componenets/RenderDropdownLevels';

import DownloadIcon from '@atlaskit/icon/glyph/download';
import MediaServicesSpreadsheetIcon from '@atlaskit/icon/glyph/media-services/spreadsheet';
import { MaxNumberOfBomLevels } from '../../../shared/utils/contstans';
import { SmallerFont } from '../../../shared/Grid/SmallerFont';
import styled from 'styled-components';
import {
  triggerBomFileDownload,
  triggerProductionOrderFileDownload,
} from '../../BillOfMaterials/BomDetails/billOfMaterialsDetailsActions';
import {
  DownloadAllButtonCode,
  PrintBomButtonCode,
} from '../../BillOfMaterials/BomDetails/componenets/BillOfMaterialsDetailsActionButtons';

const noop = () => {};

const SearchResultColumns = [
  {
    key: 'materialCode',
    name: 'Material code',
  },
  {
    key: 'materialDescription',
    name: 'Description',
  },
  {
    key: 'productionOrder',
    name: 'Production order',
  },
  {
    key: 'productionOrderItem',
    name: 'PO item',
  },
];

const ResultWrapper = styled.div`
  overflow: auto;
  white-space: nowrap;
`;

const SearchResultsGrid = ({ gridRows }) => {
  const loading = useSelector(state => isLoading(state));
  const dataGridRef = useRef();
  const bomType = useSelector(state => getBomType(state));
  const header = useSelector(state => getSearchResult(state).header);

  const dispatch = useDispatch();

  const downloadDocuments = useCallback(
    event => {
      const { row } = event;

      if (bomType === 'Material') {
        dispatch(triggerBomFileDownload(DownloadAllButtonCode, row.materialCode, null, [], event.level));
      } else if (bomType === 'SalesOrder') {
        dispatch(
          triggerProductionOrderFileDownload(
            DownloadAllButtonCode,
            row.materialCode,
            event.level,
            header.productionOrder,
            header.productionOrderItem,
          ),
        );
      }
    },
    [bomType, dispatch, header],
  );

  const printDocuments = useCallback(
    event => {
      const { row } = event;
      if (bomType === 'Material') {
        dispatch(triggerBomFileDownload(PrintBomButtonCode, row.materialCode, null, [], event.level));
      } else if (bomType === 'SalesOrder') {
        dispatch(
          triggerProductionOrderFileDownload(
            PrintBomButtonCode,
            row.materialCode,
            event.level,
            header.productionOrder,
            header.productionOrderItem,
          ),
        );
      }
    },
    [bomType, dispatch, header],
  );

  const getActionCellButtons = (column, row) => {
    if (column.key === 'materialCode') {
      return [
        {
          icon: (
            <RenderDropdownLevels
              numberOfLevels={MaxNumberOfBomLevels}
              dropDownTrigger={<DownloadIcon label={'download'} />}
              clickEventType={'download'}
              onOptionClick={e => downloadDocuments({ ...e, row })}
            />
          ),
          callback: noop,
        },
        {
          icon: (
            <RenderDropdownLevels
              numberOfLevels={MaxNumberOfBomLevels}
              dropDownTrigger={<MediaServicesSpreadsheetIcon />}
              clickEventType={'print'}
              onOptionClick={e => printDocuments({ ...e, row })}
            />
          ),
          callback: noop,
        },
      ];
    }

    return null;
  };

  return (
    <ResultWrapper>
      <SmallerFont>
        <ReactDataGrid
          ref={dataGridRef}
          columns={SearchResultColumns}
          rowGetter={i => gridRows[i]}
          emptyRowsView={() => EmptyGridState(loading)}
          rowsCount={gridRows.length}
          getCellActions={getActionCellButtons}
          minColumnWidth={20}
          minHeight={getGridHeight(gridRows.length)}
          enableRowSelect={null}
        />
      </SmallerFont>
    </ResultWrapper>
  );
};

SearchResultsGrid.propTypes = {
  gridRows: PropTypes.array.isRequired,
};

export default SearchResultsGrid;
