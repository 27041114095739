import PropTypes from 'prop-types';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import React, { useCallback, useState } from 'react';
import Textfield from '@atlaskit/textfield';
import { useDispatch, useSelector } from 'react-redux';
import { downloadReport, hidePasswordDialog } from './ReportsActions';
import { getPasswordProtectedReport } from './ReportsSelectors';

export const PasswordDialog = ({ isOpen }) => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState(undefined);

  const report = useSelector(state => getPasswordProtectedReport(state));

  const downloadReportWithPassword = useCallback(() => {
    dispatch(downloadReport(report, password));
    dispatch(hidePasswordDialog());
  }, [dispatch, report, password]);

  const actions = [
    {
      text: 'Generate',
      isDisabled: !password || password.length === 0,
      onClick: downloadReportWithPassword,
    },
    { text: 'Cancel', onClick: () => dispatch(hidePasswordDialog()) },
  ];

  const onKeyPress = useCallback(
    e => {
      if (e.key === 'Enter') {
        downloadReportWithPassword();
      }
    },
    [downloadReportWithPassword],
  );

  return (
    <ModalTransition>
      {isOpen && (
        <Modal actions={actions} heading="This report has been protected with password" autoFocus={true}>
          <label htmlFor="password">Password for report</label>
          <Textfield
            type="password"
            name="password"
            isRequired
            onChange={e => setPassword(e.target.value)}
            onKeyPress={onKeyPress}
          />
        </Modal>
      )}
    </ModalTransition>
  );
};

PasswordDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};
