/* eslint-disable no-unused-vars */
import { createAction } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedRows, isLoading } from '../WorkQueue/WorkQueueSelectors';
import { LoadingButton } from '@atlaskit/button';
import { printPackingLabel } from '../WorkQueue/WorkQueueActions';
import LabelIcon from '@atlaskit/icon/glyph/label';
import TableIcon from '@atlaskit/icon/glyph/table';
import { OrderNumberLink } from '../WorkQueue/DataGridFormatters';
import styled from 'styled-components';
import FolderFilledIcon from '@atlaskit/icon/glyph/folder-filled';
import get from 'lodash/get';
import PageLoading from 'app/shared/PageLoading';
import { getBomTree } from 'app/views/BillOfMaterials/BomDetails/billOfMaterialsDetailsReducer';
import {
  downloadSelectedBomFiles,
  fetchBillOfMaterialsDetails,
} from 'app/views/BillOfMaterials/BomDetails/billOfMaterialsDetailsActions';
import BillOfMaterialsDetailsHeaderGrid from 'app/views/BillOfMaterials/BomDetails/componenets/BillOfMaterialsDetailsHeader';
import { BomTypeProductionOrder } from 'app/shared/utils/contstans';
import { columnsMaterialDhlOrder } from 'app/views/BillOfMaterials/BomDetails/billOfMaterialsDetailsPage';
import { extractDataAsFlatArray } from 'app/views/BillOfMaterials/BomDetails/componenets/BomActionDropdown';
import { saveExcelDhlOrder } from 'app/shared/ExportToExcel';
import { DownloadSelectedButtonCode } from 'app/views/BillOfMaterials/BomDetails/componenets/BillOfMaterialsDetailsActionButtons';

const SectionHeading = styled.h5`
  margin-bottom: 16px;
`;

const Separator = styled.span`
  margin-bottom: 5px;
  display: block;
`;

const DhlOrderKitHeaders = [
  {
    key: 'materialCode',
    label: 'Material Code',
  },
  {
    key: 'materialDescription',
    label: 'Material Description',
  },
  {
    key: 'typeDesign',
    label: 'Type designation',
  },
  {
    key: 'rev',
    label: 'Rev',
  },
];

const DhlOrderDownloadFiles = [
  'ASSEMBLY DRAWING',
  'PARTS LIST',
  'INSTRUCTION',
  'WORK INSTRUCTION',
  'QUICK GUIDE',
  'PHOTO KIT PACKING PHOTO/LIST',
];

export const DhlOrderDialog = ({ visible }) => {
  const dispatch = useDispatch();
  const selectedRow = useSelector(state => getSelectedRows(state)[0]);
  const isLoadingPackingLabel = useSelector(state => isLoading(state));
  const isLoadingBom = useSelector(state => get(state, 'bomDetails.loading', false));
  const bomData = useSelector(state => getBomTree(state));
  const selectedWorkQueueItem = useSelector(state => getSelectedRows(state));

  const onCloseDialog = useCallback(() => {
    dispatch(showDhlOrderDialog(false));
  }, [dispatch]);

  const exportDataProvider = useCallback(() => {
    const headers = columnsMaterialDhlOrder
      .filter(column => column.name)
      .map(column => ({ key: column.key, label: column.name }));

    const headerKeys = headers.map(header => header.key);

    const dataToExport = extractDataAsFlatArray(bomData.rows, headerKeys);

    return {
      headers: headers,
      data: dataToExport,
    };
  }, [bomData]);

  const onPrintPackingLabel = useCallback(
    (material, sumCounter) => {
      dispatch(printPackingLabel(material, sumCounter));
    },
    [dispatch],
  );

  const onDownloadBomExcel = useCallback(() => {
    const data = exportDataProvider();
    saveExcelDhlOrder({
      ...data,
      kitHeaders: DhlOrderKitHeaders,
      kitData: bomData.header,
      name: selectedRow.prodOrder,
      filename: `${selectedRow.material}-${selectedRow.prodOrder}`,
    });
  });

  const handleDownloadDhlBomFiles = () => {
    if (bomData && bomData.rows && bomData.rows.length > 0) {
      const dhlOrderSpecificFiles = bomData.rows
        .filter(x =>
          DhlOrderDownloadFiles.some(
            z => x.description.trim().toUpperCase().startsWith(z) || x.description.includes('INSTRUCT'),
          ),
        )
        .map(x => x.id);
      dispatch(downloadSelectedBomFiles(DownloadSelectedButtonCode, selectedRow.material, dhlOrderSpecificFiles));
    }
  };

  useEffect(() => {
    if (visible && selectedRow) {
      dispatch(fetchBillOfMaterialsDetails({ materialCode: selectedRow.material }));
    }
  }, [dispatch, visible, selectedRow]);

  if (!visible || !selectedRow) {
    return null;
  }

  const actions = [{ text: 'Cancel', onClick: onCloseDialog }];

  return (
    <ModalTransition>
      <Modal
        key="active-modal"
        actions={actions}
        onClose={onCloseDialog}
        width={'x-large'}
        heading={
          <>
            DHL work queue order:{' '}
            <OrderNumberLink onClick={onCloseDialog} value={selectedRow.prodOrder} row={selectedRow} />
          </>
        }
      >
        <PageLoading visible={isLoadingBom} />
        <Separator />
        <BillOfMaterialsDetailsHeaderGrid bomType={BomTypeProductionOrder} bomHeader={bomData && bomData.header} />
        <h1>Sum: {selectedRow.sumCounter}</h1>
        <SectionHeading>Order packing label</SectionHeading>
        <LoadingButton
          isDisabled={isLoadingBom}
          isLoading={isLoadingPackingLabel}
          appearance="primary"
          iconBefore={<LabelIcon />}
          onClick={() => onPrintPackingLabel(selectedRow.material, selectedRow.sumCounter)}
        >
          Packing label
        </LoadingButton>

        <SectionHeading>Order excel file</SectionHeading>
        <LoadingButton
          isDisabled={isLoadingBom}
          appearance="primary"
          onClick={() => onDownloadBomExcel()}
          iconBefore={<TableIcon />}
        >
          Download BOM new excel
        </LoadingButton>

        <SectionHeading>BOM files</SectionHeading>
        <LoadingButton
          onClick={handleDownloadDhlBomFiles}
          isDisabled={isLoadingBom}
          appearance="primary"
          iconBefore={<FolderFilledIcon />}
        >
          Download BOM files
        </LoadingButton>
      </Modal>
    </ModalTransition>
  );
};

DhlOrderDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export const showDhlOrderDialog = createAction('dhlOrderDialog/showDhlOrderDialog');
