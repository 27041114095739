import TableTree, { Cell, Header, Row, Rows } from '@atlaskit/table-tree';
import { Checkbox } from '@atlaskit/checkbox';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { GridColumnFormatter } from '../../../shared/utils/gridUtils';
import PropTypes from 'prop-types';
import DownloadIcon from '@atlaskit/icon/glyph/download';
import Button from '@atlaskit/button';
import Get from 'lodash/get';
import IsEqual from 'lodash/isEqual';
import Without from 'lodash/without';
import { DownloadMaterialDocuments, PrintMaterialDocuments } from '../../../shared/DownloadMaterialImages';
import SubtaskIcon from '@atlaskit/icon/glyph/subtask';
import { MaxNumberOfBomLevels } from 'app/shared/utils/contstans';

const StyledGridHeader = React.memo(
  styled(Header)`
    padding: 8px;
    font-weight: 700;
    color: #000000;
    background-color: #f9f9f9;
    border-right: 1px solid #ddd;
  `,
  () => {
    return true;
  },
);

const StyledHeadersWrapper = styled.div`
  position: sticky;
  display: flex;
  top: 0;
  z-index: 10;
`;

const StyledCell = styled(Cell)`
  background-color: #ffffff;
  padding: 8px;
  border-right: 1px solid #ddd;
  display: inline-flex;
`;

const StyledCellCenter = styled(StyledCell)`
  justify-content: center;
  align-items: center;
`;

const StyledCellExpander = styled(Cell)`
  background-color: #ffffff;
  border-right: 1px solid #ddd;
`;

const GridWrapper = styled.div`
  overflow: auto;
  white-space: nowrap;
  max-height: ${props => props.gridHeight}px;
  max-width: fit-content;
`;

const OverflowDiv = styled.div`
  display: inline-block;
`;

const staticColumnWidths = {
  pos: 200,
};

const DataCell = React.memo(
  ({ width, data }) => {
    return <StyledCell width={width}>{data}</StyledCell>;
  },
  () => {
    return true;
  },
);

DataCell.propTypes = {
  width: PropTypes.number.isRequired,
  data: PropTypes.any.isRequired,
};

DataCell.displayName = 'DataCell';

const selectAllSubcomponents = (subcomponents = [], onRowSelect) => {
  subcomponents.forEach(component => {
    if (component.docs) {
      onRowSelect(generateId(component));
    }
    selectAllSubcomponents(component.children, onRowSelect);
  });
};

const CheckboxCell = React.memo(
  ({ id, onRowSelect, subcomponents, checkedRows, isDisabled, ...rest }) => {
    return (
      <StyledCell singleLine width={80}>
        <div style={{ display: 'flex', width: '100%' }}>
          <Checkbox
            isChecked={checkedRows.includes(id)}
            onChange={() => {
              onRowSelect(id);
            }}
            value={id || ''}
            isDisabled={isDisabled}
            {...rest}
          />
          {subcomponents.length > 0 && (
            <Button
              appearance={'subtle'}
              onClick={() => {
                if (!isDisabled) {
                  onRowSelect(id);
                }
                selectAllSubcomponents(subcomponents, onRowSelect);
              }}
              iconBefore={<SubtaskIcon />}
            />
          )}
        </div>
      </StyledCell>
    );
  },
  (prev, next) => {
    if (prev.subcomponents.length > 0) {
      return false;
    }
    return prev.checkedRows.includes(prev.id) === next.checkedRows.includes(next.id);
  },
);

CheckboxCell.displayName = 'CheckboxCell';

CheckboxCell.propTypes = {
  checkedRows: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  onRowSelect: PropTypes.func.isRequired,
  subcomponents: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

const DocumentDownloadCell = React.memo(
  ({ width, code }) => {
    return (
      <StyledCellCenter width={width}>
        <DownloadMaterialDocuments
          materialNumber={code}
          renderButton={onClick => (
            <Button appearance={'subtle'} onClick={onClick} iconBefore={<DownloadIcon label={'download'} />} />
          )}
        />
      </StyledCellCenter>
    );
  },
  () => {
    return true;
  },
);

DocumentDownloadCell.propTypes = {
  width: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
};

DocumentDownloadCell.displayName = 'DocumentDownloadCell';

const PrintDownloadCell = React.memo(
  ({ width, bomNumber, code, bomType }) => {
    return (
      <StyledCellCenter width={width}>
        {code && (
          <PrintMaterialDocuments
            materialNumber={bomNumber}
            materialNumberVariant={code}
            downloadType={'PrintBomVariant'}
            level={MaxNumberOfBomLevels}
            bomType={bomType}
          />
        )}
      </StyledCellCenter>
    );
  },
  () => {
    return true;
  },
);

PrintDownloadCell.propTypes = {
  width: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
  bomNumber: PropTypes.string.isRequired,
  bomType: PropTypes.string.isRequired,
};

PrintDownloadCell.displayName = 'PrintDownloadCell';

const generateId = element => {
  return `${element.parentId}-${element.code}-${element.pos}`;
};

function extractMaterialCodeFromId(id) {
  return id.split('-')[1];
}

const BillOfMaterialsDetailsTreeGrid = ({
  data,
  cols,
  flatRows,
  expansionMap,
  onRowExpanded,
  onRowCollapsed,
  showCheckbox,
  onCheckboxClick,
  gridHeight,
  bomType,
  bomNumber,
}) => {
  const columnWidthFormatter = new GridColumnFormatter(staticColumnWidths);
  cols = columnWidthFormatter.formatColumnsWidth(flatRows, cols, null, 8);
  if (showCheckbox && Get(cols, '[1].key', '') !== 'checkbox') {
    cols.splice(1, 0, {
      key: 'checkbox',
    });
  }

  const [internalCheckedRows, setInternalCheckedRows] = useState([]);

  const onRowSelect = useCallback(
    id => {
      const isAlreadySelected = internalCheckedRows.includes(id);

      const code = extractMaterialCodeFromId(id);

      onCheckboxClick({ checked: !isAlreadySelected, rowId: code });

      if (!isAlreadySelected) {
        setInternalCheckedRows(prevState => [...prevState, id]);
      } else {
        setInternalCheckedRows(prevState => Without(prevState, id));
      }
    },
    [onCheckboxClick, internalCheckedRows],
  );

  if (!data || !flatRows) return null;

  if (data.length === 0) {
    return null;
  }

  return (
    <GridWrapper gridHeight={gridHeight}>
      <OverflowDiv>
        <TableTree>
          <StyledHeadersWrapper>
            {cols.map(col => {
              if (col.key === 'checkbox') {
                return (
                  <StyledGridHeader width={80} key={col.key}>
                    <Checkbox isDisabled />
                  </StyledGridHeader>
                );
              } else {
                return (
                  <StyledGridHeader width={col.width} key={col.key}>
                    {col.name}
                  </StyledGridHeader>
                );
              }
            })}
          </StyledHeadersWrapper>
          <Rows
            items={data}
            render={renderProps => {
              const items = renderProps.children.map(child => ({
                ...child,
                id: generateId(child),
              }));

              return (
                <Row
                  items={items}
                  hasChildren={renderProps.children.length > 0}
                  isExpanded={expansionMap[renderProps.id]}
                  onExpand={() => onRowExpanded(renderProps)}
                  onCollapse={() => onRowCollapsed(renderProps)}
                >
                  {cols.map(col => {
                    const key = `${col.key}-${renderProps.id}-${renderProps.pos}`;

                    if (col.key === 'pos') {
                      return (
                        <StyledCellExpander key={key} width={col.width}>
                          {renderProps[col.key]}
                        </StyledCellExpander>
                      );
                    } else if (col.key === 'checkbox') {
                      return (
                        <CheckboxCell
                          key={key}
                          id={renderProps.id}
                          subcomponents={items}
                          onRowSelect={onRowSelect}
                          checkedRows={internalCheckedRows}
                          isDisabled={!renderProps.docs}
                        />
                      );
                    } else if (col.key === 'docs' && renderProps.docs) {
                      return <DocumentDownloadCell width={col.width} code={renderProps.code} key={key} />;
                    } else if (col.key === 'prints' && renderProps.children.length > 0) {
                      return (
                        <PrintDownloadCell
                          width={col.width}
                          bomNumber={bomNumber}
                          code={renderProps.code}
                          bomType={bomType}
                          key={key}
                        />
                      );
                    } else {
                      return <DataCell width={col.width} data={renderProps[col.key]} key={key} />;
                    }
                  })}
                </Row>
              );
            }}
          />
        </TableTree>
      </OverflowDiv>
    </GridWrapper>
  );
};

BillOfMaterialsDetailsTreeGrid.propTypes = {
  data: PropTypes.array.isRequired,
  cols: PropTypes.array.isRequired,
  flatRows: PropTypes.array.isRequired,
  expansionMap: PropTypes.object.isRequired,
  onRowExpanded: PropTypes.func,
  onRowCollapsed: PropTypes.func,
  showCheckbox: PropTypes.bool.isRequired,
  onCheckboxClick: PropTypes.func,
  gridHeight: PropTypes.number,
  bomType: PropTypes.string.isRequired,
  bomNumber: PropTypes.string.isRequired,
};

const areEqual = (prev, next) => {
  prev.data.length === next.data.length && IsEqual(prev.checkedRows, next.checkedRows);
};
export default React.memo(BillOfMaterialsDetailsTreeGrid, areEqual);
