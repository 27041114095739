import React, { useEffect } from 'react';
import Routes from '../Routes';
import styled, { injectGlobal } from 'styled-components';

import { setAdminPlantId, setAdminVendorId } from './shared/Auth/authActions';
import { ADMIN_PLANT_ID_KEY, ADMIN_VENDOR_ID_KEY, canImpersonateVendor } from './shared/utils/authUtils';
import { useDispatch, useSelector } from 'react-redux';
import { getIdentifiers, getUserPermissions, hasRolesBeenFetched, isAuthenticated } from './shared/Auth/authSelectors';
import LoadingIndicator from './shared/LoadingIndicator';

injectGlobal`
  .react-grid-Cell {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    overflow: clip;
    text-overflow: ellipsis;
  }

  .input-sm {
    padding: 8px 6px;
    height: 30px;
    font-size: 14px;
    color: inherit;
  }

  .rdg-cell-action-button {
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
    padding: 0 3px !important;
  }

  .rdg-last--frozen {
    display: flex;
  }

  .react-grid-Cell__value {
    width: 100%;
    overflow: visible!important;
  }

  .rdg-cell-action-button{
    z-index: 99;
  }
`;

const SmallList = styled.p`
  font-size: 10px;!important;
  color: lightgrey;
  position: absolute;
  bottom: 0;
  height: 2rem;
  margin-left: 2rem;
`;

function App() {
  const userPermissions = useSelector(state => getUserPermissions(state));
  const authenticated = useSelector(state => isAuthenticated(state));
  const rolesFetched = useSelector(state => hasRolesBeenFetched(state));
  const identifiers = useSelector(state => getIdentifiers(state));

  const dispatch = useDispatch();

  useEffect(() => {
    if (canImpersonateVendor(userPermissions)) {
      dispatch(setAdminVendorId(localStorage.getItem(ADMIN_VENDOR_ID_KEY) || '121015'));
      dispatch(setAdminPlantId(localStorage.getItem(ADMIN_PLANT_ID_KEY) || '0800'));
    }
  }, [dispatch, userPermissions]);

  const authenticatedAndHasRoles = authenticated && rolesFetched && userPermissions.length > 0;
  const authenticatedButDoesntHaveRoles = authenticated && rolesFetched && userPermissions.length === 0;

  if (authenticatedAndHasRoles) {
    return <Routes />;
  }

  if (authenticatedButDoesntHaveRoles) {
    return (
      <div>
        <h2>Insufficient permissions</h2>
        <p>You do not have required permissions to access the application. Please contact your administrator.</p>
        <SmallList>
          Identifiers:
          {identifiers.map(identifier => (
            <span key={identifier}>{identifier}</span>
          ))}
        </SmallList>
      </div>
    );
  }
  return (
    <div>
      <LoadingIndicator message="Loading..." />
    </div>
  );
}

export default App;
