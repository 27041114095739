import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { StorageBinHeader } from './StorageBinHeader';
import { StorageBinRows } from './StorageBinRows';
import IsEmpty from 'lodash/isEmpty';

const StorageBin = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 40rem;
`;

export const EditStorageBin = ({ storageBin }) => {
  if (IsEmpty(storageBin)) {
    return null;
  }
  return (
    <StorageBin>
      <StorageBinHeader storageBin={storageBin} />
      <StorageBinRows storageBin={storageBin} />
    </StorageBin>
  );
};

EditStorageBin.propTypes = {
  storageBin: PropTypes.object.isRequired,
};
