import React, { useCallback, useEffect, useState } from 'react';
import AbbTitle from '../../shared/AbbSectionTitle';
import SearchMaterial from '../../shared/BillOfMaterials/SearchMaterial';
import PageLoading from '../../shared/PageLoading';
import { useDispatch, useSelector } from 'react-redux';
import { searchMaterials } from './materialSearchActions';
import { getMaterialSearchResult, isLoading, isSearching } from './materialSearchReducer';
import MaterialSearchResultGrid from './components/MaterialSearchResultGrid';
import IsEmpty from 'lodash/isEmpty';
import { ShipNtDialog } from '../../shared/ShipNtDialog';
import styled from 'styled-components';
import { DownloadUserGuide } from '../../shared/DownloadUserGuide';

const GridWrapper = styled.div`
  margin-top: 1rem;
`;

const MaterialSearchPage = () => {
  const dispatch = useDispatch();
  const results = useSelector(state => getMaterialSearchResult(state));
  const [shipNtDialogVisible, setShipNtDialogVisible] = useState(false);
  const [actionMaterialCode, setActionMaterialCode] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const loading = useSelector(state => isLoading(state));
  const searching = useSelector(state => isSearching(state));

  const onSearchMaterial = useCallback(
    materialCode => {
      dispatch(searchMaterials(materialCode));
    },
    [dispatch],
  );

  useEffect(() => {
    if (results.components) {
      const { header } = results;
      let rows = [...results.components];
      if (header.material) {
        rows = [{ ...header, item: header.prodItem, isKitHeader: true }, ...rows];
      }
      rows = rows.map((row, index) => ({ ...row, number: index + 1 }));

      setSearchResults(rows);
    }
  }, [results, setSearchResults]);

  const showShipNtDialog = useCallback(() => {
    setShipNtDialogVisible(true);
  }, []);

  const closeShipNtDialog = useCallback(() => {
    setShipNtDialogVisible(false);
  }, []);

  const handleShipNtClick = useCallback(
    materialCode => {
      setActionMaterialCode(materialCode);
      showShipNtDialog();
    },
    [setActionMaterialCode, showShipNtDialog],
  );

  return (
    <>
      <PageLoading visible={loading} />
      <AbbTitle title="Material Search">
        <DownloadUserGuide filename="/user-manuals/user-guide_material-search.docx" />
      </AbbTitle>
      <ShipNtDialog materialNumber={actionMaterialCode} isOpen={shipNtDialogVisible} onClose={closeShipNtDialog} />
      <SearchMaterial onSubmitSearch={onSearchMaterial} searching={searching} />
      {(!IsEmpty(results) || loading) && (
        <GridWrapper>
          <MaterialSearchResultGrid onShipNtClick={handleShipNtClick} gridRows={searchResults || []} />
        </GridWrapper>
      )}
    </>
  );
};

export default MaterialSearchPage;
