import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from './app/shared/utils/history';
import homePageReducer from './app/views/HomePage/homePageReducer';
import { VariantSelectionReducer } from './app/views/Manufacturing';
import { WorkQueueReducer } from './app/views/Manufacturing/WorkQueue';
import { AuthReducer } from './app/shared/Auth/authReducer';
import { BillOfMaterialsDetailsReducer, BillOfMaterialsReducer } from './app/views/BillOfMaterials';
import { TracingReducer } from './app/views/Manufacturing/Trace';
import { DocumentSearchReducer } from './app/views/DocumentSearch';
import { PrintingReducer } from './app/views/Printing';
import { ReportsReducer } from './app/views/Reports';
import { createAction } from '@reduxjs/toolkit';
import { storageBindsReducer } from './app/views/StorageBins';
import { shipNtDialogReducer } from './app/shared/ShipNtDialog';
import { printWorkOrderReducer } from './app/views/PrintWorkOrder';
import { MaterialSearchReducer } from './app/views/MaterialSearch';

export const resetAppState = createAction('RESET_APP_STATE');

const appReducer = combineReducers({
  router: connectRouter(history),
  homePage: homePageReducer,
  selectVariant: VariantSelectionReducer,
  workQueue: WorkQueueReducer,
  auth: AuthReducer,
  bom: BillOfMaterialsReducer,
  bomDetails: BillOfMaterialsDetailsReducer,
  tracing: TracingReducer,
  documentSearch: DocumentSearchReducer,
  printing: PrintingReducer,
  materialSearch: MaterialSearchReducer,
  reports: ReportsReducer,
  storageBins: storageBindsReducer,
  shipNt: shipNtDialogReducer,
  printWO: printWorkOrderReducer,
});

const rootReducer = (state, action) => {
  if (action.type === resetAppState.type) {
    const { auth } = state;
    state = { auth };
  }

  return appReducer(state, action);
};

export default rootReducer;
