import { buildApiUrl } from '../../shared/utils/ApiUtils';
import { RSAA } from 'redux-api-middleware';
import { createAction } from '@reduxjs/toolkit';
import { getStorageBin } from './StorageBinsSelectors';

export const fetchStorageBinsStart = createAction('storageBins/fetchStorageBinsStart');
export const fetchStorageBinsSuccess = createAction('storageBins/fetchStorageBinsSuccess');
export const fetchStorageBinsFailure = createAction('storageBins/fetchStorageBinsFailure');

export const saveStorageBinsStart = createAction('storageBins/saveStorageBinsStart');
export const saveStorageBinsSuccess = createAction('storageBins/saveStorageBinsSuccess');
export const saveStorageBinsFailure = createAction('storageBins/saveStorageBinsFailure');

export const fetchStorageBins = materialNumber => dispatch => {
  const url = buildApiUrl('storageBins', { materialNumber });

  const meta = { materialNumber };

  return dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      types: [fetchStorageBinsStart.type, { type: fetchStorageBinsSuccess.type, meta }, fetchStorageBinsFailure.type],
    },
  });
};

export const saveStorageBins = storageBinRows => (dispatch, getState) => {
  const { description, typeDesignation, quantity, materialNumber } = getStorageBin(getState());

  const url = buildApiUrl('storageBins/' + materialNumber);

  const storageBin = {
    description,
    typeDesignation,
    quantity,
    rows: Object.keys(storageBinRows).map(key => ({ name: key, address: storageBinRows[key] })),
  };

  return dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(storageBin),
      types: [saveStorageBinsStart.type, saveStorageBinsSuccess.type, saveStorageBinsFailure.type],
    },
  });
};
