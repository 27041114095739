import UpperFirst from 'lodash/upperFirst';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const HeaderWrapper = styled.div`
  width: 100%;
`;

const plantSegmentNumber = {
  display: 'inline-block',
  fontWeight: 'bold',
  fontSize: '12px',
  marginRight: '10px',
};
const availableQuantity1 = {
  display: 'inline-block',
  fontSize: '10px',
  marginRight: '5px',
  width: '30px',
  textAlign: 'right',
};
const availableDate = {
  display: 'inline-block',
  fontSize: '10px',
  marginRight: '5px',
};
const pcs = {
  fontSize: '10px',
  marginRight: '5px',
};
const tdStockStyle = {
  lineHeight: '0.8',
};
//margin content for spacing

export const ShipNtHeader = ({ header, stocks }) => {
  return (
    <HeaderWrapper>
      <table>
        <tbody>
          <tr>
            <th>Material</th>
            <td>{header.material}</td>
          </tr>
          <tr>
            <th>Description</th>
            <td>{header.description}</td>
          </tr>
          <tr>
            <th>Purchasing group</th>
            <td>
              {header.purGroup} {header.purGroupName}
            </td>
          </tr>
          <tr>
            <th>{UpperFirst(header.stock)}</th>
            {stocks.map(stock => {
              return (
                <tr key={'tr' + stock.plantSegmentNumber}>
                  <td key={stock.plantSegmentNumber} style={tdStockStyle}>
                    <span style={plantSegmentNumber}>{stock.plantSegmentNumber}</span>
                    <span style={availableQuantity1}>{stock.availableQuantity1}</span>
                    <span style={pcs}>pcs</span>
                    <span style={availableDate}>({stock.availableDate})</span>
                  </td>
                </tr>
              );
            })}
          </tr>
        </tbody>
      </table>
    </HeaderWrapper>
  );
};

ShipNtHeader.propTypes = {
  header: PropTypes.shape({
    date: PropTypes.string,
    description: PropTypes.string,
    material: PropTypes.string.isRequired,
    purGroup: PropTypes.string,
    purGroupName: PropTypes.string,
    quantity: PropTypes.any,
    stock: PropTypes.string,
  }).isRequired,
  stocks: PropTypes.arrayOf(
    PropTypes.shape({
      plantSegmentNumber: PropTypes.string,
      availableQuantity1: PropTypes.number,
      availableDate: PropTypes.string,
    }),
  ).isRequired,
};
