import React, { useCallback, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useDispatch } from 'react-redux';
import Fuse from 'fuse.js';
import Textfield from '@atlaskit/textfield';
import PropTypes from 'prop-types';

const SearchBar = ({ data, isDisabled, fuseConfig, dispatchOnFiltered, onFiltered, debounceDelay = 300 }) => {
  const [searchInput, setSearchInput] = useState('');
  const dispatch = useDispatch();
  const fuse = new Fuse(data, fuseConfig);

  const debouncedCallback = useDebouncedCallback(val => {
    if (val.length === 0) {
      if (dispatchOnFiltered) {
        dispatch(dispatchOnFiltered(data));
      }
      if (onFiltered) {
        onFiltered(data);
      }
      return;
    }

    const searchResult = fuse.search(val);
    if (dispatchOnFiltered) {
      dispatch(dispatchOnFiltered(searchResult.map(x => x.item)));
    }
    if (onFiltered) {
      onFiltered(searchResult.map(x => x.item));
    }
  }, debounceDelay);

  const handleChange = useCallback(
    val => {
      setSearchInput(val);
      debouncedCallback(val);
    },
    [debouncedCallback],
  );

  return (
    <>
      <div>
        <Textfield
          isDisabled={isDisabled}
          value={searchInput}
          onChange={e => handleChange(e.target.value)}
          autoComplete="off"
          name="searchBar"
          placeholder="Filter bill of materials"
        />
      </div>
    </>
  );
};

SearchBar.propTypes = {
  data: PropTypes.array,
  isDisabled: PropTypes.bool,
  fuseConfig: PropTypes.object,
  dispatchOnFiltered: PropTypes.any,
  onFiltered: PropTypes.any,
  debounceDelay: PropTypes.number,
};

export default SearchBar;
