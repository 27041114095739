import React from 'react';

import { BomTypeMaterial, BomTypeSalesOrder } from '../utils/contstans';
import Form, { Field, FormFooter } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';

import PropTypes from 'prop-types';
import SearchIcon from '@atlaskit/icon/glyph/search';
import { LoadingButton } from '@atlaskit/button';

const SearchMaterialForm = ({ searchType, onSubmitSearch, searching }) => {
  return (
    <Form onSubmit={e => onSubmitSearch({ ...e, searchType })}>
      {({ formProps }) => (
        <form {...formProps}>
          {searchType === BomTypeMaterial && (
            <Field name="materialCode" defaultValue="" isRequired>
              {({ fieldProps, error }) => (
                <TextField width="large" placeholder={'Material'} {...fieldProps} isInvalid={error} />
              )}
            </Field>
          )}
          {searchType === BomTypeSalesOrder && (
            <>
              <Field name="salesOrderNumber" defaultValue="" isRequired>
                {({ fieldProps, error }) => (
                  <TextField width="large" placeholder={'Sales Order number'} {...fieldProps} isInvalid={error} />
                )}
              </Field>
              <Field name="salesOrderNumberItem" defaultValue="" isRequired>
                {({ fieldProps, error }) => (
                  <TextField width="large" placeholder={'Sales Order item number'} {...fieldProps} isInvalid={error} />
                )}
              </Field>
            </>
          )}
          <FormFooter align={'start'}>
            <LoadingButton type="submit" iconBefore={<SearchIcon />} appearance="primary" isLoading={searching}>
              Search
            </LoadingButton>
          </FormFooter>
        </form>
      )}
    </Form>
  );
};

SearchMaterialForm.propTypes = {
  searchType: PropTypes.string.isRequired,
  onSubmitSearch: PropTypes.func.isRequired,
  searching: PropTypes.bool,
};

export default SearchMaterialForm;
