import { createReducer } from '@reduxjs/toolkit';
import {
  downloadFileFailure,
  downloadFileStart,
  downloadFileSuccess,
  fetchDocumentKindsFailure,
  fetchDocumentKindsStart,
  fetchDocumentKindsSuccess,
  searchFailure,
  searchStart,
  searchSuccess,
} from './DocumentSearchActions';
import { showActionFailure } from '../../shared/utils/Notifications';
import Get from 'lodash/get';

const initialState = {
  loading: false,
  documentKinds: [],
  results: [],
};

export const DocumentSearchReducer = createReducer(initialState, {
  [fetchDocumentKindsStart](state) {
    state.loading = true;
  },
  [fetchDocumentKindsSuccess](state, action) {
    state.loading = false;
    state.documentKinds = Get(action, 'payload.result', []);
  },
  [fetchDocumentKindsFailure](state, action) {
    state.loading = false;
    showActionFailure(action, 'Failed to load document kinds');
  },
  [searchStart](state) {
    state.loading = true;
  },
  [searchSuccess](state, action) {
    state.loading = false;

    const files = Get(action, 'payload.result.files', []);
    state.results = Get(action, 'payload.result.result', []).map(result => {
      const resultFiles = files.filter(
        file => file.documentNumber === result.documentNumber && file.documentVersion === result.documentVersion,
      );
      return {
        ...result,
        files: resultFiles,
      };
    });
  },
  [searchFailure](state, action) {
    state.loading = false;
    showActionFailure(action, 'Failed to perform the search');
  },
  [downloadFileStart](state) {
    state.loading = true;
  },
  [downloadFileSuccess](state) {
    state.loading = false;
  },
  [downloadFileFailure](state, action) {
    state.loading = false;
    showActionFailure(action, 'Failed to download the file');
  },
});
