import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Textfield from '@atlaskit/textfield';
import Button from '@atlaskit/button';

import * as authActions from '../Auth/authActions';
import * as authSelectors from '../Auth/authSelectors';
import { resetAppState } from '../../../reducers';

const ContextSwitcherWrapper = styled.div`
  padding: 12px;
`;

function VendorContextSwitcher() {
  const [vendorId, setVendorId] = useState();
  const [plantId, setPlantId] = useState();
  const initialVendorId = useSelector(state => authSelectors.getVendorId(state));
  const initialPlantId = useSelector(state => authSelectors.getPlantId(state));
  const dispatch = useDispatch();
  let history = useHistory();

  const isFormValid = plantId && vendorId;

  useEffect(() => {
    setVendorId(initialVendorId);
    setPlantId(initialPlantId);
  }, [initialVendorId, initialPlantId]);

  const handleContextSwitch = () => {
    dispatch(authActions.switchVendorContext({ vendorId, plantId }));
    dispatch(resetAppState());
    history.push('/home');
  };

  return (
    <ContextSwitcherWrapper>
      <Textfield
        isRequired
        isInvalid={!vendorId}
        value={vendorId}
        onChange={e => setVendorId(e.target.value)}
        defaultValue={vendorId}
        name="vendorId"
        placeholder="Vendor id"
      />
      <br />
      <Textfield
        isRequired
        isInvalid={!plantId}
        value={plantId}
        onChange={e => setPlantId(e.target.value)}
        defaultValue={plantId}
        name="plantId"
        placeholder="Plant"
      />
      <br />
      <Button appearance="primary" type="submit" onClick={handleContextSwitch} isDisabled={!isFormValid}>
        Switch context
      </Button>
    </ContextSwitcherWrapper>
  );
}

export default VendorContextSwitcher;
