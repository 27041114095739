import { createReducer } from '@reduxjs/toolkit';
import get from 'lodash/get';
import {
  getBillOfMaterialsDetailsFailure,
  getBillOfMaterialsDetailsStart,
  getBillOfMaterialsDetailsSuccess,
  getBillOfMaterialsFileListFailure,
  getBillOfMaterialsFileListStart,
  getBillOfMaterialsFileListSuccess,
  sapFileDownloadFailure,
  sapFileDownloadStart,
  sapFileDownloadSuccess,
  updateCheckedRows,
} from './billOfMaterialsDetailsActions';
import { ReloadButton, showActionFailure } from '../../../shared/utils/Notifications';
import { setButtonLoading } from '../../../shared/utils/loadingButtons';

const initialBomDetailsTree = { header: {}, rows: [] };

const initialState = {
  loading: false,
  bomDetailsTree: initialBomDetailsTree,
  checkedRows: {},
  buttonLoading: [],
  filesLoading: false,
  bomFiles: [],
};

export const isLoading = state => get(state, 'bomDetails.loading');
export const getLoadingButtons = state => get(state, 'bomDetails.buttonLoading', []);
export const isFileLoading = state => get(state, 'bomDetails.filesLoading');
export const getFilesList = state => get(state, 'bomDetails.bomFiles');
export const getCheckedRows = state => {
  return Object.entries(get(state, 'bomDetails.checkedRows', {}))
    .filter(x => x[1])
    .map(([key]) => key);
};
export const getBomTree = state => get(state, 'bomDetails.bomDetailsTree', initialBomDetailsTree);

const billOfMaterialsDetailsReducer = createReducer(initialState, {
  [getBillOfMaterialsDetailsStart](state) {
    state.bomDetailsTree = initialBomDetailsTree;
    state.checkedRows = {};
    state.loading = true;
  },
  [getBillOfMaterialsDetailsSuccess](state, action) {
    state.bomDetailsTree = get(action, 'payload.result', initialBomDetailsTree);
    state.loading = false;
  },
  [getBillOfMaterialsDetailsFailure](state, action) {
    state.bomDetailsTree = initialBomDetailsTree;
    state.checkedRows = {};
    state.loading = false;
    showActionFailure(action, 'Failed to load bill of materials', ReloadButton);
  },
  [getBillOfMaterialsFileListStart](state) {
    state.filesLoading = true;
    state.bomFiles = [];
  },
  [getBillOfMaterialsFileListSuccess](state, action) {
    state.filesLoading = false;
    state.bomFiles = get(action, 'payload.result');
  },
  [getBillOfMaterialsFileListFailure](state, action) {
    state.filesLoading = false;
    showActionFailure(action, 'Failed to load files');
  },
  [sapFileDownloadStart](state, action) {
    const buttonCode = get(action, 'meta.buttonCode');
    setButtonLoading(state, buttonCode, true);
  },
  [sapFileDownloadSuccess](state, action) {
    const buttonCode = get(action, 'meta.buttonCode');
    setButtonLoading(state, buttonCode, false);
  },
  [sapFileDownloadFailure](state, action) {
    const buttonCode = get(action, 'meta.buttonCode');
    setButtonLoading(state, buttonCode, false);
    showActionFailure(action, 'Download action failed');
  },
  [updateCheckedRows](state, action) {
    state.checkedRows = { ...state.checkedRows, ...get(action, 'payload') };
  },
});

export default billOfMaterialsDetailsReducer;
