import Form, { Field } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStorageBins } from './StorageBinsActions';
import SearchIcon from '@atlaskit/icon/glyph/search';
import { LoadingButton } from '@atlaskit/button';
import { isLoading } from './StorageBinsSelectors';

const FormFields = styled.div`
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 28rem;
  margin-bottom: 3rem;
`;

export const GetStorageBinsForm = () => {
  const dispatch = useDispatch();
  const loading = useSelector(state => isLoading(state));

  const onSubmit = useCallback(
    values => {
      dispatch(fetchStorageBins(values.materialNumber));
    },
    [dispatch],
  );

  return (
    <Form onSubmit={onSubmit}>
      {({ formProps, dirty, submitting }) => (
        <form {...formProps}>
          <FormFields>
            <Field name="materialNumber" label="Material number" defaultValue="" isRequired>
              {({ fieldProps }) => (
                <TextField maxLength={18} type="text" width="large" style={{ width: '15rem' }} {...fieldProps} />
              )}
            </Field>
            <LoadingButton
              type="submit"
              iconBefore={<SearchIcon />}
              isDisabled={!dirty || submitting}
              appearance="primary"
              isLoading={loading}
            >
              Get storage bins
            </LoadingButton>
          </FormFields>
        </form>
      )}
    </Form>
  );
};
