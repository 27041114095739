import React, { useEffect } from 'react';
import AbbTitle from '../../../shared/AbbSectionTitle';
import { useDispatch, useSelector } from 'react-redux';
import { isLoading } from './billOfMaterialsReducer';
import PageLoading from '../../../shared/PageLoading';
import { fetchBillOfMaterialsListFromBackend } from './billOfMaterialsActions';
import { BillOfMaterialsListGrid } from './BillOfMaterialsListGrid';
import { DownloadUserGuide } from '../../../shared/DownloadUserGuide';

function BillOfMaterialsPage() {
  const dispatch = useDispatch();
  const loading = useSelector(state => isLoading(state));

  useEffect(() => {
    dispatch(fetchBillOfMaterialsListFromBackend());
  }, [dispatch]);

  return (
    <>
      <>
        <PageLoading visible={loading} />
      </>
      <AbbTitle title="Bill of materials">
        <DownloadUserGuide filename="/user-manuals/user-guide_bill-of-materials.docx" />
      </AbbTitle>
      <BillOfMaterialsListGrid loading={loading} />
    </>
  );
}

export default BillOfMaterialsPage;
