import React, { useCallback, useEffect, useRef, useState } from 'react';
import EmptyGridState from '../../../shared/Grid/EmptyGridState';
import { getGridHeight } from '../../../shared/utils/gridUtils';
import ReactDataGrid from 'react-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { isDownloading, isLoading } from '../materialSearchReducer';
import { SmallerFont } from '../../../shared/Grid/SmallerFont';
import PropTypes from 'prop-types';
import Button from '@atlaskit/button';
import { printPackingLabel } from '../../Manufacturing/WorkQueue/WorkQueueActions';
import { downloadMaterialFile } from '../materialSearchActions';

const MaterialSearchResultGrid = ({ gridRows, onShipNtClick }) => {
  const searchResultGridRef = useRef(undefined);
  const loading = useSelector(state => isLoading(state));
  const downloading = useSelector(state => isDownloading(state));

  const dispatch = useDispatch();
  const [editedRows, setEditedRows] = useState([]);

  const [isEditing, setEditing] = useState(false);

  const MaterialSearchResultColumns = [
    {
      key: 'item',
      name: 'Item',
      width: 200,
    },
    {
      key: 'material',
      name: 'Material',
    },
    {
      key: 'description',
      name: 'Description',
    },
    {
      key: 'typeDesignation',
      name: 'Type designation',
    },
    {
      key: 'printQty',
      name: 'Print qty',
      editable: true,
      width: 100,
      events: {
        onDoubleClick: () => {
          setEditing(true);
        },
      },
    },
    {
      key: 'pic',
      width: 100,
    },
    {
      key: 'shipnt',
      width: 100,
    },
    {
      key: 'print',
      width: 120,
    },
  ];

  useEffect(() => {
    setEditedRows(
      gridRows.map(row => ({
        ...row,
        sumCounter: row.printQty,
      })),
    );
  }, [gridRows]);

  const onDownloadMaterialFile = useCallback(
    materialCode => {
      dispatch(downloadMaterialFile(materialCode));
    },
    [dispatch],
  );

  const onPrintPackingLabel = useCallback(
    (material, printQty) => {
      dispatch(printPackingLabel(material, printQty));
    },
    [dispatch],
  );

  const getActionCellButtons = (column, row) => {
    const isHeader = row.isKitHeader;
    const isFirstRow = row.number === 1;

    if (column.key === 'shipnt') {
      return [
        {
          icon: <Button appearance="link">ShipNT</Button>,
          callback: () => onShipNtClick(row.material),
        },
      ];
    }
    if (column.key === 'pic') {
      return [
        {
          icon: (
            <>
              {!isHeader && (
                <Button appearance="link" isDisabled={!row.material || row.isKitHeader || downloading}>
                  Link to pic
                </Button>
              )}
            </>
          ),
          callback: () => onDownloadMaterialFile(row.material),
        },
      ];
    }

    if (column.key === 'print') {
      return [
        {
          icon: (
            <>
              {isFirstRow && (
                <Button appearance="link" isDisabled={isEditing}>
                  Packing label
                </Button>
              )}
            </>
          ),
          callback: () => {
            onPrintPackingLabel(row.material, row.printQty);
          },
        },
      ];
    }

    return null;
  };

  const onRowsUpdated = useCallback(({ fromRow, toRow, updated }) => {
    setEditedRows(oldRows => {
      const rows = oldRows.slice();
      for (let i = fromRow; i <= toRow; i++) {
        rows[i] = { ...rows[i], ...updated };
      }
      return rows;
    });
    setEditing(false);
  }, []);

  return (
    <SmallerFont>
      <ReactDataGrid
        ref={searchResultGridRef}
        columns={MaterialSearchResultColumns}
        rowGetter={i => editedRows[i]}
        emptyRowsView={() => EmptyGridState(loading)}
        rowsCount={editedRows.length}
        getCellActions={getActionCellButtons}
        minColumnWidth={20}
        minHeight={getGridHeight(gridRows.length)}
        onGridRowsUpdated={onRowsUpdated}
        enableCellSelect={true}
        enableRowSelect={null}
      />
    </SmallerFont>
  );
};

MaterialSearchResultGrid.propTypes = {
  gridRows: PropTypes.array.isRequired,
  onShipNtClick: PropTypes.func.isRequired,
};

export default MaterialSearchResultGrid;
