import DynamicTable from '@atlaskit/dynamic-table';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isLoadingReport, showPasswordDialog } from './ReportsSelectors';
import { downloadReport } from './ReportsActions';
import { LoadingButton } from '@atlaskit/button';
import LockFilledIcon from '@atlaskit/icon/glyph/lock-filled';
import PropTypes from 'prop-types';
import { PasswordDialog } from './PasswordDialog';
import styled from 'styled-components';

const TableHeader = styled.span`
  font-weight: bold;
  font-size: larger;
  color: black;
`;

const head = {
  cells: [
    {
      key: 'webUserGroup',
      content: <TableHeader>USERGROUP</TableHeader>,
    },
    {
      key: 'webQuery',
      content: <TableHeader>QUERY</TableHeader>,
    },
    {
      key: 'webQueryName',
      content: <TableHeader>QUERY content</TableHeader>,
    },
    {
      key: 'webVariant',
      content: <TableHeader>VARIANT</TableHeader>,
    },
  ],
};

const RunReport = ({ report, children }) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(state => isLoadingReport(state, report.id));

  const onClick = useCallback(() => {
    dispatch(downloadReport(report));
  }, [report, dispatch]);

  return (
    <LoadingButton
      appearance="primary"
      onClick={onClick}
      isLoading={isLoading}
      iconBefore={report.requirePassword ? <LockFilledIcon /> : undefined}
    >
      {children}
    </LoadingButton>
  );
};

export const ReportsTable = ({ reports }) => {
  const isPasswordDialogOpen = useSelector(state => showPasswordDialog(state));

  const content = reports.map(report => ({
    cells: [
      {
        key: 'webUserGroup',
        content: report.webUserGroup,
      },
      {
        key: 'webQuery',
        content: report.webQuery,
      },
      {
        key: 'webQueryContent',
        content: report.webQueryName,
      },
      {
        key: 'webVariant',
        content: report.webVariant,
      },
      {
        key: 'actions',
        content: <RunReport report={report}>Generate</RunReport>,
      },
    ],
  }));
  return (
    <>
      <PasswordDialog isOpen={isPasswordDialogOpen} />
      <DynamicTable head={head} rows={content} isFixedSize />
    </>
  );
};

RunReport.propTypes = {
  children: PropTypes.node,
  report: PropTypes.object.isRequired,
};

ReportsTable.propTypes = {
  reports: PropTypes.array.isRequired,
};
