import { ButtonGroup, LoadingButton } from '@atlaskit/button';
import React from 'react';
import Get from 'lodash/get';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getButtons, getLoadingButtons, getSelectedRowIds } from './WorkQueueSelectors';
import {
  confirmWorkOrder,
  printRatingPlate,
  printWorkOrder,
  printWorkOrderDocuments,
  traceBySerialNumber,
  triggerSapPrintout,
  updateStatusForSelectedRows,
} from './WorkQueueActions';
import { AccessTracing } from '../../../shared/Auth/permissions';
import * as authSelectors from '../../../shared/Auth/authSelectors';
import { PermissibleRender } from '@brainhubeu/react-permissible';
import { triggerBomFilesDownloadForSelectedRows } from 'app/views/BillOfMaterials/BomDetails/billOfMaterialsDetailsActions';

// Button properties if no button specific properies have been defined
const genericButtonProperties = () => ({
  isDisabled: true,
});

const buttonSpecificProperties = {
  trace: ({ dispatch, history, selectedRowsCount }) => ({
    isDisabled: selectedRowsCount !== 1,
    onClick: () => {
      dispatch(traceBySerialNumber(history));
    },
    requiredPermissions: [AccessTracing],
  }),
  status: ({ dispatch, selectedRowsCount }) => ({
    isDisabled: selectedRowsCount === 0,
    onClick: () => {
      dispatch(updateStatusForSelectedRows());
    },
  }),
  printdocuments: ({ dispatch, selectedRowsCount }) => ({
    isDisabled: selectedRowsCount !== 1,
    onClick: () => {
      dispatch(printWorkOrderDocuments());
    },
  }),
  alldocs: ({ dispatch, selectedRowsCount }) => ({
    isDisabled: selectedRowsCount !== 1,
    onClick: () => {
      dispatch(triggerBomFilesDownloadForSelectedRows());
    },
  }),
  // Start work
  relord: ({ dispatch, selectedRowsCount, button }) => ({
    isDisabled: selectedRowsCount === 0,
    onClick: () => {
      dispatch(triggerSapPrintout(button.code, button.description));
    },
  }),
  // Confirm order
  confsum: ({ dispatch, selectedRowsCount }) => ({
    isDisabled: selectedRowsCount === 0,
    onClick: () => {
      dispatch(confirmWorkOrder());
    },
  }),
  www_printwo: ({ dispatch, selectedRowsCount }) => ({
    isDisabled: selectedRowsCount === 0,
    onClick: () => {
      dispatch(printWorkOrder('WWW_PRINTWO'));
    },
  }),
  // Rating plate
  www_ratingp: ({ dispatch, selectedRowsCount }) => ({
    isDisabled: selectedRowsCount === 0,
    onClick: () => {
      dispatch(printRatingPlate());
    },
  }),
};

const getButtonProperties = buttonCode => {
  return Get(buttonSpecificProperties, buttonCode.toLowerCase(), genericButtonProperties);
};

const shouldRenderButton = (workQueueName, buttonCode) => {
  const relButtons = ['CONFSUM', 'STATUS', 'WWW_PRINTWO'];
  const crtdButton = ['WWW_PRINTWO', 'RELORD'];

  if (workQueueName.includes('REL')) {
    return relButtons.includes(buttonCode);
  }
  if (workQueueName.includes('CRTD')) {
    return crtdButton.includes(buttonCode);
  }
  return true;
};

export const WorkQueueButtons = ({ workQueueName }) => {
  const dispatch = useDispatch();

  const userPermissions = useSelector(state => authSelectors.getUserPermissions(state));
  const buttons = useSelector(state => getButtons(state));
  const selectedRowIds = useSelector(state => getSelectedRowIds(state));
  const loadingButtons = useSelector(state => getLoadingButtons(state));

  const history = useHistory();
  return (
    <ButtonGroup appearance="primary">
      {buttons.map(button => {
        let buttonProps = getButtonProperties(button.code)({
          dispatch,
          history,
          selectedRowsCount: selectedRowIds.length,
          button,
        });
        const isLoading = loadingButtons.includes(button.code.toLowerCase());
        buttonProps = {
          ...buttonProps,
          isLoading,
          isDisabled: buttonProps.isDisabled || isLoading,
        };

        const requiredPermissions = buttonProps.requiredPermissions ? buttonProps.requiredPermissions : userPermissions;

        return (
          <>
            {shouldRenderButton(workQueueName, button.code) && (
              <PermissibleRender
                key={button.id}
                userPermissions={userPermissions}
                requiredPermissions={requiredPermissions}
              >
                <LoadingButton {...buttonProps}>{button.description}</LoadingButton>
              </PermissibleRender>
            )}
          </>
        );
      })}
    </ButtonGroup>
  );
};

WorkQueueButtons.propTypes = {
  workQueueName: PropTypes.string.isRequired,
};
