import PropTypes from 'prop-types';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getBomTree, isLoading } from '../billOfMaterialsDetailsReducer';
import { ExportDataToExcel } from '../../../../shared/ExportToExcel';
import { columnsMaterial } from '../billOfMaterialsDetailsPage';
import forEachDeep from 'deepdash-es/forEachDeep';
import pickBy from 'lodash/pickBy';
import get from 'lodash/get';

const pickSpecificKeys = (object, includedKeys) => pickBy(object, (value, key) => includedKeys.includes(key));

export const extractDataAsFlatArray = (bomData, headerKeys) => {
  const flatArray = [];
  const performForEachDeepObject = value => {
    const pickedObject = pickSpecificKeys(value, headerKeys);
    flatArray.push(pickedObject);
  };

  forEachDeep(bomData, performForEachDeepObject, {
    childrenPath: 'children',
  });
  return flatArray;
};

export const BomActionDropdown = ({ bomData }) => {
  const loading = useSelector(state => isLoading(state));
  const materialCode = useSelector(state => get(getBomTree(state), 'header.materialCode'));

  const exportDataProvider = useCallback(() => {
    const headers = columnsMaterial
      .filter(column => column.name)
      .map(column => ({ key: column.key, label: column.name }));

    const headerKeys = headers.map(header => header.key);

    const dataToExport = extractDataAsFlatArray(bomData, headerKeys);

    return {
      headers: headers,
      data: dataToExport,
    };
  }, [bomData]);

  return (
    <DropdownMenu
      trigger="Actions"
      triggerType="button"
      triggerButtonProps={{
        appearance: 'default',
        isDisabled: loading,
      }}
    >
      <DropdownItemGroup>
        <DropdownItem key="export-excel">
          <ExportDataToExcel
            exportDataProvider={exportDataProvider}
            filename={`${materialCode}`}
            name="Bill of Materials"
          />
        </DropdownItem>
      </DropdownItemGroup>
    </DropdownMenu>
  );
};

BomActionDropdown.propTypes = {
  bomData: PropTypes.array.isRequired,
};
