import React from 'react';
import AbbTitle from '../../shared/AbbSectionTitle';
import { DownloadUserGuide } from '../../shared/DownloadUserGuide';

function Home() {
  return (
    <>
      <AbbTitle title="Home Page">
        <DownloadUserGuide filename="/user-manuals/user-guide_main-page.docx" />
      </AbbTitle>
      <p>
        <b>Manufacturing Services Portal</b> (MSP) is a web portal designed for ABB’s business partners. The purpose of
        this portal is to provide partners with required transactions and information needed in manufacturing.
      </p>
      <p>
        <b>Production section</b> includes those transactions that are needed for manufacturing products. This is where
        you can find printable part lists and drawings. You can also view the work queue and trace components, print
        rating plates, test reports and so on.
      </p>

      <p>
        <b>Materials Management</b> section is for maintaining and keeping track of material flow inside the production
        chain. For example transaction used in storage bin update can be found here.
      </p>

      <p>
        <b>Reports</b> provide information needed in production. The purpose of these reports is to generate useful data
        that can be used in managing the production operations. Section includes reporting tool used for generating
        different real-time reports, such as stock reports.
      </p>
    </>
  );
}

export default Home;
