import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PageLoading from '../../../shared/PageLoading';
import AbbTitle from '../../../shared/AbbSectionTitle';
import { RePrintRatingPlateDialog } from '../RePrintDialog/RePrintRatingPlateDialog';
import { getTracingData, printRatingPlate } from './TracingActions';
import { TracingHeaderGrid } from './TracingHeaderGrid';
import { TracingComponentsGrid } from './TracingComponentsGrid';
import styled from 'styled-components';
import { getHeaderAsRows, getShowReprintDialog, getTraceableComponents, isLoading } from './TracingSelectors';
import { TracingButtons } from './TracingButtons';
import { DownloadUserGuide } from '../../../shared/DownloadUserGuide';

const ButtonContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const TracingPage = () => {
  const { serialNumber } = useParams();
  const dispatch = useDispatch();

  const loading = useSelector(state => isLoading(state));
  const headerRows = useSelector(state => getHeaderAsRows(state));
  const components = useSelector(state => getTraceableComponents(state));
  const showReprintDialog = useSelector(state => getShowReprintDialog(state));

  useEffect(() => {
    dispatch(getTracingData(serialNumber));
  }, [dispatch, serialNumber]);

  const printRatingPlateCallback = useCallback(() => {
    dispatch(printRatingPlate());
  }, [dispatch]);

  return (
    <>
      <PageLoading visible={loading} />

      <AbbTitle title="Trace by serial number">
        <DownloadUserGuide filename="/user-manuals/user-guide_tracing.docx" />
      </AbbTitle>
      <RePrintRatingPlateDialog
        visible={showReprintDialog}
        yesButtonCallback={printRatingPlateCallback}
        message="Label already printed. Print another one?"
      />
      <ButtonContainer>
        <TracingButtons />
      </ButtonContainer>
      <TracingHeaderGrid rows={headerRows} loading={loading} />
      <TracingComponentsGrid components={components} loading={loading} />
    </>
  );
};
