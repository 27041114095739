import PropTypes from 'prop-types';
import Form, { CheckboxField, Field, FormFooter, FormSection } from '@atlaskit/form';
import { Column, Row } from 'simple-flexbox';
import TextField from '@atlaskit/textfield';
import { Checkbox } from '@atlaskit/checkbox';
import Select from '@atlaskit/select';
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import React, { useCallback, useState } from 'react';
import { getDocumentKinds, isLoading } from './DocumentSearchSelectors';
import { useSelector } from 'react-redux';

const fileExtensions = ['DOC', 'DWG', 'PDF', 'XSL', 'ZIP'].map(ext => ({
  value: ext.toLowerCase(),
  label: ext,
}));

export const DocumentSearchForm = ({ onSubmit }) => {
  const documentKinds = useSelector(state => getDocumentKinds(state));
  const loading = useSelector(state => isLoading(state));

  const [advancedSearch, setAdvancedSearch] = useState(false);

  const toggleAdvancedSearch = useCallback(() => {
    setAdvancedSearch(oldValue => !oldValue);
  }, []);

  return (
    <Form onSubmit={values => onSubmit({ ...values }, advancedSearch)}>
      {({ formProps, dirty, submitting }) => (
        <form {...formProps} style={{ width: '100%' }}>
          <FormSection>
            <Row>
              <Column flexGrow={1}>
                <Field name="materialNumber" label="Material number" defaultValue="">
                  {({ fieldProps }) => <TextField maxLength={18} type="text" width="medium" {...fieldProps} />}
                </Field>
              </Column>
              <Column flexGrow={1}>
                <Field name="documentNumber" label="Document number" defaultValue="">
                  {({ fieldProps }) => <TextField maxLength={18} type="text" width="medium" {...fieldProps} />}
                </Field>
              </Column>

              <Column flexGrow={1}>
                <Field name="changeNumber" label="Change number" defaultValue="">
                  {({ fieldProps }) => <TextField maxLength={18} type="text" width="medium" {...fieldProps} />}
                </Field>
              </Column>
            </Row>
            <Row>
              <Column flexGrow={1}>
                <Field name="lastChangedBy" label="Last changed by" defaultValue="">
                  {({ fieldProps }) => (
                    <TextField maxLength={18} type="text" width="medium" placeholder="Username" {...fieldProps} />
                  )}
                </Field>
              </Column>
              <Column flexGrow={1}>
                <Field name="createdBy" label="Created by" defaultValue="">
                  {({ fieldProps }) => (
                    <TextField maxLength={18} type="text" width="medium" placeholder="Username" {...fieldProps} />
                  )}
                </Field>
              </Column>
              <Column flexGrow={1}>
                <Field name="createdOn" label="Create on" defaultValue="">
                  {({ fieldProps }) => (
                    <TextField maxLength={18} type="text" width="medium" placeholder="YYYY-MM-DD" {...fieldProps} />
                  )}
                </Field>
              </Column>
            </Row>
          </FormSection>
          <FormSection title="Filters">
            <Row>
              <Column>
                <CheckboxField name="showLatestRevision" defaultIsChecked>
                  {({ fieldProps }) => <Checkbox {...fieldProps} label="Latest revision" />}
                </CheckboxField>
              </Column>
            </Row>
            <Row>
              <Column flexGrow={1}>
                <Field name="lastChangedOn" label="Last changed on" defaultValue="">
                  {({ fieldProps }) => (
                    <TextField maxLength={18} type="text" width="medium" placeholder="YYYY-MM-DD" {...fieldProps} />
                  )}
                </Field>
              </Column>
              <Column flexGrow={1} style={{ paddingLeft: '5rem' }}>
                <Field name="fileExtension" label="File extension" defaultValue={[]}>
                  {({ fieldProps: { id, ...rest } }) => (
                    <>
                      <Select
                        inputId={id}
                        {...rest}
                        options={fileExtensions}
                        isClearable
                        styles={{
                          container: base => ({ ...base, width: '240px' }),
                        }}
                      />
                    </>
                  )}
                </Field>
              </Column>
              <Column flexGrow={1}>
                <Field name="documentKind" label="Document kind" defaultValue={[]}>
                  {({ fieldProps: { id, ...rest } }) => (
                    <>
                      <Select
                        inputId={id}
                        {...rest}
                        options={documentKinds}
                        isClearable
                        styles={{
                          container: base => ({ ...base, width: '240px' }),
                        }}
                      />
                    </>
                  )}
                </Field>
              </Column>
            </Row>
          </FormSection>
          {advancedSearch && (
            <FormSection title="Advanced search parameters">
              <Row>
                <Column flexGrow={1}>
                  <Field name="description" label="Description" defaultValue="">
                    {({ fieldProps }) => <TextField maxLength={25} type="text" width="medium" {...fieldProps} />}
                  </Field>
                </Column>
                <Column flexGrow={1}>
                  <Field name="typeCode" label="Type code" defaultValue="">
                    {({ fieldProps }) => (
                      <TextField maxLength={25} type="text" width="medium" placeholder="DMS" {...fieldProps} />
                    )}
                  </Field>
                </Column>
                <Column flexGrow={1}>
                  <Field name="filename" label="File name" defaultValue="">
                    {({ fieldProps }) => <TextField maxLength={25} type="text" width="medium" {...fieldProps} />}
                  </Field>
                </Column>
              </Row>
            </FormSection>
          )}
          <FormFooter>
            <ButtonGroup>
              <Button appearance="default" onClick={toggleAdvancedSearch}>
                {advancedSearch ? 'Hide advanced search' : 'Show advanced search'}
              </Button>
              <LoadingButton type="submit" appearance="primary" isDisabled={!dirty || submitting} isLoading={loading}>
                Search
              </LoadingButton>
            </ButtonGroup>
          </FormFooter>
        </form>
      )}
    </Form>
  );
};

DocumentSearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
