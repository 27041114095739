import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import EmptyGridState from '../../shared/Grid/EmptyGridState';
import { useSelector } from 'react-redux';
import { getResults } from './DocumentSearchSelectors';
import { DownloadFilesModalDialog } from './DownloadFilesModalDialog';
import DownloadIcon from '@atlaskit/icon/glyph/download';
import { SmallerFont } from '../../shared/Grid/SmallerFont';

const FileFormatter = ({ value }) => (
  <span>
    {value.length} {value.length === 1 ? 'file' : 'files'}
  </span>
);

const columns = [
  {
    key: 'documentNumber',
    name: 'Document number',
  },
  {
    key: 'documentRevision',
    name: 'Revision',
  },
  {
    key: 'documentDescription',
    name: 'Document description',
  },
  {
    key: 'files',
    name: 'Files',
    formatter: FileFormatter,
  },
  {
    key: 'materialNumber',
    name: 'Material numbers',
  },
  {
    key: 'lastChanged',
    name: 'Last changed on',
  },
  {
    key: 'changedBy',
    name: 'Last changed by',
  },
  {
    key: 'documentKind',
    name: 'Document kinds',
  },
];

export const DocumentSearchResults = () => {
  const results = useSelector(state => getResults(state));
  const [downloadFiles, setDownloadFiles] = useState([]);
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);

  const onCloseDownloadDialog = useCallback(() => {
    setDownloadDialogOpen(false);
  }, []);

  const getCellActions = (column, row) => {
    if (column.key === 'files') {
      return [
        {
          icon: <DownloadIcon />,
          callback: () => {
            setDownloadFiles(row.files);
            setDownloadDialogOpen(true);
          },
        },
      ];
    }
    return null;
  };

  return (
    <>
      <DownloadFilesModalDialog isOpen={downloadDialogOpen} files={downloadFiles} onClose={onCloseDownloadDialog} />
      <SmallerFont>
        <ReactDataGrid
          columns={columns}
          rowGetter={i => results[i]}
          rowsCount={results.length}
          emptyRowsView={() => EmptyGridState(false, 'No results')}
          getCellActions={getCellActions}
          enableRowSelect={null}
        />
      </SmallerFont>
    </>
  );
};

FileFormatter.propTypes = {
  value: PropTypes.object.isRequired,
};
