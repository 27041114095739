import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getLoadingButtons } from '../billOfMaterialsDetailsReducer';

const RenderDropdownLevels = ({
  numberOfLevels,
  clickEventType,
  dropDownTrigger,
  onOptionClick,
  triggerType,
  isDisabled,
}) => {
  const loadingButtons = useSelector(state => getLoadingButtons(state));
  const handleOptionClick = useCallback(onOptionClick, [onOptionClick]);

  return (
    <DropdownMenu
      trigger={dropDownTrigger}
      triggerButtonProps={{
        appearance: 'default',
        isDisabled: isDisabled,
      }}
      triggerType={triggerType}
      isMenuFixed
    >
      <DropdownItemGroup isLoading={loadingButtons.includes(clickEventType.toLowerCase())} id={clickEventType}>
        {Array(numberOfLevels)
          .fill()
          .map((x, i) => {
            return (
              <DropdownItem
                key={`${clickEventType}-${i}`}
                onClick={() => handleOptionClick({ type: clickEventType, level: i + 1 })}
              >
                {`${i + 1} level`}
              </DropdownItem>
            );
          })}
      </DropdownItemGroup>
    </DropdownMenu>
  );
};

RenderDropdownLevels.propTypes = {
  numberOfLevels: PropTypes.number.isRequired,
  clickEventType: PropTypes.string.isRequired,
  dropDownTrigger: PropTypes.any.isRequired,
  onOptionClick: PropTypes.func,
  triggerType: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default RenderDropdownLevels;
