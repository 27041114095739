import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import TextField from '@atlaskit/textfield';

import Button from '@atlaskit/button';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { updateSerialNumberFromBarcode } from './TracingActions';
import Form, { Field } from '@atlaskit/form';
import { isLoading } from './TracingSelectors';

const FormFieldWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 1rem;
  justify-content: space-between;
  width: 30rem;
`;

export const UpdateSerialNumber = ({ onUsernameUpdated }) => {
  const dispatch = useDispatch();

  const loading = useSelector(state => isLoading(state));

  const onSubmit = useCallback(
    values => {
      dispatch(updateSerialNumberFromBarcode(values.barcode, values.username));
    },
    [dispatch],
  );

  const captureUsername = useCallback(
    value => {
      onUsernameUpdated && onUsernameUpdated(value);
    },
    [onUsernameUpdated],
  );

  return (
    <Form onSubmit={onSubmit}>
      {({ formProps, dirty, submitting }) => (
        <form {...formProps}>
          <FormFieldWrapper>
            <Field name="barcode" defaultValue="" label="Barcode" isRequired>
              {({ fieldProps, error }) => (
                <TextField maxLength={50} width="large" placeholder="Barcode" {...fieldProps} isInvalid={error} />
              )}
            </Field>
            <Field name="username" defaultValue="" label="Username" isRequired validate={captureUsername}>
              {({ fieldProps, error }) => (
                <TextField maxLength={12} width="medium" placeholder="Username" {...fieldProps} isInvalid={error} />
              )}
            </Field>
            <Button type="submit" appearance="primary" isDisabled={!dirty || submitting || loading}>
              Update
            </Button>
          </FormFieldWrapper>
        </form>
      )}
    </Form>
  );
};

UpdateSerialNumber.propTypes = {
  onUsernameUpdated: PropTypes.func,
};
