import PropTypes from 'prop-types';
import styled from 'styled-components';
import QuestionCircleIcon from '@atlaskit/icon/glyph/question-circle';
import React from 'react';

const UserGuideContainer = styled.span`
  padding-left: 2rem;
  text-align: center;
  line-height: 45px;
  position: relative;
  top: -1rem;
`;

export const DownloadUserGuide = ({ filename }) => {
  return (
    <UserGuideContainer>
      <a href={process.env.PUBLIC_URL + filename}>
        <QuestionCircleIcon label="Download user guide for this screen" />
      </a>
    </UserGuideContainer>
  );
};

DownloadUserGuide.propTypes = {
  filename: PropTypes.string.isRequired,
};
