import { createReducer } from '@reduxjs/toolkit';
import {
  fetchStorageBinsFailure,
  fetchStorageBinsStart,
  fetchStorageBinsSuccess,
  saveStorageBinsFailure,
  saveStorageBinsStart,
  saveStorageBinsSuccess,
} from './StorageBinsActions';
import { getInfoMessageFromAction, showActionFailure, showSuccessNotification } from '../../shared/utils/Notifications';
import Get from 'lodash/get';
import { getBillOfMaterialsFileListFailure } from '../BillOfMaterials/BomDetails/billOfMaterialsDetailsActions';

const initialState = {
  loading: false,
  saving: false,
  storageBin: {},
};

export const storageBindsReducer = createReducer(initialState, {
  [fetchStorageBinsStart](state) {
    state.loading = true;
    state.storageBin = {};
  },
  [fetchStorageBinsSuccess](state, action) {
    state.loading = false;
    const materialNumber = Get(action, 'meta.materialNumber');

    state.storageBin = {
      ...Get(action, 'payload.result', {}),
      materialNumber,
    };
  },
  [fetchStorageBinsFailure](state, action) {
    state.loading = false;
    showActionFailure(action, 'Failed to fetch storage bins');
  },
  [saveStorageBinsStart](state) {
    state.loading = true;
    state.saving = true;
  },
  [saveStorageBinsSuccess](state, action) {
    state.loading = false;
    state.saving = false;
    showSuccessNotification(getInfoMessageFromAction(action, 'Storage bin saved.'));
  },
  [saveStorageBinsFailure](state, action) {
    state.loading = false;
    state.saving = false;
    showActionFailure(action, 'Failed to save storage bins');
  },
  [getBillOfMaterialsFileListFailure](state, action) {
    showActionFailure(action, 'Failed to load material image');
  },
});
