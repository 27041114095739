import React, { useCallback, useEffect } from 'react';
import Page, { Grid } from '@atlaskit/page';
import AbbTitle from '../../shared/AbbSectionTitle';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocumentKinds, search } from './DocumentSearchActions';
import PageLoading from '../../shared/PageLoading';
import { isLoading } from './DocumentSearchSelectors';
import { DocumentSearchForm } from './DocumentSearchForm';
import { DocumentSearchResults } from './DocumentSearchResults';
import { DownloadUserGuide } from '../../shared/DownloadUserGuide';

export const DocumentSearchPage = () => {
  const dispatch = useDispatch();
  const loading = useSelector(state => isLoading(state));

  useEffect(() => {
    dispatch(fetchDocumentKinds());
  }, [dispatch]);

  const onSubmit = useCallback(
    (values, advancedSearch) => {
      dispatch(search(values, advancedSearch));
    },
    [dispatch],
  );

  return (
    <>
      <>
        <PageLoading visible={loading} />
      </>

      <Page>
        <AbbTitle title="Document search">
          <DownloadUserGuide filename="/user-manuals/user-guide_document-search.docx" />
        </AbbTitle>
        <Grid layout="fixed">
          <DocumentSearchForm onSubmit={onSubmit} />
        </Grid>
        <Grid layout="fluid">
          <h2>Results</h2>
          <DocumentSearchResults />
        </Grid>
      </Page>
    </>
  );
};
