import { buildApiUrl } from '../../shared/utils/ApiUtils';
import { RSAA } from 'redux-api-middleware';
import { createAction } from '@reduxjs/toolkit';
import { generateFilename, handleFileDownload } from '../../shared/utils/FileDownloadUtils';

export const getMaterialSearchResultsStart = createAction('materialSearch/getMaterialSearchResultsStart');
export const getMaterialSearchResultsSuccess = createAction('materialSearch/getMaterialSearchResultsSuccess');
export const getMaterialSearchResultsFailure = createAction('materialSearch/getMaterialSearchResultsFailure');

export const downloadMaterialFileStart = createAction('printing/downloadMaterialFileStart');
export const downloadMaterialFileSuccess = createAction('printing/downloadMaterialFileSuccess');
export const downloadMaterialFileFailure = createAction('printing/downloadMaterialFileFailure');

export const searchMaterials = query => dispatch => {
  const url = buildApiUrl(`bom/searchMaterial`);

  return dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(query),
      types: [
        getMaterialSearchResultsStart.type,
        getMaterialSearchResultsSuccess.type,
        getMaterialSearchResultsFailure.type,
      ],
    },
  });
};

export const downloadMaterialFile = materialCode => dispatch => {
  const url = buildApiUrl('download/downloadMaterialFile');

  const data = {
    materialCode,
  };

  const filenameGenerator = contentType => generateFilename(contentType, materialCode);

  dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
      fetch: handleFileDownload(filenameGenerator),
      types: [downloadMaterialFileStart.type, downloadMaterialFileSuccess.type, downloadMaterialFileFailure.type],
    },
  });
};
