/* eslint-disable react/prop-types */
import React, { useRef, useCallback, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import { useSelector, useDispatch } from 'react-redux';
import { getBomRows, getBomRowsFiltered } from './billOfMaterialsReducer';
import { addToEachColumn, GridColumnFormatter } from '../../../shared/utils/gridUtils';
import EmptyGridState from '../../../shared/Grid/EmptyGridState';
import PropTypes from 'prop-types';
import { MaterialCodeLinkFormatter } from '../../Manufacturing/WorkQueue/DataGridFormatters';
import { SmallerFont } from '../../../shared/Grid/SmallerFont';
import SearchBar from '../../../shared/SearchBar';
import { setFilteredItems } from './billOfMaterialsActions';
import styled from 'styled-components';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { ExportDataToExcel } from '../../../shared/ExportToExcel';
import dayjs from 'dayjs';
import { getVendorId } from '../../../shared/Auth/authSelectors';
import Head from 'lodash/head';
import Difference from 'lodash/difference';
import { MaxNumberOfBomLevels } from '../../../shared/utils/contstans';
import { PrintBomButtonCode } from '../BomDetails/componenets/BillOfMaterialsDetailsActionButtons';
import { BomTypeMaterial } from '../../../shared/utils/contstans';
import { downloadBomFiles } from '../BomDetails/billOfMaterialsDetailsActions';
import { getConfiguration } from 'app/shared/utils/configuration';
import { LoadingButton } from '@atlaskit/button';
import { getLoadingButtons } from '../BomDetails/billOfMaterialsDetailsReducer';
import OverviewIcon from '@atlaskit/icon/glyph/overview';

const HeaderContainer = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  span {
    margin-left: auto;
  }
`;

const CustomHeaderDataGrid = styled.div`
  .react-grid-HeaderCell-sortable {
    display: flex;
    align-items: center;
  }
`;

const columns = [
  {
    key: 'materialCode',
    name: 'Material code',
    formatter: MaterialCodeLinkFormatter,
  },
  {
    key: 'description',
    name: 'Description',
  },
  {
    key: 'technicalDescription',
    name: 'Technical description',
  },
  {
    key: 'technicalData',
    name: 'Technical Data',
  },
  {
    key: 'frameSize',
    name: 'Frame',
  },
  {
    key: 'revision',
    name: 'Revision',
  },
  {
    key: 'ean',
    name: 'EAN',
  },
  {
    key: 'emsProductId',
    name: 'EMS Product Id',
  },
  {
    key: 'revisionDate',
    name: 'Revision date',
  },
  {
    key: 'contractCreated',
    name: 'Contract created',
  },
  {
    key: 'bomChanged',
    name: 'BOM changed',
  },
];

const staticColumnWidths = {
  print: 130,
};

const SearchBarWrapper = styled.div`
  width: 350px;
  margin-bottom: 16px;
`;

const Spacer = styled.div`
  flex-grow: 10;
`;

const BomActionBar = styled.div`
  display: flex;
  gap: 10px;
`;

export const BillOfMaterialsListGrid = ({ loading }) => {
  const data = useSelector(state => getBomRows(state));
  const gridRows = useSelector(state => getBomRowsFiltered(state));
  const columnWidthFormatter = new GridColumnFormatter(staticColumnWidths);
  const dataGridRef = useRef(undefined);
  const vendorId = useSelector(state => getVendorId(state));
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();
  const loadingButtons = useSelector(state => getLoadingButtons(state));
  const [initialRows, setInitialRows] = useState([]);

  let gridColumns = addToEachColumn(columns, { resizable: true });
  gridColumns = addToEachColumn(gridColumns, {
    sortable: true,
    headerRenderer: React.createElement(props => {
      return (
        <HeaderContainer>
          {props.column.name} <OverviewIcon />
        </HeaderContainer>
      );
    }, {}),
  });

  const fuseConfig = {
    keys: gridColumns.map(column => column.key),
    minMatchCharLength: 1,
    threshold: 0,
    tokenize: true,
  };

  const onRowsSelectedSingle = rows => {
    const selectedIndexes = Head(rows.map(row => row.rowIdx));

    setSelectedRows([selectedIndexes]);
  };

  const handleBomPrint = () => {
    const materialCode = gridRows[Head(selectedRows)].materialCode;
    dispatch(downloadBomFiles(PrintBomButtonCode, materialCode, null, BomTypeMaterial, MaxNumberOfBomLevels));
  };

  const onRowsDeselected = rows => {
    const deselectedIndexes = rows.map(row => row.rowIdx);

    setSelectedRows(Difference(selectedRows, deselectedIndexes));
  };

  const exportDataProvider = useCallback(() => {
    const headers = gridColumns.filter(column => column.name).map(column => ({ key: column.key, label: column.name }));

    return {
      headers: headers,
      data: gridRows,
    };
  }, [gridRows]);

  const handleBomListSort = (initialRows, sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      if (sortDirection === 'ASC') {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === 'DESC') {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };
    if (sortDirection === 'ASC' && initialRows.length != gridRows.length) {
      setInitialRows(gridRows);
    }
    sortDirection === 'NONE'
      ? dispatch(setFilteredItems(initialRows))
      : dispatch(setFilteredItems([...gridRows].sort(comparer)));
  };

  const PrintBomAndExcelDownloadButtonsEnabled = getConfiguration('features.bomListPrintButtons', false);

  return (
    <>
      <BomActionBar>
        <SearchBarWrapper>
          <SearchBar isDisabled={loading} data={data} dispatchOnFiltered={setFilteredItems} fuseConfig={fuseConfig} />
        </SearchBarWrapper>
        {PrintBomAndExcelDownloadButtonsEnabled ? (
          <>
            <Spacer />
            <LoadingButton
              appearance="primary"
              isLoading={loadingButtons.includes(PrintBomButtonCode.toLowerCase())}
              isDisabled={!selectedRows.length || loading}
              onClick={handleBomPrint}
            >
              Print BOM
            </LoadingButton>
            <DropdownMenu
              trigger="Actions"
              triggerType="button"
              triggerButtonProps={{
                appearance: 'default',
                isDisabled: loading,
              }}
            >
              <DropdownItemGroup>
                <DropdownItem key="export-excel">
                  <ExportDataToExcel
                    exportDataProvider={exportDataProvider}
                    filename={`bom-list-${vendorId}-${dayjs().format('YYYY-MM-DD_HH-mm')}`}
                    name="Bill of Materials List"
                  />
                </DropdownItem>
              </DropdownItemGroup>
            </DropdownMenu>
          </>
        ) : null}
      </BomActionBar>
      <SmallerFont>
        <CustomHeaderDataGrid>
          <ReactDataGrid
            ref={dataGridRef}
            columns={columnWidthFormatter.formatColumnsWidth(gridRows, gridColumns, dataGridRef, 20)}
            rowGetter={i => gridRows[i]}
            emptyRowsView={() => EmptyGridState(loading)}
            enableCellAutoFocus={false}
            enableDragAndDrop={true}
            rowsCount={gridRows.length}
            minColumnWidth={20}
            minHeight={window.innerHeight - 290}
            enableCellSelect={true}
            enableRowSelect={null}
            rowSelection={{
              showCheckbox: true,
              enableShiftSelect: false,
              onRowsSelected: onRowsSelectedSingle,
              onRowsDeselected: onRowsDeselected,
              selectBy: {
                indexes: selectedRows,
              },
            }}
            onGridSort={(sortColumn, sortDirection) => {
              handleBomListSort(initialRows, sortColumn, sortDirection);
            }}
            sortColumn={'materialCode'}
            sortDirection={'NONE'}
          />
        </CustomHeaderDataGrid>
      </SmallerFont>
    </>
  );
};

BillOfMaterialsListGrid.propTypes = {
  loading: PropTypes.bool.isRequired,
};
