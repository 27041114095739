import { getConfiguration } from 'app/shared/utils/configuration';
import Get from 'lodash/get';
import { hasUserPermission } from '../../../shared/Auth/authSelectors';
import { AllowWorkQueueLabelPrint } from '../../../shared/Auth/permissions';

const updateStatusButton = {
  id: 'STATUS',
  description: 'Update status',
  code: 'STATUS',
};

const printDocumentButton = {
  id: 'PRINT_DOCUMENTS',
  description: 'Print documents',
  code: 'printDocuments',
};

const downloadBomButton = {
  id: 'DOWNLOAD_BOM',
  description: 'Download BOM files',
  code: 'alldocs',
};

const printPackageLabelHeader = {
  key: 'print',
  name: 'Print',
};

export const getButtons = state => {
  const buttonsFromSap = Get(state, 'workQueue.buttons', []);
  const workQueue = Get(state, 'workQueue', {});

  const hasStatusColumn =
    getHeaders(state).filter(
      header => header.key.toUpperCase() === 'STATUS' || header.key.toUpperCase() === 'STATUSLONG',
    ).length === 1;
  const shouldShowPrintDocuments =
    workQueue &&
    workQueue.selectedVariant &&
    //workQueue.selectedVariant.includes('MEKANIIKKA KIT') &&
    getConfiguration('features.dhlModal', false);

  const shouldShowDownloadBom = workQueue && workQueue.selectedVariant && workQueue.selectedVariant.startsWith('X_');

  let resultButtons = [...buttonsFromSap];

  if (hasStatusColumn) {
    resultButtons = [...resultButtons, updateStatusButton];
  }

  if (shouldShowPrintDocuments) {
    resultButtons = [...resultButtons, printDocumentButton];
  }

  if (shouldShowDownloadBom) {
    resultButtons = [...resultButtons, downloadBomButton];
  }

  return resultButtons;
};
export const isLoading = state => Get(state, 'workQueue.loading');
export const getHeaders = state => {
  let headers = Get(state, 'workQueue.headers', []);

  headers = checkPrivilegeToShowPrintHeader(state, headers);

  return headers;
};
export const getRows = state => Get(state, 'workQueue.items', []);
export const getFilteredRows = state => Get(state, 'workQueue.filteredItems', []);

export const getSelectedRowIds = state => Get(state, 'workQueue.selected', []);
export const getShowCreateSerialNumberDialog = state => Get(state, 'workQueue.serialNumberDialog.show', false);
export const getShowRePrintRowDialog = state => Get(state, 'workQueue.showRePrintRatingPlateDialog', false);
export const getShowDhlOrderDialog = state => Get(state, 'workQueue.showDhlBomOrderDialog', false);
export const getCreateSerialNumberDialogLoading = state => Get(state, 'workQueue.serialNumberDialog.loading', false);
export const getRowsByIds = (state, ids) => {
  return Get(state, 'workQueue.items', []).filter(item => ids.includes(item.id));
};
export const getSelectedRows = state => {
  const selectedIds = getSelectedRowIds(state);
  return getRowsByIds(state, selectedIds);
};
export const getProductionLine = state => Get(state, 'workQueue.productionLine');
export const getLoadingButtons = state =>
  Get(state, 'workQueue.buttonLoading', []).concat(Get(state, 'bomDetails.buttonLoading', []));
export const getSelectedVariant = state => Get(state, 'workQueue.selectedVariant', '');
const checkPrivilegeToShowPrintHeader = (state, headers) => {
  if (hasUserPermission(state, AllowWorkQueueLabelPrint) && headers.length > 0) {
    return [...headers, printPackageLabelHeader];
  }
  return headers;
};
