import React, { useCallback } from 'react';
import AbbTitle from '../../shared/AbbSectionTitle';
import { useDispatch, useSelector } from 'react-redux';
import { searchBillOfMaterials } from './printingActions';
import { getSearchResult, isLoading, isSearching } from './printingReducer';
import { Column } from 'simple-flexbox';
import SearchResultsGrid from './components/SearchResultsGrid';

import IsEmpty from 'lodash/isEmpty';
import PageLoading from '../../shared/PageLoading';
import SearchMaterial from '../../shared/BillOfMaterials/SearchMaterial';
import styled from 'styled-components';
import { DownloadUserGuide } from '../../shared/DownloadUserGuide';

const GridWrapper = styled.div`
  margin-top: 1rem;
`;

const PrintingPage = () => {
  const dispatch = useDispatch();
  const data = useSelector(state => getSearchResult(state));
  const loading = useSelector(state => isLoading(state));
  const searching = useSelector(state => isSearching(state));

  const onSubmitSearch = useCallback(
    data => {
      dispatch(searchBillOfMaterials({ bomType: data.searchType, ...data }));
    },
    [dispatch],
  );

  return (
    <>
      <PageLoading visible={loading} />
      <AbbTitle title="Printing">
        <DownloadUserGuide filename="/user-manuals/user-guide_printing.docx" />
      </AbbTitle>
      <Column flexGrow={1}>
        <SearchMaterial onSubmitSearch={onSubmitSearch} searching={searching} />
        {(!IsEmpty(data) || loading) && (
          <GridWrapper>
            <SearchResultsGrid gridRows={new Array(data.header)} />
          </GridWrapper>
        )}
      </Column>
    </>
  );
};

export default PrintingPage;
