import React from 'react';
import { GroupHeading, HeaderSection, Item, MenuSection, Separator } from '@atlaskit/navigation-next';

import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import * as PERMISSIONS from '../../../../shared/Auth/permissions';
import { useSelector } from 'react-redux';
import * as authSelectors from '../../../../shared/Auth/authSelectors';
import { PermissibleRender } from '@brainhubeu/react-permissible';
import VendorContextSwitcher from '../../../../shared/VendorContextSwitcher';

const Wordmark = styled.div`
  text-align: center;
  color: black !important;
`;

function SideNavigation() {
  const userPermissions = useSelector(state => authSelectors.getUserPermissions(state));
  return (
    <>
      <HeaderSection>
        {({ className }) => (
          <div className={className}>
            <NavLink to="/home">
              <Wordmark>MSP</Wordmark>
            </NavLink>
          </div>
        )}
      </HeaderSection>
      <MenuSection>
        {({ className }) => (
          <div className={className}>
            <PermissibleRender
              userPermissions={userPermissions}
              requiredPermissions={[PERMISSIONS.AllowImpersonateVendor]}
            >
              <Separator />
              <GroupHeading>Context selector</GroupHeading>
              <VendorContextSwitcher />
            </PermissibleRender>
            <Separator />
            <GroupHeading>Product data</GroupHeading>
            <PermissibleRender
              userPermissions={userPermissions}
              requiredPermissions={[PERMISSIONS.AccessDocumentSearch]}
            >
              <NavLink css={{ textDecoration: 'none' }} to="/document-search">
                <Item text="Document Search" />
              </NavLink>
            </PermissibleRender>
            <PermissibleRender
              userPermissions={userPermissions}
              requiredPermissions={[PERMISSIONS.AccessBillOfMaterials]}
            >
              <NavLink css={{ textDecoration: 'none' }} to="/bom">
                <Item text="Bill of Materials" />
              </NavLink>
            </PermissibleRender>
            <Separator />
            <GroupHeading>Production</GroupHeading>
            <PermissibleRender userPermissions={userPermissions} requiredPermissions={[PERMISSIONS.AccessWorkQueues]}>
              <NavLink css={{ textDecoration: 'none' }} to="/variant">
                <Item text="Manufacturing work queues" />
              </NavLink>
            </PermissibleRender>
            <PermissibleRender userPermissions={userPermissions} requiredPermissions={[PERMISSIONS.AccessTracing]}>
              <NavLink css={{ textDecoration: 'none' }} to="/trace">
                <Item text="Tracing" />
              </NavLink>
            </PermissibleRender>
            <Separator />
            <GroupHeading>Material Managements</GroupHeading>
            <PermissibleRender userPermissions={userPermissions} requiredPermissions={[PERMISSIONS.AccessStorageBins]}>
              <NavLink css={{ textDecoration: 'none' }} to="/storage-bins">
                <Item text="Storage bins" />
              </NavLink>
            </PermissibleRender>
            <PermissibleRender
              userPermissions={userPermissions}
              requiredPermissions={[PERMISSIONS.AccessMaterialSearch]}
            >
              <NavLink css={{ textDecoration: 'none' }} to="/material-search">
                <Item text="Material Search" />
              </NavLink>
            </PermissibleRender>
            <Separator />
            <GroupHeading>Others</GroupHeading>
            <PermissibleRender userPermissions={userPermissions} requiredPermissions={[PERMISSIONS.AccessReports]}>
              <NavLink css={{ textDecoration: 'none' }} to="/reports">
                <Item text="Reports" />
              </NavLink>
            </PermissibleRender>
            <PermissibleRender
              userPermissions={userPermissions}
              requiredPermissions={[PERMISSIONS.AccessPrintWorkOrder]}
            >
              <NavLink css={{ textDecoration: 'none' }} to="/print-wo">
                <Item text="Print WO" />
              </NavLink>
            </PermissibleRender>
            <PermissibleRender userPermissions={userPermissions} requiredPermissions={[PERMISSIONS.AccessPrinting]}>
              <NavLink css={{ textDecoration: 'none' }} to="/printing">
                <Item text="Printing" />
              </NavLink>
            </PermissibleRender>
            <Separator />
          </div>
        )}
      </MenuSection>
    </>
  );
}

SideNavigation.propTypes = {};

export default SideNavigation;
