import * as PERMISSIONS from '../Auth/permissions';

export const ADMIN_VENDOR_ID_KEY = 'ABB_MSP_ADMIN_VENDOR_ID';
export const ADMIN_PLANT_ID_KEY = 'ABB_MSP_ADMIN_PLANT_ID_KEY';

export const canImpersonateVendor = userPermissions => {
  if (userPermissions && Array.isArray(userPermissions)) {
    return userPermissions.includes(PERMISSIONS.AllowImpersonateVendor);
  }
};
