export const clearAbbLocalStorage = () => {
  let keysToClear = [];
  for (let i = 0; i < localStorage.length; i++) {
    if (localStorage.key(i).startsWith('ABB_MSP')) {
      keysToClear.push(localStorage.key(i));
    }
  }
  for (let i = 0; i < keysToClear.length; i++) {
    localStorage.removeItem(keysToClear[i]);
  }
};
