import { createAction } from '@reduxjs/toolkit';
import { RSAA } from 'redux-api-middleware';
import { buildApiUrl } from '../../../shared/utils/ApiUtils';
import { getHeader, getTraceableComponents, getUpdatedTraceableComponents } from './TracingSelectors';
import Get from 'lodash/get';
import { getInfoMessageFromAction, showFailureNotification } from '../../../shared/utils/Notifications';
import { downloadRatingPlate, triggerSapPrintout } from '../WorkQueue/WorkQueueActions';

export const getTracingDataStart = createAction('trace/getTracingDataStart');
export const getTracingDataSuccess = createAction('trace/getTracingDataSuccess');
export const getTracingDataFailure = createAction('trace/getTracingDataFailure');

export const convertSerialNumberStart = createAction('trace/convertSerialNumberStart');
export const convertSerialNumberSuccess = createAction('trace/convertSerialNumberSuccess');
export const convertSerialNumberFailure = createAction('trace/convertSerialNumberFailure');

export const saveTraceableComponentsStart = createAction('trace/saveTraceableComponentsStart');
export const saveTraceableComponentsSuccess = createAction('trace/saveTraceableComponentsSuccess');
export const saveTraceableComponentsFailure = createAction('trace/saveTraceableComponentsFailure');

export const updateSerialNumberForComponent = createAction('trace/updateSerialNumbersForComponent');
export const updateDataForComponent = createAction('trace/updateDataForComponent', (material, updatedData) => ({
  payload: {
    material,
    data: updatedData,
  },
}));

export const genericSapPrintoutStart = createAction('trace/genericSapPrintoutStart');
export const genericSapPrintoutSuccess = createAction('trace/genericSapPrintoutSuccess');
export const genericSapPrintoutFailure = createAction('trace/genericSapPrintoutFailure');

export const printRatingPlateStart = createAction('trace/printRatingPlateStart');
export const printRatingPlateSuccess = createAction('trace/printRatingPlateSuccess');
export const printRatingPlateFailure = createAction('trace/printRatingPlateFailure');

export const updateSerialNumberFailed = (message = 'Could not assign serial number to any row!') => ({
  type: 'trace/updateSerialNumberFailed',
  error: true,
  payload: message,
});

export const getTracingData = serialNumber => dispatch => {
  const url = buildApiUrl('trace', { sn: serialNumber });

  return dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      types: [getTracingDataStart.type, getTracingDataSuccess.type, getTracingDataFailure.type],
    },
  });
};

const updateTraceableComponents = (component, barcode, username) => {
  if (component.serialNumber) {
    return updateSerialNumberFailed(component.material);
  }
  const serialNumber = barcode.slice(component.material.length);

  return updateSerialNumberForComponent({
    material: component.material,
    unitCount: component.unitCount,
    serialNumber,
    username,
  });
};

const updateComponentsUsingBarCode = (components, barcode, username, dispatch) => {
  const componentsToBeUpdated = components.filter(
    component => barcode.toLowerCase().startsWith(component.material.toLowerCase()) && !component.serialNumber,
  );

  if (componentsToBeUpdated.length > 0) {
    const firstComponent = componentsToBeUpdated[0];
    dispatch(updateTraceableComponents(firstComponent, barcode, username));

    return true;
  }
  return false;
};

export const updateSerialNumberFromBarcode = (barcode, username) => async (dispatch, getState) => {
  const state = getState();
  const components = getTraceableComponents(state);

  const userSerialNumberMatched = updateComponentsUsingBarCode(components, barcode, username, dispatch);

  if (!userSerialNumberMatched) {
    const serialNumber = Get(getHeader(state), 'serialNumber');

    const convertSerialAction = await dispatch(convertSerialNumber(barcode, serialNumber));

    if (convertSerialAction.error) {
      return dispatch(updateSerialNumberFailed());
    }

    const barcodeFromSAP = Get(convertSerialAction, 'payload.result', '');

    if (!barcodeFromSAP) {
      return dispatch(updateSerialNumberFailed(getInfoMessageFromAction(convertSerialAction)));
    }

    const sapSerialNumberMatched = updateComponentsUsingBarCode(components, barcodeFromSAP, username, dispatch);

    if (!sapSerialNumberMatched) {
      return dispatch(updateSerialNumberFailed());
    }
  }
};

export const convertSerialNumber = (barcode, serialNumber) => dispatch => {
  const url = buildApiUrl('trace/convertSerial', { bc: barcode, sn: serialNumber });

  return dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      types: [convertSerialNumberStart.type, convertSerialNumberSuccess.type, convertSerialNumberFailure.type],
    },
  });
};

export const saveTraceableComponents = () => (dispatch, getState) => {
  const state = getState();
  const { productionLine, refDocument, serialNumber } = getHeader(state);
  const updatedComponents = getUpdatedTraceableComponents(state);

  const missingUsername = updatedComponents.filter(component => !component.traceUser).length > 0;

  if (missingUsername) {
    showFailureNotification('Username missing for some updated rows');
    return;
  }

  const data = {
    productionLine,
    refDocument,
    serialNumber,
    components: [...updatedComponents],
  };

  const url = buildApiUrl('trace/updateComponents');

  return dispatch({
    [RSAA]: {
      endpoint: url,
      body: JSON.stringify(data),
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      types: [
        saveTraceableComponentsStart.type,
        saveTraceableComponentsSuccess.type,
        saveTraceableComponentsFailure.type,
      ],
    },
  });
};

const tracingPrintoutDataProvider = state => {
  const header = getHeader(state);

  return [
    {
      id: 0,
      prodLine: header.productionLine,
      prodOrder: header.prodOrder,
      serial: header.serialNumber,
    },
  ];
};

export const genericSapPrintout = (code, description) => dispatch => {
  const actionTypes = [genericSapPrintoutStart, genericSapPrintoutSuccess, genericSapPrintoutFailure];

  dispatch(triggerSapPrintout(code, description, tracingPrintoutDataProvider, actionTypes));
};

export const printRatingPlate = () => dispatch => {
  const types = [printRatingPlateStart, printRatingPlateSuccess, printRatingPlateFailure];

  dispatch(downloadRatingPlate(tracingPrintoutDataProvider, types));
};
