import { isRSAA, RSAA } from 'redux-api-middleware';

import * as authSelectors from './app/shared/Auth/authSelectors';
import { canImpersonateVendor } from './app/shared/utils/authUtils';

const adminVendorInjector = store => next => action => {
  if (isRSAA(action)) {
    const rsaaAction = action[RSAA];
    const userPermissions = authSelectors.getUserPermissions(store.getState());
    if (canImpersonateVendor(userPermissions)) {
      const vendorId = authSelectors.getVendorId(store.getState());
      const plantId = authSelectors.getPlantId(store.getState());
      rsaaAction.headers = {
        ...rsaaAction.headers,
        'X-ABB-MSP-VendorId': vendorId,
        'X-ABB-MSP-PlantId': plantId,
      };
    }
  }
  return next(action);
};

export default adminVendorInjector;
