import Get from 'lodash/get';

export const isLoading = state => Get(state, 'documentSearch.loading', false);
export const getResults = state => Get(state, 'documentSearch.results', []);

export const getDocumentKinds = state =>
  Get(state, 'documentSearch.documentKinds', []).map(kind => ({
    value: kind.value,
    label: kind.description,
  }));
