import PullAt from 'lodash/pullAt';

export const setButtonLoading = (state, buttonId, isLoading) => {
  if (isLoading) {
    state.buttonLoading.push(buttonId.toLowerCase());
  } else {
    PullAt(
      state.buttonLoading,
      state.buttonLoading.findIndex(item => item === buttonId.toLowerCase()),
    );
  }
  state.loading = state.buttonLoading.length > 0;
};
