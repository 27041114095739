import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import Button from '@atlaskit/button';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import Pick from 'lodash/pick';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

export const saveExcelFile = ({ headers, data }, name, filename) => {
  const headerKeys = Array.from(headers.map(header => header.key));
  const headerLabels = Array.from(headers.map(header => header.label));

  const dataWithOnlyHeaderKeys = data.map(entry => Pick(entry, headerKeys));

  const workSheet = XLSX.utils.aoa_to_sheet([headerLabels]);
  XLSX.utils.sheet_add_json(workSheet, dataWithOnlyHeaderKeys, {
    header: headerKeys,
    skipHeader: true,
    origin: 1,
  });

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, workSheet, name);
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const excelData = new Blob([excelBuffer], { type: fileType });
  saveAs(excelData, filename + fileExtension);
};

export const saveExcelDhlOrder = ({ headers, data, kitHeaders, kitData, name, filename }) => {
  const kitHeaderKeys = Array.from(kitHeaders.map(kitHeader => kitHeader.key));
  const kitHeaderLabels = Array.from(kitHeaders.map(kitHeader => kitHeader.label));

  const kitDataWithOnlyHeaderKeys = [kitData].map(entry => Pick(entry, kitHeaderKeys));

  const workSheet = XLSX.utils.aoa_to_sheet([kitHeaderLabels]);
  XLSX.utils.sheet_add_json(workSheet, kitDataWithOnlyHeaderKeys, {
    header: kitHeaderKeys,
    skipHeader: true,
    origin: 1,
  });

  const headerKeys = Array.from(headers.map(header => header.key));
  const headerLabels = Array.from(headers.map(header => header.label));

  const dataWithOnlyHeaderKeys = data.map(entry => Pick(entry, headerKeys));

  XLSX.utils.sheet_add_aoa(workSheet, [headerLabels], {
    origin: 3,
  });

  XLSX.utils.sheet_add_json(workSheet, dataWithOnlyHeaderKeys, {
    header: headerKeys,
    skipHeader: true,
    origin: 4,
  });

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, workSheet, name);
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const excelData = new Blob([excelBuffer], { type: fileType });
  saveAs(excelData, filename + fileExtension);
};

export const ExportDataToExcel = ({ exportDataProvider, filename, name }) => {
  const exportToExcel = useCallback(() => {
    saveExcelFile(exportDataProvider(), name, filename);
  }, [name, exportDataProvider, filename]);

  return (
    <Button appearance="link" onClick={() => exportToExcel()}>
      Export to Excel
    </Button>
  );
};

ExportDataToExcel.propTypes = {
  exportDataProvider: PropTypes.func.isRequired,
  filename: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
