export const addToEachColumn = (destination, source) => {
  return destination.map(data => {
    return {
      ...data,
      ...source,
    };
  });
};

export const mergeByKey = (destination, source) => {
  return destination.map(item => {
    return {
      ...item,
      ...source.find(column => column.key === item.key),
    };
  });
};
export const getGridHeight = rowsCount => {
  return rowsCount * 35 + 37 + 15;
};

const determineWidth = (col, calculatedWidth, staticWidths) => {
  if (staticWidths[col.key]) {
    return staticWidths[col.key];
  }

  return calculatedWidth;
};

export class GridColumnFormatter {
  constructor(staticWidths = {}) {
    this.canvas = null;
    this.canvasContext = null;
    this.staticWidths = staticWidths;
  }

  formatColumnsWidth(data, cols, gridRef, padding = 4) {
    let gridWidth;
    if (gridRef && gridRef.current) {
      gridWidth = gridRef.current.getTotalWidth();
    } else {
      gridWidth = 0;
    }
    let combinedColumnWidth = 0;
    for (let i = 0; i < cols.length; i++) {
      cols[i].width = determineWidth(cols[i], this._getTextWidth(data, cols, i, padding), this.staticWidths);
      combinedColumnWidth += cols[i].width;
    }

    if (combinedColumnWidth < gridWidth) {
      if (cols.length > 0) {
        cols = this._distributeRemainingSpace(combinedColumnWidth, cols, gridWidth, padding);
      }
    }
    return cols;
  }

  _getTextWidth(data, cols, i, padding) {
    const rowValues = [];
    const reducer = (a, b) => {
      return (a ? a.length : 0) > (b ? b.length : 0) ? a : b;
    };
    const cellPadding = padding;
    const arrowWidth = padding;
    let longestCellData, longestCellDataWidth, longestColName, longestColNameWidth, longestString;

    for (let row of data) {
      rowValues.push(row && row[cols[i].key]);
    }

    longestCellData = rowValues.reduce(reducer, 0);
    longestColName = cols[i].name;
    longestCellDataWidth = Math.ceil(this._getCanvas().measureText(longestCellData).width);
    longestColNameWidth = Math.ceil(this._getCanvas('bold ').measureText(longestColName).width) + arrowWidth;

    longestString = Math.max(longestCellDataWidth, longestColNameWidth);

    return longestString + cellPadding;
  }

  _getCanvas(fontWeight = '') {
    if (!this.canvas) {
      this.canvas = document.createElement('canvas');
      this.canvasContext = this.canvas.getContext('2d');
    }
    this.canvasContext.font = `${fontWeight}16px sans-serif`;

    return this.canvasContext;
  }

  _distributeRemainingSpace(combinedColumnWidth, columns, gridWidth, padding) {
    const spaceLeftOver = gridWidth - combinedColumnWidth;
    const remainder = spaceLeftOver % columns.length;
    const equalSpaceLeft = spaceLeftOver - remainder - 4 * padding;

    columns[0].width += remainder;

    for (let col of columns) {
      col.width += equalSpaceLeft / columns.length;
    }
    return columns;
  }
}
