import Button, { ButtonGroup } from '@atlaskit/button';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { getButtons, getLoadingButtons, haveComponentsBeenEdited } from './TracingSelectors';
import { genericSapPrintout, saveTraceableComponents } from './TracingActions';
import { showRePrintRowsDialog } from '../RePrintDialog/RePrintRatingPlateDialog';

export const TracingButtons = () => {
  const dispatch = useDispatch();
  const buttons = useSelector(state => getButtons(state));

  const loadingButtons = useSelector(state => getLoadingButtons(state));
  const edited = useSelector(state => haveComponentsBeenEdited(state));

  const getButtonProperties = ({ code, description }) => {
    if (code === 'WWW_RATINGP') {
      return {
        onClick: () => dispatch(showRePrintRowsDialog(true)),
      };
    }
    if (code === 'SAVE_TRACE') {
      return {
        onClick: () => dispatch(saveTraceableComponents()),
        isDisabled: !edited,
      };
    }
    return {
      onClick: () => dispatch(genericSapPrintout(code, description)),
    };
  };

  return (
    <ButtonGroup appearance="primary">
      {buttons.map(button => {
        let buttonProps = getButtonProperties(button);
        const isLoading = loadingButtons.includes(button.code.toLowerCase());
        buttonProps = {
          ...buttonProps,
          isLoading,
          isDisabled: buttonProps.isDisabled || isLoading,
        };

        return (
          <Button key={button.id} {...buttonProps}>
            {button.description}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};
