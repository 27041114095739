import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';
import 'bootstrap/dist/css/bootstrap.css'; //styles used only for react data grid
import '@atlaskit/css-reset';

import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import history from './app/shared/utils/history';

import App from './app/App';
import * as serviceWorker from './serviceWorker';
import configureStore from './configureStore';
import { Authenticate } from './app/shared/Auth/Authenticate';
import { initializeMsal } from 'app/shared/Auth/msal';

// Create redux store
const initialState = {};
const store = configureStore(initialState, history);

const InitApp = Component => {
  fetch('/app-config.json')
    .then(response => response.json())
    .then(async config => {
      await initializeMsal();
      RenderApp(Component, config);
    });
};

const RenderApp = (Component, config) => {
  window.APP_CONFIG = JSON.stringify(config);

  // eslint-disable-next-line react/no-render-return-value
  return ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Authenticate store={store}>
          <Component config={config} />
        </Authenticate>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root'),
  );
};

InitApp(App);

if (module.hot) {
  module.hot.accept('./app/App', () => {
    // eslint-disable-next-line global-require
    const NextApp = require('./app/App').default;
    InitApp(NextApp);
  });
}

serviceWorker.unregister();
